import { Temporal } from '@js-temporal/polyfill';

interface PickupRequest {
  id: UUID;
  updatedAt?: Temporal.Instant;
  confirmedAt?: Temporal.Instant;
  carrierReference?: string;
  rejectedAt?: Temporal.Instant;
  rejectionReason?: string;
}

export const newPickupRequest = (): PickupRequest => ({
  id: '',
});

export const createPickupRequest = ({
                                      id = '',
                                      updatedAt,
                                      confirmedAt,
                                      carrierReference,
                                      rejectedAt,
                                      rejectionReason,
                                    }: Partial<PickupRequest> | undefined = newPickupRequest()): PickupRequest => ({
  id,
  updatedAt,
  confirmedAt,
  carrierReference,
  rejectedAt,
  rejectionReason,
});

export default PickupRequest;
