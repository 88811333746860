
export const MOVEMENT_FLOW_TYPES = ['outbound', 'inbound', 'transfer'] as const;

export type MovementFlowType = typeof MOVEMENT_FLOW_TYPES[number];

// NOTE: MOVEMENT_FLOW_TYPES_RECORD is only required for backwards compatibility
// (usage in *.js files, mainly as CONSIGNMENT_TYPES)
export const MOVEMENT_FLOW_TYPES_RECORD: Record<string, MovementFlowType> = {
  OUTBOUND: 'outbound',
  INBOUND: 'inbound',
  TRANSFER: 'transfer',
};



