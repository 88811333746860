import { createTypeHelpers } from '@App/store/store-helpers';

import { name } from '../module';


/**
 * Explicitly named getters, mutations and actions. Marked internal because
 * these will be used for definitions in the store, but most external code
 * will access these through the namespace, ie:
 *   '${MODULE_NAME}/CURRENT_ORGANISATION'
 */
const InternalTypes = {
  getters: {
    address: 'address',
    addressType: 'addressType',
  },

  mutations: {
    SET_ADDRESS: 'SET_ADDRESS',
  },

  actions: {
    resolveAddress: 'resolveAddress',
    getAddress: 'getAddress',
    getEstimateDetails: 'getEstimateDetails',
    getSuggestionDetails: 'getSuggestionDetails',
    storeAddress: 'storeAddress',
  },
};

/**
 * Derive namespaced versions of each constant that can be used in views and
 * components directly, ie:
 *   this.$store.dispatch(Org.fetchUser)
 *     which is the same as:
 *   this.$store.dispatch('org/fetchUser')
 */
const ModuleTypes = createTypeHelpers(name, InternalTypes);

export { ModuleTypes as default, InternalTypes };
