import { InternalTypes as Address } from './types';

const AddressGetters = {
  [Address.getters.address]: state => addressId => state.addresses[addressId],

  [Address.getters.addressType]: state => (addressId) => {
    if (!state.addresses[addressId]) {
      return null;
    }
    return state.addresses[addressId].from;
  },
};

export default AddressGetters;
