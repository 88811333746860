<template>
  <div v-if="show" class="banner-message" :class="`is-${type}`">
    <DialogContent :type="type" compact>
      <template #default>
        <div v-if="title" class="banner-title">{{ title }}</div>
        <slot>
          <TextParagraphs v-if="message" :text="message" />
        </slot>
        <a
          v-for="action in actions"
          :key="action.label"
          class="action"
          href="#"
          @click.prevent="action.callback"
        >{{ action.label }}</a>
      </template>
      <template #end>
        <slot name="end"></slot>
        <SvgIcon
          v-if="canDismiss"
          :svg="CloseIcon"
          class="banner-dismiss"
          @click="dismiss"
        />
      </template>
    </DialogContent>
  </div>
</template>

<script>
  import { computed, ref, toRefs } from 'vue';

  import { THEME } from '@/shared/consts';

  import SvgIcon from '@/shared/icons/SvgIcon.vue';
  import CloseIcon from '@/shared/icons/svgs/close.svg';
  import DialogContent from '@/shared/notify/components/DialogContent.vue';
  import TextParagraphs from '@/shared/notify/components/TextParagraphs.vue';

  // if this is updated the types in the style block also need to be updated
  export const BANNER_TYPE = {
    DANGER: THEME.DANGER,
    INFO: THEME.INFO,
    WARNING: THEME.WARNING,
  };

  export default {
    components: {
      SvgIcon,
      TextParagraphs,
      DialogContent,
    },
    props: {
      title: { type: String, default: undefined },
      message: { type: String, default: undefined },
      actions: { type: Array, default: () => ([]) },
      type: {
        type: String,
        default: BANNER_TYPE.INFO,
        // don't allow banners to be created with an unsupported type
        validator: (type) => Object.values(BANNER_TYPE).includes(type),
      },
      dismissable: { type: Boolean, default: false },
    },
    setup(props) {
      const { type } = toRefs(props);

      const show = ref(true);
      const canDismiss = computed(() => type.value !== BANNER_TYPE.DANGER && !!props.dismissable);

      const dismiss = () => {
        if (!canDismiss.value) return;

        show.value = false;
      };

      return {
        canDismiss,
        dismiss,
        show,
        CloseIcon,
      };
    },
  };
</script>

<style lang="scss" scoped>
  $banner-text-line-height: 20px;

  .banner-message {
    @include Subhead2-default;

    color: var(--copy-90);
    border-radius: var(--radius-large);

    @each $type in [danger, info, warning] {
      &.is-#{$type} {
        border: 1px solid var(--#{$type}-50);
        background: var(--#{$type}-10);
      }
    }

    &:has(+ .banner-message) {
      margin-bottom: 12px;
    }

    .banner-title {
      @include H4-default;

      line-height: $banner-text-line-height;
    }
    a.action {
      @include Button2-default;

      line-height: $banner-text-line-height;
      text-decoration: underline;
    }
  }

  .banner-dismiss {
    box-sizing: content-box;
    height: 11px;
    width: 11px;
    padding: 2.5px;
    cursor: pointer;
    fill: var(--copy-70);
  }
</style>
