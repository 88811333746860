import { escapeStringForRegex } from '../utils/string-utils';

export default function markFilterMatches({
  text,
  filter,
  markerStart = '{{',
  markerEnd = '}}',
}) {
  return text.replaceAll(new RegExp(escapeStringForRegex(filter), 'gi'), `${markerStart}$&${markerEnd}`);
}
