<template>
  <component
    :is="svg"
    class="svg-icon"
    :class="[`svg-icon-${props.size}`]"
  />
</template>

<script lang="ts" setup>
  import { type Component } from 'vue';

  type IconSize = 'small' | 'medium' | 'large' | 'xlarge' | 'text';
  const props = withDefaults(defineProps<{
    svg: Component,
    size?: IconSize;
  }>(), {
    size: 'text',
  });
</script>

<style lang="scss" scoped>
.svg-icon {
  max-height: 100%;
  fill: currentColor;

  // @see https://blog.prototypr.io/align-svg-icons-to-text-and-say-goodbye-to-font-icons-d44b3d7b26b4
  &.svg-icon-text {
    height: 1em; // default to the current text height
    top: .125em;
    position: relative;
  }

  &.svg-icon-small {
    height: 16px;
  }
  &.svg-icon-medium {
    height: 20px;
  }
  &.svg-icon-large {
    height: 28px;
  }
  &.svg-icon-xlarge {
    height: 36px;
  }
}
</style>
