import MonitorClient from '@/shared/analytics/MonitorClient';

export default class LocalMonitorClient implements MonitorClient {
  /* eslint-disable no-console */

  private context: Record<string, unknown> = {};

  addAction(name: string, context?: object | undefined) {
    console.log('[LocalMonitorClient] RUM: action recorded', { name, context: { ...(context || {}), ...this.context } });
  }

  setGlobalContextProperty(key: string, value: unknown) {
    console.log('[LocalMonitorClient] RUM: add to global context', { key, value });
    this.context[key] = value;
  }

  removeGlobalContextProperty(key: string) {
    console.log('[LocalMonitorClient] RUM: remove from global context', { key });
    delete this.context[key];
  }
}
