export enum OrderInstrumentation {
  SENDER_ADDRESS_MISMATCH = 'order from address does not match site address',
  REPORT_ORDER_VALIDATION_ERRORS = 'report order validation errors',
  ORDER_ALREADY_CONSIGNED = 'order has been already consigned',
  ORDER_SELECTED = 'order selected',
}

export default OrderInstrumentation;



