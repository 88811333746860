export interface AppFlag {
  type: 'string' | 'boolean' | 'enum' | 'enums';
  options?: string[];
  default: boolean | string | string[];
  group?: string;
  description?: string;
}

const appFlags: Record<string, AppFlag> = {
  sensitive: {
    description: 'set to "hide" to preview sensitive masked elements during development',
    type: 'enum',
    options: ['hide', 'show', 'blur'],
    default: 'hide',
  },

  // set to 'type' or 'version' to see the prompt
  browserCheck: {
    description: 'set to "type" or "version" to see the prompt',
    type: 'enum',
    options: ['pass', 'type', 'version'],
    default: 'pass',
  },

  // enable dangerous goods features in development
  dgSite: {
    description: 'Are DGs enabled for the current site?',
    group: 'Dangerous Goods',
    type: 'enum',
    options: ['default', 'enabled', 'disabled'],
    default: 'default',
  },

  dgSitePast: {
    description: 'WERE DGs enabled for the current site?',
    group: 'Dangerous Goods',
    type: 'enum',
    options: ['default', 'enabled', 'disabled'],
    default: 'default',
  },

  dgUser: {
    description: 'Are DGs enabled for the current user?',
    group: 'Dangerous Goods',
    type: 'enum',
    options: ['default', 'enabled', 'disabled'],
    default: 'default',
  },

  // https://flip-eng.atlassian.net/browse/GEPPIE-8785
  dgNotifiable: {
    description: 'Enable to show Notifiable DGs',
    group: 'Dangerous Goods',
    type: 'boolean',
    default: false,
  },

  orderIntegration: {
    description: 'Enable order integration',
    type: 'boolean',
    default: false,
  },

  customFields: {
    group: 'Custom fields',
    description: 'Enable custom fields',
    type: 'boolean',
    default: false,
  },

  customFieldNoClientValidations: {
    group: 'Custom fields',
    description: 'Disable client validations',
    type: 'boolean',
    default: false,
  },
} as const;

export default appFlags;
