<template>
  <footer class="section">
    <div class="container is-fluid">
      <div class="navbar">
        <div class="navbar-brand">
          <a class="navbar-item is-text-tag powered-by" href="https://flip.com.au" target="_blank" rel="noopener noreferrer">
            Powered by <img src="@/assets/flip.svg?url" alt="Flip logo">
          </a>
        </div>
        <div class="navbar-start">
          <router-link :to="{ name: 'page.terms-of-use' }" class="navbar-item">Terms of Use</router-link>
          <router-link :to="{ name: 'page.privacy-policy' }" class="navbar-item">Privacy Policy</router-link>
        </div>
        <div class="navbar-end">
          <div class="navbar-item">
            <span>Need help?</span>
            <span class="icon is-medium"><i class="fas fa-phone"></i></span>
            <span class="phone">{{ helpContact.phone }}</span>
          </div>
        </div>
      </div>
      <div class="build-info" :class="{ debug } ">
        <div>Build</div>
        <div class="version">version: {{ version }}</div>
      </div>
    </div>
  </footer>
</template>

<script>
  import { mapGetters } from 'vuex';

  import app from '@/config';

  import Org from '@Org/store/types';

  export default {
    name: 'AppFooter',
    data() {
      return {
        version: app.version,
        debug: app.debug,
      };
    },
    computed: {
      ...mapGetters({
        platformDefaults: Org.getters.platformDefaults,
      }),
      helpContact() {
        return this.platformDefaults.helpContact;
      },
    },
  };
</script>

<style lang="scss" scoped>
  footer {
    color: $white;
    background: $black;

    &.section {
      padding: 2em 0;
    }
  }

  .navbar {
    @include Tag-default;
    background-color: transparent;
    z-index: unset; // don't use multi-level so not needed

    @media screen and (max-width: $navbar-breakpoint) {
      & {
        text-align: center;
      }
      .navbar-brand {
        justify-content: center;
        margin-right: 0;
      }
    }
  }

  .navbar-item,
  .navbar-link {
    color: $white;
    font-weight: $font-weight-medium;
  }

  .navbar-brand {
    margin-right: 1.5rem;

    a {
      font-family: $family-sans-serif;
      font-weight: $font-weight-medium;
      text-transform: uppercase;
      &, &:hover, &:active {
        color: $white;
        text-decoration: none
      }

      img {
        max-height: initial;
      }
    }
  }

  .navbar-start {
    align-items: center;

    .navbar-item {
      padding-top: 0;
      padding-bottom: 0;
      color: $brand-30;

      &:hover, &:active, &:focus {
        background-color: transparent;
      }

      @media screen and (min-width: $navbar-breakpoint) {
        &:not(:last-child) {
          border-right: 1px solid $brand-110;
        }
      }
    }
  }
  .navbar-end {
    .navbar-item {
      padding-right: 0;
    }

    .icon {
      padding-left: 0.6rem;
    }

    .phone {
      font-weight: $font-weight-bold;
    }
  }

  .icon {
    color: $brand-30;
  }

  .build-info {
    display: none;
    color: $copy-70;

    &.debug {
      display: flex;
      justify-content: center;
    }

    & > * {
      margin-right: 1rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
</style>
