import { mutation as buildMutation, query as buildQuery } from 'gql-query-builder';

import OrgUser, { orgUserGqlFields } from '@/shared/models/OrgUser';
import PackagingType, { packagingTypeGqlFields } from '@/shared/models/PackagingType';
import ApiClient, { ApiClientError, GeppettoApiResponse } from '@/shared/services/api-client';
import ErrorWithResponse from '@/shared/services/org/errors/ErrorWithResponse';
import GraphQLError from '@/shared/services/org/errors/GraphQLError';
import InvalidOrganisationError from '@/shared/services/org/errors/InvalidOrganisationError';

import { mapFetchUserToOrgUser } from '@/shared/services/org/mappers/orgMappers';
import { FetchUserResponse, SiteResponse } from '@/shared/services/org/types/FetchUser';

let platformPackagingTypes: PackagingType[] = [];

interface GraphQLQuery {
  operationName: string;
  variables: never;
  query: string;
}

function addSitePackagingTypes(sites: SiteResponse | SiteResponse[], packagingTypes: PackagingType[]) {
  const sitesArray = Array.isArray(sites) ? sites : [sites];
  // In the future package types will be configured per-site but are a static enum
  // for the time being. We hoist them into the site for now to make things easier.
  // @see https://flip-eng.atlassian.net/browse/GEPPIE-2398
  // eslint-disable-next-line no-param-reassign
  sitesArray.forEach(site => { site.packagingTypes = packagingTypes; });
}

export default class OrgClient {
  private apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  private async graphql<T>(query: GraphQLQuery) {
    const response = await this.apiClient.post<GeppettoApiResponse<T>>('/graphql', query);

    if (response.data.errors) {
      const error = new GraphQLError(
        `GraphQL operation '${query.operationName}' returned an error`,
        undefined,
        response,
      );

      throw new ApiClientError<T>(error);
    }

    return response;
  }

  private _debugFetchCount = 0;
  public async fetchUser(): Promise<OrgUser> {
    const query = buildQuery([
      {
        operation: 'me',
        fields: orgUserGqlFields,
      },
      {
        operation: '__type',
        variables: { name: { value: 'PackagingType', required: true } },
        fields: [{ enumValues: packagingTypeGqlFields }],
      },
    ], null, { operationName: 'getUser' }) as GraphQLQuery;
    query.operationName = 'getUser';

    let response;
    try {
      type FetchUserGQLResponse = {
        me: FetchUserResponse;
        __type: { enumValues: PackagingType[] };
      };
      response = await this.graphql<FetchUserGQLResponse>(query);
    } catch (error) {
      if ((error as ErrorWithResponse).status === 400) {
        throw new InvalidOrganisationError(undefined, { cause: error } as never);
      }
      logger.error('[OrgClient] fetchUser failed', { error });
      throw error;
    }

    // this will exclude a specified agreed service from all sites when they are initially
    // fetched, to help us test loading an agreed service that wasn't present in the users
    // sites when they logged in.
    // TODO: remove before merging to prod
    // ?debug.service=2fd2f26c-7051-5deb-8d73-4775e3b45696
    if (window.location.search?.includes('debug.service=') && this._debugFetchCount === 0) {
      const removeServiceId = window.location.search.split('debug.service=')?.at(-1);
      response.data.data.me.sites.forEach(site => {
        site.agreedServices = site.agreedServices?.filter(s => s.carrierServiceId !== removeServiceId);
      });
    }
    this._debugFetchCount += 1;

    platformPackagingTypes = response.data.data.__type.enumValues;

    addSitePackagingTypes(response.data.data.me.sites, platformPackagingTypes);

    return mapFetchUserToOrgUser(response.data.data.me);
  }

  public async setAcceptTerms(): Promise<Date> {
    const mutation = buildMutation({
      operation: 'acceptTerms',
      // variables: { version: app.latestLegalDocumentPublished },
    }) as GraphQLQuery;

    let response;
    try {
      type AcceptTermsGQLResponse = {
        acceptTerms: Date;
      };
      response = await this.graphql<AcceptTermsGQLResponse>(mutation);
    } catch (error) {
      logger.error('[OrgClient] setAcceptTerms failed', error);
      throw error;
    }

    return response.data.data.acceptTerms;
  }
}
