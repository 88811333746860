import { asyncImport, withContextPrefix } from '@App/router/helpers';

import { routes as ConsignmentTrack } from '@ConsignmentTrack';

const ConsignmentRoutes = [
  ...withContextPrefix(
    {
      path: 'consignment',
      meta: {
        requiresAuthentication: true,
      },
    },
    [
      // consignment/track
      ...ConsignmentTrack,

      {
        path: 'create',
        name: 'consignment.create',
        // No actual content at this route, redirect to a child route
        redirect: { name: 'consignment.create.outbound' },
        // Used for layout of all pages in this section
        component: asyncImport(() => import('@Consignment/views/CreateConsignmentLayout.vue')),
        meta: {
          title: 'Create consignment',
          context: {
            allowed: ['site'],
            selectSiteFor: 'consignment',
          },
        },
        children: [
          {
            path: 'outbound',
            name: 'consignment.create.outbound',
            component: asyncImport(() => import('@Consignment/views/CreateOutbound.vue')),
            meta: {
              title: 'Create outbound consignment',
              enableFullStory: true,
            },
          },
          {
            path: 'inbound',
            name: 'consignment.create.inbound',
            component: asyncImport(() => import('@Consignment/views/CreateInbound.vue')),
            meta: {
              title: 'Create inbound consignment',
              enableFullStory: true,
            },
          },
          {
            path: 'transfer',
            name: 'consignment.create.transfer',
            component: asyncImport(() => import('@Consignment/views/CreateTransfer.vue')),
            meta: {
              title: 'Create transfer consignment',
              enableFullStory: true,
            },
          },
        ],
      },
      {
        path: 'ready-to-manifest',
        name: 'consignment.readyToManifest',
        component: asyncImport(() => import('@Consignment/views/ConsignmentList.vue')),
        meta: {
          title: 'Ready-to-manifest consignments',
          enableFullStory: true,
          context: {
            allowed: ['site', 'org'],
          },
        },
      },
      // Temporal redirection, remove when we confirm this url is no longer used
      {
        path: 'list',
        meta: { enableFullStory: true },
        redirect: { name: 'consignment.readyToManifest' },
      },
      {
        path: 'issues',
        name: 'consignment.issues',
        component: asyncImport(() => import('@Consignment/views/importedConsignment/ConsignmentsWithIssuesManage.vue')),
        meta: {
          title: 'Resolve consignment issues',
          context: {
            allowed: ['site', 'org'],
          },
        },
      },
      {
        path: ':consignmentId/edit',
        name: 'consignment.edit',
        component: asyncImport(() => import('@Consignment/views/ConsignmentEdit.vue')),
        meta: {
          title: 'Edit consignment',
          enableFullStory: true,
          context: {
            allowed: ['site'],
          },
        },
      },
      {
        path: ':consignmentId',
        name: 'consignment.view',
        component: asyncImport(() => import('@Consignment/views/ConsignmentView.vue')),
        meta: {
          title: 'View consignment',
          enableFullStory: true,
          context: {
            allowed: ['site', 'org'],
            actionDescription: 'viewing this consignment',
          },
        },
      },

      {
        path: '',
        meta: { enableFullStory: true },
        redirect: { name: 'consignment.readyToManifest' },
      },
    ],
  ),
];

export default ConsignmentRoutes;
