import { Freight } from '@/shared/models';
import { isDestination } from '@/shared/models/Destination';
import { createFreight } from '@/shared/models/Freight';

import FreightInput, { createFreightInput, createFreightItemInput } from '@/shared/models/Pickup/FreightInput';
import MappingError from '@/shared/services/errors/MappingError';

/**
 * Form Data -> Pickup
 */

export const freightInputToFreight = (freightInput: FreightInput[]): Freight[] => {
  const freight: Freight[] = [];
  freightInput.forEach((f) => {
    f.items.forEach((i) => {
      if (!isDestination(f.destination)) throw new MappingError('freight destination cannot be mapped');
      if (!i.width) throw new MappingError('freight width cannot be undefined');
      if (!i.height) throw new MappingError('freight height cannot be undefined');
      if (!i.length) throw new MappingError('freight length cannot be undefined');
      if (!i.weight) throw new MappingError('freight weight cannot be undefined');

      freight.push(createFreight({
        _key: i._key,
        destination: f.destination,
        description: i.description,
        packagingType: i.packagingType,
        width: i.width,
        height: i.height,
        length: i.length,
        weight: i.weight,
        quantity: i.quantity,
        dangerousGoods: i.dangerousGoods,
      }));
    });
  });

  return freight;
};

/**
 * Pickup -> Form Data
 */

export const freightToFreightInput = (freight: Freight[]): FreightInput[] => {
  // group freight by destination
  const lookup: { [ key: string ]: FreightInput } = {};
  freight.forEach((f) => {
    const key = `${f.destination.countryId}-${f.destination.postcode}-${f.destination.locality}`;
    if (!lookup[key]) {
      lookup[key] = createFreightInput({
        _key: key,
        destination: f.destination,
        items: [],
      });
    }
    lookup[key].items.push(createFreightItemInput({
      _key: f._key,
      description: f.description || '',
      packagingType: f.packagingType,
      // shippingItemPresetId: f.shippingItemPresetId,
      width: f.width,
      height: f.height,
      length: f.length,
      weight: f.weight,
      quantity: f.quantity,
      dangerousGoods: f.dangerousGoods || [],
    }));
  });

  return Object.values(lookup);
};
