/**
 * OrgStore - getter methods
 * @see https://vuex.vuejs.org/guide/getters.html
 */
import { Temporal } from '@js-temporal/polyfill';
import keyBy from 'lodash/keyBy';


import { dynamicKeySort } from '@/shared/utils/string-utils';

import { latestLegalDate } from '@Legal/documents';

import { InternalTypes as Org } from './types';

const OrgGetters = {
  /**
   * Proxy getters are used instead of mapState to prevent Vuex store state
   * from ever being modified directly.
   */
  [Org.getters.user]: state => state.user,
  [Org.getters.platformDefaults]: state => state.platformDefaults,

  [Org.getters.sites]: (state, getters) => keyBy(getters[Org.getters.user]?.sites || [], 'id') || {},
  [Org.getters.site]: (state, getters) => siteId => getters[Org.getters.sites][siteId] || null,

  [Org.getters.organisations]: (state, getters) => {
    if (!Object.values(getters[Org.getters.sites]).length) return {};

    return Object.values(getters[Org.getters.sites])
      .reduce((orgs, site) => {
        /* eslint-disable no-param-reassign */
        if (!orgs[site.organisation.id]) {
          orgs[site.organisation.id] = {
            ...site.organisation,
            sites: {},
          };
        }
        orgs[site.organisation.id].sites[site.id] = site;
        return orgs;
      }, {});
  },
  [Org.getters.organisation]: (state, getters) => orgId => getters[Org.getters.organisations][orgId] || null,

  // first site of the first org, alphabetically
  [Org.getters.defaultSite]: (state, getters) => {
    if (!Object.values(getters[Org.getters.organisations]).length) return null;

    const firstOrg = Object.values(getters[Org.getters.organisations])
      .sort(dynamicKeySort('name'))[0];
    return Object.values(firstOrg.sites)
      .sort(dynamicKeySort('name'))[0];
  },

  [Org.getters.userHasAcceptedTerms]: (state, getters) => {
    if (!getters[Org.getters.user]?.acceptedTermsAt) return undefined;

    // this is pretty ugly, but until the org store gets turned into Pinia we have to hack
    // around it.
    const acceptedAtInstant = new Date(getters[Org.getters.user].acceptedTermsAt).toTemporalInstant();
    const acceptedAtZDT = acceptedAtInstant.toZonedDateTimeISO('UTC');

    // latestLegalDate is a ZDT string, so that's easy
    const latestLegalZDT = Temporal.ZonedDateTime.from(latestLegalDate);

    // id acceptedZDT >= latestLegalZDT we're up to date
    return Temporal.ZonedDateTime.compare(acceptedAtZDT, latestLegalZDT) > -1;
  },
};

export default OrgGetters;
