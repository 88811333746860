import { Interval } from '@/shared/DateTime';
import { instantFromDateAndTime } from '@/shared/DateTime/mappers';
import { NewConsignment } from '@/shared/models';

import MappingError from '@/shared/services/errors/MappingError';

import { components } from '@/shared/services/schema/shared.schema';

export const mapDeliveryTimeSlotFeaturesToDeliveryTimeSlotRequest = (receiver: NewConsignment['receiver'], timeZone: string): components['schemas']['DeliveryTimeSlot'] => {
  if (!receiver.deliveryTimeSlot) throw new MappingError('deliveryTimeSlot is undefined');
  if (!receiver.deliveryTimeSlot.slot?.dateRange?.length) throw new MappingError('DeliveryTimeSlot DateRange is required');
  if (!receiver.deliveryTimeSlot.slot?.timeRange?.start) throw new MappingError('DeliveryTimeSlot.timeRange.start Date[0] is required');
  if (!receiver.deliveryTimeSlot.slot?.timeRange?.end) throw new MappingError('DeliveryTimeSlot.timeRange.end Date[0] is required');
  if (!timeZone) throw new MappingError('receiver.address.timezone or timeZone is required');

  return {
    references: receiver.deliveryTimeSlot.slot?.references,
    slot: {
      window: Interval.from({
        start: instantFromDateAndTime(
          receiver.deliveryTimeSlot.slot?.dateRange[0],
          receiver.deliveryTimeSlot.slot?.timeRange.start,
          timeZone,
        ),
        end: instantFromDateAndTime(
          receiver.deliveryTimeSlot.slot?.dateRange[0],
          receiver.deliveryTimeSlot.slot?.timeRange?.end,
          timeZone,
        ),
      }).toString(),
      recurrences: receiver.deliveryTimeSlot.slot?.dateRange[1]
        ? receiver.deliveryTimeSlot.slot?.dateRange[0].until(receiver.deliveryTimeSlot.slot?.dateRange[1]).days
        : 0,
    },
  };
};
