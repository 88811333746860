import { AgreedService } from '@/shared/models';

type FormattableCarrier = Pick<AgreedService['carrier'], 'name'>;
type FormattableAgreedService = Pick<AgreedService, 'name' | 'carrierServiceProviderType'> & { carrier: FormattableCarrier };

export const formatAgreedService = (service?: FormattableAgreedService) => {
  if (!service) return '';
  switch (service.carrierServiceProviderType) {
    case 'sender':
    case 'receiver':
      return service.name;
    default:
      return `${service.carrier.name} | ${service.name}`;
  }
};
