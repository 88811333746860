<template>
  <ControlFrame
    v-on-leave:click,focus="blur"
    class="control-segmented"
    ignore-focus
    :class="{
      'is-danger': hasError,
      'is-fullwidth': isFullwidth,
    }"
    :data-testref="dataTestref"
    @keydown="handleKeyboardInteraction"
  >
    <button
      v-for="option in btnOptions"
      :key="option.value"
      :ref="option.ref"
      v-sensitive
      type="button"
      :tabindex="option.tabIndex"
      :disabled="disabled"
      :class="{ active: option.isSelected }"
      @click="toggleValue(option)"
    >
      {{ option.label }}
    </button>
  </ControlFrame>
</template>

<script setup lang="ts">
  import { computed, onMounted, Ref, ref } from 'vue';

  import ControlFrame from '@/shared/components/controls/ControlFrame.vue';
  import { onLeave as vOnLeave } from '@/shared/on-outside';

  import createSensitiveDirective from '@/shared/sensitive/createSensitiveDirective';

  const props = defineProps<{
    dataTestref?: string;
    hasError?: boolean;
    isFullwidth?: boolean;
    startFocused?: boolean;
    sensitive?: boolean;
    disabled?: boolean;
    options: readonly { value: string, label: string }[];
    canDeselect?: boolean; // If set to true, clicking a selected item will deselect it
    multiple?: boolean; // If set to true, multiple items can be selected and modelValue is an array
  }>();

  const emit = defineEmits<{
    'blur': [];
  }>();

  const model = defineModel<string | string[] | null>({ default: null });

  const vSensitive = createSensitiveDirective(() => props.sensitive);

  defineExpose({
    focus,
  });

  onMounted(() => {
    if (props.startFocused) focus();
  });

  const getModelValueAsArray = () => {
    if (Array.isArray(model.value)) return model.value;
    return model.value ? [model.value] : [];
  };

  interface ButtonOption {
    index: number;
    ref: Ref<HTMLButtonElement>;
    tabIndex: number;
    isSelected: boolean;
    label: string;
    value: string;
  }

  const btnOptions = computed(() => props.options.map(({ value, label }, index) => {
    let shouldFocus = false;
    const modelValue = getModelValueAsArray();
    if (!model.value && index === 0) shouldFocus = true;
    if (modelValue.length && value === modelValue[0]) shouldFocus = true; // otherwise tab takes you to the first selected value
    return {
      index,
      ref: ref(),
      tabIndex: shouldFocus ? 0 : -1,
      isSelected: modelValue.includes(value),
      label,
      value,
    } as ButtonOption;
  }));

  function focus() {
    const focusOption = btnOptions.value?.find(o => o.tabIndex === 0);
    focusOption?.ref.value?.focus();
  }

  function blur() {
    emit('blur');
  }

  function toggleValue(option: ButtonOption) {
    if (props.multiple) {
      const values = getModelValueAsArray();
      if (values.includes(option.value)) {
        values.splice(values.indexOf(option.value), 1);
      } else {
        values.push(option.value);
      }
      model.value = values;
      return;
    }

    if (option.isSelected) {
      if (!props.canDeselect) return;
      model.value = null;
    } else {
      model.value = option.value;
    }
  }

  /*
from https://flip-eng.atlassian.net/wiki/spaces/PR/pages/1870757917/Segmented+control#Keyboard-Interactions

 */
  const handleKeyboardInteraction = (e: KeyboardEvent) => {
    const target = (e.target as HTMLElement);
    const buttons = Array.prototype.slice.call(target.parentNode?.children);
    if (!buttons) return;
    const button = e.target as HTMLButtonElement;
    const index = buttons.indexOf(button);
    if (index === -1) return; // no button selected?

    switch (e.key) {
    case 'Enter': // Activates/deactivates the item. (Space does this by default)
      button?.click();
      break;
    case 'ArrowDown': // Moves focus to the next item in the group.
    case 'ArrowRight':
      if (index < buttons.length - 1) buttons[index + 1].focus();
      break;
    case 'ArrowUp': // Moves focus to the previous item in the group.
    case 'ArrowLeft':
      if (index > 0) buttons[index - 1].focus();
      break;
    case 'Home': // Moves focus to the first item.
      buttons[0].focus();
      break;
    case 'End': // Moves focus to the last item.
      buttons[buttons.length - 1].focus();
      break;
    default:
      return;
    }
    e.preventDefault();
  };
</script>

<style scoped lang="scss">
.control-segmented {
  padding: 0;
  display: inline-flex;
  align-items: stretch;

  &.is-fullwidth {
    width: 100%;
  }
}

button {
  @include H4-default;
  display: block;
  border: 0;
  background-color: transparent;
  height: 100%;
  padding: 0 16px;
  flex: 1;
  position: relative;

  &:not(:last-child) {
    margin-right: 1px;
    &:after {
      position: absolute;
      right: -1px;
      top: 0;
      content: '';
      border-right: 1px solid var(--chrome-50);
      height: 100%;
    }
  }

  .option:last-child & {
    border-radius: 0 3px 3px 0;
  }

  .option:first-child & {
    border-radius: 3px 0 0 3px;
  }

  &:active,
  &:focus {
    outline: 1px solid var(--brand-100);
    outline-offset: -1px;
  }

  &.active {
    background-color: var(--brand-10);
    color: var(--brand-100);
  }
}
</style>
