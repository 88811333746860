<template>
  <div
    v-sensitive
    class="control-frame input focus-within"
    :data-testref="dataTestref"
    :class="{
      'is-transparent': borderless,
      'is-disabled': disabled,
      'is-danger': hasError,
      'ignore-focus': ignoreFocus,
    }"
  >
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
  import { BasicControlProps } from '@/shared/components/controls/helpers/controlBehaviour';
  import createSensitiveDirective from '@/shared/sensitive/createSensitiveDirective';

  const props = defineProps<BasicControlProps & {
    disabled?: boolean,
    borderless?: boolean,
    ignoreFocus?: boolean,
  }>();

  const vSensitive = createSensitiveDirective(() => props.sensitive);
</script>

<style scoped lang="scss">
.control-frame {
  display: flex;
  align-items: center;

  // useful when it should take up the space of an input, but not look like one
  &.is-borderless {
    border-color: transparent;
    box-shadow: none;
  }
}
</style>
