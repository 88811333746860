import { withParams } from 'veritas';

export const passwordValidationRules = {
  minLength: (minLength => withParams(
    { type: 'minLength', min: minLength },
    value => minLength <= (value || '').toString().length,
  ))(8),
  uppercase: value => /[A-Z]/.test((value || '').toString()),
  lowercase: value => /[a-z]/.test((value || '').toString()),
  number: value => /[0-9]/.test((value || '').toString()),
};
