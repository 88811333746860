/**
 * Manifest model
 * represents a single manifest, including its status and the included consignments
 */

export const ManifestStatus = {
  Proposed: 'proposed',
  Approved: 'approved',
  Completed: 'completed',
  Rejected: 'rejected',
};

export const StagedConsignmentStatus = {
  Staged: 'staged',
  Accepted: 'accepted',
  Cancelled: 'cancelled',
  Rejected: 'rejected',
};

const StagedConsignment = ({
  id,
  status,
  code,
}) => {
  // if status is 'rejected' then code should be defined
  if (status === StagedConsignmentStatus.Rejected && !code) {
    logger.warn('staged consignment rejected without a code', { consignmentId: id });
  }
  return { id, status, code };
};

const create = ({
  id,
  created,
  status,
  consignmentType,
  siteId,
  dispatchDate,
  consignments = [],
  trackingNumber,
} = {}) => ({
  id,
  created,
  status,
  consignmentType,
  siteId,
  dispatchDate,
  consignments: consignments.map(StagedConsignment),
  // if status is 'completed' then trackingNumber should be included
  trackingNumber,
});

const createFromApi = ({
  id,
  attributes,
}) => create({
  id,
  ...attributes,
});

const Manifest = {
  create,
  createFromApi,
};

export default Manifest;
