import pickBy from 'lodash/pickBy';
import { computed, Ref, unref } from 'vue';

import { JSONAPIError, ValidationError } from '@/shared/services/api-client';

import createServerFailedRule, { type ServerErrors, type ServerValidation } from './createServerFailedRule';

const useBackendValidation = (rootPointer: string | RegExp, serverValidationState: Ref<ServerValidation[]>) => {
  const serverErrorsFlat = computed(() => (unref(serverValidationState)
    ? unref(serverValidationState).reduce((acc, serverError) => {
      acc[serverError.source.pointer] = {
        code: serverError.code,
        errorMessage: serverError.detail || serverError.title || 'is invalid',
        pointer: serverError.source.pointer,
      };
      return acc;
    }, {} as ServerErrors)
    : null));

  const locateServerError = (subPointer: string | RegExp) => {
    const serverErrors = unref(serverErrorsFlat);
    if (!serverErrors) return null;

    if (typeof rootPointer === 'string' && typeof subPointer === 'string') {
      const pointer = subPointer ? `${rootPointer}/${subPointer}` : rootPointer;
      return serverErrors[pointer];
    }

    // assuming that either rootPointer and subPointer can be a regex
    const rootPointerMatches = pickBy(serverErrors, (_, pointer) => (new RegExp(rootPointer)).test(pointer));
    return Object.entries(rootPointerMatches).filter(([pointer]) => {
      const testPointer = rootPointer instanceof RegExp
        ? pointer.replace(rootPointer.exec(pointer)?.[0] || '', '')
        : pointer.replace(rootPointer, '');
      return (new RegExp(subPointer)).test(testPointer);
    }).map(([, value]) => value);
  };

  const oldValueCache = {};

  return {
    serverValidationRule: createServerFailedRule(locateServerError, oldValueCache),
  };
};

const errorHasPointer = (e: JSONAPIError): e is ServerValidation => !!e.source?.pointer;

export const extractValidationErrors = (error: ValidationError) => {
  const validationErrors: ServerValidation[] = [];
  const otherErrors: JSONAPIError[] = [];

  if (!error.apiErrors) {
    throw new Error('Validation error received with no server validation errors', { cause: error });
  }

  error.apiErrors.forEach(e => {
    if (errorHasPointer(e)) {
      validationErrors.push(e);
    } else {
      // no pointer = no bueno, we'll throw a different error at the end of this
      otherErrors.push(e);
    }
  });

  return { validationErrors, otherErrors };
};


export default useBackendValidation;
