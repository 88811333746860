<template>
  <HeaderMenu v-if="showOrders" title="Orders" base-route="order" data-testref="nav-menu-orders" data-cue="orders-menu">
    <ListBoxItemRouterLink :to="{ name: 'order.manage' }" data-testref="nav-manage-order">Manage</ListBoxItemRouterLink>
  </HeaderMenu>
  <HeaderMenu title="Estimates" base-route="estimate" data-testref="nav-menu-estimates" data-cue="estimate-pricing-menu">
    <ListBoxItemRouterLink :to="{ name: 'estimate.create' }" data-testref="nav-create-estimate">Create</ListBoxItemRouterLink>
    <ListBoxItemRouterLink :to="{ name: 'estimate.manage' }" data-testref="nav-manage-estimate">Manage</ListBoxItemRouterLink>
  </HeaderMenu>
  <HeaderMenu title="Consignments" base-route="consignment" data-testref="nav-menu-consignments">
    <ListBoxItemRouterLink :to="{ name: 'consignment.create' }">Create</ListBoxItemRouterLink>
    <ListBoxItemRouterLink v-if="showResolveIssues" :to="{ name: 'consignment.issues' }">Resolve issues <span v-if="count" class="issues-count">{{ count }}</span></ListBoxItemRouterLink>
    <ListBoxItemRouterLink :to="{ name: 'consignment.readyToManifest' }">Ready to manifest</ListBoxItemRouterLink>
    <ListBoxItemRouterLink :to="{ name: 'track.consignments' }">Track</ListBoxItemRouterLink>
  </HeaderMenu>
  <HeaderMenu title="Pickups" base-route="pickup" data-testref="nav-menu-pickups">
    <ListBoxItemRouterLink :to="{ name: 'pickup.prefill' }" data-testref="nav-create-pickup">Create</ListBoxItemRouterLink>
    <ListBoxItemRouterLink :to="{ name: 'pickup.manage' }" data-testref="nav-manage-pickups">Manage</ListBoxItemRouterLink>
  </HeaderMenu>
  <HeaderMenu title="Manifests" base-route="manifest" data-testref="nav-menu-manifest">
    <ListBoxItemRouterLink :to="{ name: 'manifest.create' }">Create</ListBoxItemRouterLink>
    <ListBoxItemRouterLink :to="{ name: 'manifest.manage' }">Manage</ListBoxItemRouterLink>
  </HeaderMenu>
  <div class="is-right">
    <GlobalSearchInput sensitive class="global-search" />
  </div>
</template>

<script setup lang="ts">
  import { onMounted, watch } from 'vue';
  import { useRoute } from 'vue-router';


  import appFeatures from '@/app/config/appFeatures';
  import { useFeature } from '@/app/config/features';
  import { ListBoxItemRouterLink } from '@/shared/components/list-box';

  import HeaderMenu from '@App/layout/components/HeaderMenu.vue';
  import { useConsignmentsWithIssuesCount } from '@App/modules/Consignment/behaviours/useConsignmentsWithIssuesCount';
  import GlobalSearchInput from '@GlobalSearch/components/GlobalSearchInput.vue';

  const feature = useFeature();
  const showResolveIssues = feature.enabled(appFeatures.resolveIssues);
  const showOrders = feature.enabled(appFeatures.orderIntegration);

  const route = useRoute();
  const { count, fetchIssuesCount } = useConsignmentsWithIssuesCount();

  watch(() => [showResolveIssues.value, route.fullPath], ([show]) => {
    if (show) {
      fetchIssuesCount();
    }
  });

  onMounted(async () => {
    if (showResolveIssues) {
      await fetchIssuesCount();
    }
  });
</script>

<style scoped lang="scss">
.global-search {
  position: relative;
  width: 420px;
}

.issues-count {
  color: $white;
  background-color: var(--warning-100);
  padding-inline: 0.5rem;
  padding-block: 0.2rem;
  margin-inline-start: 1rem;
  border-radius: 50%;
}
</style>
