import { modelWithHighlightFactory } from '@/shared/models/helpers/ModelWithHighlight';
import type { NewShippingItemPreset, ShippingItemPreset } from '@/shared/models/ShippingItemPreset';
import { shippingItemPresetFactory } from '@/shared/models/ShippingItemPreset';

import mapPaginatedResponse from '@/shared/services/api-client/helpers/mapPaginatedResponse';
import MappingError from '@/shared/services/errors/MappingError';
import { components, operations } from '@/shared/services/schema/geppetto-org/shippingItemPresets.schema';

type ShippingItemPresetResponse = operations['getShippingItemPreset']['responses']['200']['content']['application/json'];
export const mapShippingItemPresetGetResponse =
  ({ data }: ShippingItemPresetResponse): ShippingItemPreset => shippingItemPresetFactory.create(data);

const createShippingItemPresetWithHighlight = modelWithHighlightFactory<ShippingItemPreset>(shippingItemPresetFactory.create);

type ShippingItemPresetSearchResponse = operations['searchShippingItemPresets']['responses']['200']['content']['application/json'];
export const mapShippingItemPresetSearchResultResponse =
  (response: ShippingItemPresetSearchResponse) => mapPaginatedResponse(createShippingItemPresetWithHighlight, response);

type CreateShippingItemPresetPayload = operations['createShippingItemPreset']['requestBody']['content']['application/json'];
export const mapNewShippingItemPresetToCreateShippingItemPresetPayload = (
  preset: NewShippingItemPreset,
): CreateShippingItemPresetPayload => {
  if (!preset.siteId
    || !preset.description
    || !preset.packagingType
    || !preset.quickAccessCode) {
    // this should never happen unless front-end validation fails
    throw new MappingError('ShippingItemPreset was created without all required fields');
  }

  return {
    data: {
      siteId: preset.siteId,
      quickAccessCode: preset.quickAccessCode,
      packagingType: preset.packagingType as components['schemas']['CreateResource']['data']['packagingType'],
      description: preset.description,
      length: preset.length ? Math.round(preset.length) : undefined,
      width: preset.width ? Math.round(preset.width) : undefined,
      height: preset.height ? Math.round(preset.height) : undefined,
      weight: preset.weight ? Math.round(preset.weight) : undefined,
    },
  };
};


type UpdateShippingItemPresetPayload = operations['updateShippingItemPreset']['requestBody']['content']['application/json'];
export const mapShippingItemPresetToUpdateShippingItemPresetPayload = (
  preset: ShippingItemPreset,
): UpdateShippingItemPresetPayload => {
  if (!preset.siteId
    || !preset.description
    || !preset.packagingType
    || !preset.quickAccessCode) {
    // this should never happen unless front-end validation fails
    throw new MappingError('ShippingItemPreset was created without all required fields');
  }

  return {
    data: {
      quickAccessCode: preset.quickAccessCode,
      packagingType: preset.packagingType as components['schemas']['UpdateResource']['data']['packagingType'],
      description: preset.description,
      length: preset.length ? Math.round(preset.length) : undefined,
      width: preset.width ? Math.round(preset.width) : undefined,
      height: preset.height ? Math.round(preset.height) : undefined,
      weight: preset.weight ? Math.round(preset.weight) : undefined,
    },
  };
};
