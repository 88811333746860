
export default class GraphQLError<TResponse> extends Error {
  public response: TResponse;
  public config = { method: 'post' }; // graphql is always POST

  constructor(
    message: Parameters<ErrorConstructor>[0],
    options: Parameters<ErrorConstructor>[1] | undefined,
    response: TResponse,
    ) {
    super(message, options);

    this.response = response;
  }
}
