/**
 * Based on https://flip-eng.atlassian.net/browse/GEPPIE-4044
 */

export const pickupInstrumentationTypes = {
  /**
   * Create metrics
   */
  // How far in advance are pickup requests submitted?
  // How many destinations does a single pickup request have?
  // How many packaging types does a single pickup request have?
  // What is the average lead time provided for a pickup?
  // What is the average pickup window provided?
  // How many pickups are successfully submitted via clone pickup action?
  // Did the user change the sender address?
  PICKUP_CREATED: 'pickup created',

  // How often do users click ‘show all services’ when selecting a service?
  PICKUP_CREATE_SHOW_ALL_SERVICES: 'pickup create show all services clicked',

  // How often do users make an error when submitting a pickup request?
  // What errors are most common?
  PICKUP_CREATE_ERROR: 'pickup create error',

  // todo: when unsupported freight is available to select:
  // How often does a user select a carrier service that doesn’t support the freight?
  // Does a user try to select a service when the cut off time has passed?
  PICKUP_CREATE_SERVICES_REQUESTED: 'pickup create request services',

  /**
   * View metrics
   */

  // todo: when enquiries are enabled
  // How often does the user lodge an enquiry?
  // What is/are the status/es of the booking when an enquiry is lodged?
  // What is/are the status/es of the booking when a user views a pickup request?

  // What is/are the status/es of the booking when a user views a pickup request?
  PICKUP_VIEW: 'pickup view',

  // How often do users use the timezone tooltip?
  // see PICKUP_TIMEZONE_TOOLTIP below

  // How many users click the clone pickup from the request actions menu?
  // What is the pickup status when the user clicks clone pickup from the request actions menu?
  PICKUP_VIEW_CLONE: 'pickup view clone',

  /**
   * Manage metrics
   */

  // todo: when enquiries are enabled
  // How often does the user lodge an enquiry?
  // What is/are the status/es of the booking when an enquiry is lodged?

  // How often do users use the timezone tooltip?
  // see PICKUP_TIMEZONE_TOOLTIP below

  /**
   * Generic metrics
   */
  PICKUP_TIMEZONE_TOOLTIP: 'pickup timezone tooltip',

  PICKUP_CREATE_SERVICE_SELECTED: 'pickup service selected',

  PICKUP_PREFILL_INITIATED: 'pickup prefill initiated',
  PICKUP_PREFILL_CUSTOM: 'pickup prefill custom',
  PICKUP_PREFILL_ERROR: 'pickup prefill error',
  PICKUP_CUTOFF_ERROR: 'pickup cutoff error',
};

export default pickupInstrumentationTypes;

