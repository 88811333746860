/**
 * ConsignmentSummary model
 * represents a single API result when searching/listing consignments
 */

import type {
 ConsignmentManifest, ConsignmentStatus, ConsignmentTrackingEvent, ConsignmentTransfer, ConsignmentType, ProofOfDeliveryEvent, ScanEvent,
} from '@/shared/models/Consignment';

import DeliveryTimeSlot from '@/shared/models/DeliveryTimeSlot';

import { components } from '@/shared/services/schema/consignment.schema';
import {
  ConsignmentPalletDocketNumbers,
  PalletCounts,
  PalletTransferType,
} from '@/shared/types/ConsignmentPallets';

import consignmentTrackingEventFactory from './ConsignmentTrackingEvent';

export interface ConsignmentSummaryAddressee {
  name: string,
  address: components['schemas']['Address'] | components['schemas']['UnverifiedAddress']
}

export type ConsignmentSummarySender = ConsignmentSummaryAddressee;
export type ConsignmentSummaryReceiver = ConsignmentSummaryAddressee & {
  specialInstructions?: string;
  deliveryTimeSlot?: DeliveryTimeSlot;
  deliveryReferences: string[];
};

interface ConsignmentSummary {
  id: string;
  type: ConsignmentType;
  status: ConsignmentStatus;
  consignmentNo: string;
  previousConsignmentNumbers?: string[],
  updated: RFC3339InstantString;
  dispatchDate: PlainDateString;
  estimatedTimeOfArrival?: RFC3339InstantString,
  estimatedTransitTimeDays?: number;
  references: string[];
  itemQuantity: number;
  volume: number;
  weight: number;
  site: {
    id: string;
  }
  manifest?: ConsignmentManifest,
  transfer?: ConsignmentTransfer;
  transferPolicy: components['schemas']['ConsignmentTransferPolicy']
  agreedService: {
    id: string;
    name: string;
  }
  sender: ConsignmentSummarySender;
  receiver: ConsignmentSummaryReceiver;
  organisation: {
    id: string;
  }
  carrier: {
    id: string;
    name: string;
  }
  tracking: ConsignmentTrackingEvent<ScanEvent | ProofOfDeliveryEvent>[];
  pallets: {
    transferType: PalletTransferType;
    quantity?: PalletCounts;
    docketNumbers?: ConsignmentPalletDocketNumbers;
  } | undefined;
  hasDangerousGoods: boolean;

  // derived
  pods: ConsignmentTrackingEvent<ProofOfDeliveryEvent>[];
  issues?: Record<string, number>;
}

export default ConsignmentSummary;

function create({
                  id,
                  type,
                  status,
                  consignmentNo,
                  previousConsignmentNumbers = [],
                  updated,
                  dispatchDate,
                  estimatedTimeOfArrival,
                  estimatedTransitTimeDays,
                  references = [],
                  itemQuantity,
                  volume,
                  weight,
                  organisation,
                  site,
                  carrier,
                  manifest,
                  transfer,
                  transferPolicy,
                  agreedService,
                  sender,
                  receiver,
                  tracking,
                  pallets,
                  hasDangerousGoods,
                  issues,
                }: Omit<ConsignmentSummary, 'pods'>): ConsignmentSummary {
  const trackingEventModels = tracking?.map(consignmentTrackingEventFactory.create) || [];
  const podEventModels = tracking?.filter(trackingEvent => trackingEvent.type === 'proofOfDelivery').map(consignmentTrackingEventFactory.create) || [];

  return {
    id,
    type,
    status,
    consignmentNo,
    previousConsignmentNumbers,
    updated,
    dispatchDate,
    estimatedTimeOfArrival,
    estimatedTransitTimeDays,
    references,
    itemQuantity,
    volume,
    weight,
    organisation,
    site,
    carrier,
    manifest,
    transfer,
    transferPolicy,
    agreedService,
    sender,
    receiver,
    pallets,
    hasDangerousGoods,
    issues,

    // mapped fields
    tracking: trackingEventModels,
    pods: podEventModels,
  };
}

const createFromApi = ({
  id,
  attributes,
}: { id: string, attributes: Omit<ConsignmentSummary, 'id'> }) => create({
  id,
  ...attributes,
});

export const consignmentSummaryFactory = {
  create,
  createFromApi,
};
