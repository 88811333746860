import { defineComponent, h, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import ProgressIndicator from '@/shared/components/ProgressIndicator.vue';

const StatefulRedirect = defineComponent({
  props: {
    redirectTo: { type: Function, required: true },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();

    onBeforeMount(async () => router.push(await props.redirectTo({ router, store }) || ''));

    return () => h(ProgressIndicator);
  },
});

export default StatefulRedirect;
