import { ReadonlyRefOrGetter, watchOnce } from '@vueuse/core';
import { Component, nextTick, Ref, toValue } from 'vue';

import ModalDialog from '@/shared/modals/ModalDialog.vue';
import { modalManager } from '@/shared/modals/modalManager';
import { ACTIONS, DIALOG_TYPE } from '@/shared/notify';

type ACTION_TYPES = typeof ACTIONS[keyof typeof ACTIONS];

export { DIALOG_TYPE, ACTIONS };

export interface ConfirmConfig {
  quickClose?: boolean;
  title: string;
  message?: string | Component;
  type?: typeof DIALOG_TYPE[keyof typeof DIALOG_TYPE];
  confirmLabel: string;
  confirmLoading?: ReadonlyRefOrGetter<boolean> | Ref<boolean>;
  cancelLabel?: string | false;
}

/**
 * Global helper function for confirm worflows
 */
function confirm(config: ConfirmConfig): Promise<ACTION_TYPES> {
  // defaults
  const dialogConfig = { type: DIALOG_TYPE.PRIMARY, ...config };

  return new Promise((res) => {
    const modal = modalManager.open(ModalDialog, {
      title: dialogConfig.title,
      message: dialogConfig.message,
      type: dialogConfig.type,
      confirmLabel: dialogConfig.confirmLabel,
      confirmLoading: dialogConfig.confirmLoading,
      cancelLabel: dialogConfig.cancelLabel,
      onConfirm: async () => {
        res(ACTIONS.CONFIRM);

        await nextTick();

        if (dialogConfig.confirmLoading && toValue(dialogConfig.confirmLoading)) {
          watchOnce(dialogConfig.confirmLoading, () => {
            modal.close();
          });
        } else {
          modal.close();
        }
      },
      onCancel: () => {
        res(ACTIONS.CANCEL);
        modal.close();
      },
    });
  });
}

export default confirm;
