/* eslint class-methods-use-this: "off" */

import { Amplify } from 'aws-amplify';
import {
  confirmResetPassword,
  confirmSignIn,
  fetchAuthSession,
  fetchMFAPreference,
  fetchUserAttributes,
  getCurrentUser,
  resetPassword,
  signIn,
  signOut,
  updateMFAPreference,
  verifyTOTPSetup,
} from 'aws-amplify/auth';

import MappedErrorType, { isMappedError, throwNextStepError } from './cognito-error-mapping';
import { AuthFeedbackError } from './errors';


/**
 * Map AuthService interface to Amplify.Auth API.
 *
 * @see https://docs.amplify.aws/lib/auth/overview?platform=js
 * @see https://aws-amplify.github.io/amplify-js/api/enums/autherrortypes.html
 */
export class CognitoAuthProvider {
  constructor(config = {}) {
    Amplify.configure({
      Auth: config,
    });
  }

  async signIn(username, password) {
    // Prevent Cognito from throwing a completely idiotic error about custom lambda triggers
    if (!password) {
      throw new AuthFeedbackError('Username or password is invalid');
    }

    let attributes;
    let user;

    try {
      user = await signIn({
        username: username.trim(),
        password,
      });

      if (!user.isSignedIn && user.nextStep) {
        throwNextStepError(user);
      }

      attributes = await fetchUserAttributes();

      return {
        ...user,
        attributes,
      };
    } catch (error) {
      if (isMappedError(error)) throw error;

      const MappedError = MappedErrorType(error);
      throw new MappedError();
    }
  }

  async signOut() {
    try {
      await signOut();
    } catch (error) {
      logger.debug('[Auth] signOut failed', { error });
    }
  }

  async setupMFATOTP({ token }) {
    try {
      await verifyTOTPSetup({ code: token });
      await updateMFAPreference({ sms: 'DISABLED', totp: 'PREFERRED' });
    } catch (error) {
      logger.debug('[Auth] setupTOTP failed', { error });

      const MappedError = MappedErrorType(error);
      throw new MappedError();
    }

    return this.getCurrentUser();
  }

  async verifyMFATOTP({ token }) {
    try {
      await confirmSignIn({ challengeResponse: token });
    } catch (error) {
      logger.debug('[Auth] verifyTOTP failed', { error });

      const MappedError = MappedErrorType(error);
      throw new MappedError();
    }

    return this.getCurrentUser();
  }

  async completeNewPassword(newPassword) {
    return confirmSignIn({ challengeResponse: newPassword });
  }

  async currentSession() {
    let session;
    try {
      session = await fetchAuthSession();
      return session.tokens ? session : undefined;
      // return await getCurrentUser();
    } catch (error) {
      const MappedError = MappedErrorType(error);
      throw new MappedError();
    }
  }

  async forgotPassword({ username }) {
    try {
      const result = await resetPassword({ username });

      return result.nextStep.codeDeliveryDetails;
    } catch (error) {
      const MappedError = MappedErrorType(error);
      throw new MappedError();
    }
  }

  async forgotPasswordComplete({ username, code, password }) {
    return confirmResetPassword({
      username,
      confirmationCode: code,
      newPassword: password,
    });
  }

  /*
   * returns user if authenticated or null no user logged in
   */
  async getCurrentUser() {
    let attributes;
    let user;
    try {
      user = await getCurrentUser();
      attributes = await fetchUserAttributes();

      return {
        ...user,
        attributes,
      };
    } catch (error) {
      if (error.name === 'UserUnAuthenticatedException') {
        return null;
      }
      logger.debug('[Auth] getCurrentUser failed', { error });
      throw error;
    }
  }

  async fetchMFAPreference() {
    let mfaPreferences;
    try {
      mfaPreferences = await fetchMFAPreference();
    } catch (error) {
      logger.debug('[Auth] fetchMFAPreference failed', { error });
      return {};
    }
    return mfaPreferences;
  }
}
