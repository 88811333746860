<template>
  <label
    class="control control-switch"
    :class="{
      'is-danger': hasError,
      'has-text-danger': hasError,
      [`position-${position}`]: true,
      [`vertical-align-${verticalAlign}`]: true,
    }"
    :data-testref="dataTestref"
  >
    <span class="control-label">
      <slot></slot>
    </span>
    <div class="switch">
      <input
        ref="inputEl"
        v-model="computedValue"
        v-sensitive
        type="checkbox"
        :value="value"
        autocomplete="off"
        :disabled="disabled"
        @keydown="handleEnterKey"
      >
      <div class="track"></div>
    </div>
  </label>
</template>

<script setup lang="ts">
  import { computed } from 'vue';

  import {
    basicControl,
    BasicControlProps,
  } from '@/shared/components/controls/helpers/controlBehaviour';

  const props = withDefaults(defineProps<BasicControlProps & {
    disabled?: boolean;
    modelValue?: boolean;
    value?: unknown | undefined;
    position?: 'left' | 'right';
    verticalAlign? : 'top' | 'center' | 'bottom';
  }>(), {
    position: 'right',
    value: undefined,
    modelValue: undefined,
    verticalAlign: 'center',
  });

  const emit = defineEmits<{
    'update:modelValue': [boolean]
  }>();

  const { inputEl, focus, select, vSensitive } = basicControl(props);

  const computedValue = computed({
    get() {
      return props.modelValue;
    },
    set(value) {
      emit('update:modelValue', value || false);
    },
  });

  const handleEnterKey = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      computedValue.value = !computedValue.value;
    }
  };

  defineExpose({
    focus,
    select,
  });
</script>

<style lang="scss" scoped>
$track-width: 28px;
$track-height: 18px;
$thumb-size: 14px;
$thumb-offset: math.div($track-height - $thumb-size, 2);
$transition-speed: 150ms;

.control-switch {
  display: inline-flex;

  &.vertical-align-top {
    align-items: flex-start;
    .switch { margin-top: 2px }
  }
  &.vertical-align-center {
    align-items: center;
  }
  &.vertical-align-bottom {
    align-items: flex-end;
    .switch { margin-bottom: 1px }
  }

  &:active .track,
  &:focus-within .track,
  &.focused .track {
    //box-shadow: 0 0 4px 3px rgba(#472B88, 0.25);
    box-shadow: 0 0 4px 3px rgba(var(--brand-100), 0.25);
    outline-style: solid;
    outline-color: var(--brand-100);
    outline-width: 1px;
    outline-offset: 1px;
  }
}

.control-label {
  padding-right: $control-padding-horizontal;
  flex: 1;

  .position-left & {
    order: 2;
    padding-left: $control-padding-horizontal;
    padding-right: 0;
  }
}

.switch {
  display: block;
  position: relative;
  min-width: $track-width;
  height: $track-height;
}

input {
  opacity: 0;
  width: 0;
  height: 0;

  + .track {
    position: absolute;
    cursor: pointer;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--copy-30);
    transition: $transition-speed;
    border-radius: math.div($track-height, 2);

    &:before {
      position: absolute;
      content: "";
      height: $thumb-size;
      width: $thumb-size;
      border-radius: math.div($thumb-size, 2);
      left: $thumb-offset;
      bottom: $thumb-offset;
      background-color: #fff;
      transition: $transition-speed;
    }
  }

  &:checked + .track {
    background-color: var(--brand-100);
    &:before {
      transform: translateX($track-width - $thumb-size - $thumb-offset * 2);
    }
  }

  &:disabled + .track {
    cursor: not-allowed;
    background-color: var(--chrome-50);
  }

  &:checked:disabled + .track {
    background-color: var(--brand-20);
  }
}
</style>
