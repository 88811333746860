
import {
  AuthError,
  AuthFeedbackError,
  MFAVerificationRequiredError,
  NoAuthenticatedUserError,
  PasswordResetRequiredError,
  PasswordSetupRequiredError,
  SigninInProgressError,
  TempPasswordExpiredError,
  TOTPMismatchError,
  UserAlreadyAuthenticatedError,
} from './errors';

/**
 * Translate error messages / codes into error objects.
 *
 * If the UI needs to handle any of these errors using a special case, then they should be
 * declared as a new Error type in errors.js.
 *
 * Adapted from:
 * https://github.com/aws-amplify/amplify-js/blob/b8645f64c6da7400e2ee78c09c4eff5a592d1042/packages/aws-amplify-react-native/src/AmplifyMessageMap.ts
 * See also https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html
 *
 * @see @aws-amplify/auth/src/common/AuthErrorStrings.ts
 */
const CognitoErrorMap = [
  // codes represent a class of errors, whereas messages typically represent a specific
  // error, therefor we typically check message matches first.

  // TODO convert this file to .ts and make use of
  // @aws-amplify/auth/src/common/AuthErrorStrings.ts directly
  {
    messageExact: 'No current user',
    type: NoAuthenticatedUserError,
  },
  {
    messageRegex: /temporary\spassword\shas\sexpired/i,
    type: TempPasswordExpiredError,
    message: 'Contact efm to have your temporary password reset',
  },
  {
    messageExact: 'User password cannot be reset in the current state.',
    type: TempPasswordExpiredError,
    message: 'Contact efm to have your temporary password reset',
  },
  {
    messageExact: 'Username cannot be empty',
    type: AuthFeedbackError,
    message: 'Username cannot be empty',
  },
  {
    messageRegex: /incorrect.*username.*password/i,
    type: AuthFeedbackError,
    message: 'Invalid username, email or password',
  },
  {
    messageRegex: /password.*policy/i,
    type: AuthFeedbackError,
    message: 'Password should have at least 8 characters, including numbers and uppercase and lowercase characters',
  },
  {
    messageRegex: /email is missing/i,
    type: AuthFeedbackError,
    message: 'User does not have an email address',
  },
  {
    messageRegex: /session is expired/i,
    type: AuthFeedbackError,
    message: 'Your session has expired, please log in again',
  },
  {
    messageRegex: /password.*attempts.*exceeded/i,
    type: AuthFeedbackError,
    message: 'Too many failed login attempts, try again in 30 seconds',
  },

  // cognito error codes can sometimes include multiple error types
  {
    codeRegex: /PasswordResetRequiredException/,
    type: PasswordResetRequiredError,
  },
  {
    codeExact: 'InvalidParameterException',
    type: AuthFeedbackError,
    message: 'Invalid username, email or password',
  },
  {
    nameExact: 'InvalidParameterException',
    type: AuthFeedbackError,
    message: 'Invalid username, email or password',
  },

  {
    nameExact: 'UserAlreadyAuthenticatedException',
    type: UserAlreadyAuthenticatedError,
  },
  {
    codeExact: 'NotAuthorizedException',
    messageExact: 'Refresh Token has expired',
    type: AuthFeedbackError,
    message: 'Your session has expired, please log in again',
  },
  {
    codeExact: 'NotAuthorizedException',
    type: AuthFeedbackError,
    message: 'Invalid username or password',
  },
  {
    codeExact: 'TooManyRequestsException',
    type: AuthFeedbackError,
    message: 'Too many failed attempts, try again in 30 seconds',
  },
  {
    codeExact: 'LimitExceededException',
    type: AuthFeedbackError,
    message: 'Too many failed attempts, try again in 30 seconds',
  },
  {
    codeExact: 'NetworkError',
    message: 'Network error, please check your internet connection',
  },
  {
    codeRegex: /UserNotFoundException/,
    type: AuthFeedbackError,
    message: 'Username not found',
  },
  {
    messageExact: 'Pending sign-in attempt already in progress',
    type: SigninInProgressError,
  },

  // MFA
  {
    nameExact: 'EnableSoftwareTokenMFAException',
    type: TOTPMismatchError,
  },
  {
    nameExact: 'CodeMismatchException',
    type: TOTPMismatchError,
  },

  // Password reset
  {
    messageRegex: /previousPassword.*failed to satisfy constraint/i,
    type: AuthFeedbackError,
    message: 'Old password is invalid',
  },
  {
    messageRegex: /proposedPassword.*failed to satisfy constraint/i,
    type: AuthFeedbackError,
    message: 'New password is invalid',
  },

  // If no patterns match, a GeneralAuthError will be matched
];

const nextStepErrorType = [
  {
    step: 'RESET_PASSWORD',
    type: PasswordResetRequiredError,
  },
  {
    step: 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
    type: PasswordSetupRequiredError,
  },
  {
    step: 'CONFIRM_SIGN_IN_WITH_TOTP_CODE',
    type: MFAVerificationRequiredError,
  },
];

const findMatchingMapper = error => CognitoErrorMap.find(errorMapping => (
  (errorMapping.messageExact && errorMapping.messageExact === error.message)
  || (errorMapping.codeExact && errorMapping.codeExact === error.code)
  || (errorMapping.nameExact && errorMapping.nameExact === error.name)
  || errorMapping.messageRegex?.test(error.message)
  || errorMapping.codeRegex?.test(error.code)
));

export function throwNextStepError(user) {
  const errorMapping = nextStepErrorType.find(errorType => errorType.step === user.nextStep.signInStep);

  const NextStepErrorType = (errorMapping?.type) || AuthError;
  const message = errorMapping?.message;

  throw new NextStepErrorType(message || undefined);
}

export function isMappedError(error) {
  return [
    AuthError,
    AuthFeedbackError,
    NoAuthenticatedUserError,
    PasswordResetRequiredError,
    PasswordSetupRequiredError,
    MFAVerificationRequiredError,
    SigninInProgressError,
    TempPasswordExpiredError,
  ].some(errorType => error instanceof errorType);
}

export default function MappedErrorType(cognitoError) {
  let error = cognitoError;
  if (typeof error === 'string') {
    error = { message: error };
  }

  const errorMapping = findMatchingMapper(error);

  if (!errorMapping) {
    logger.error('[CognitoErrorMap] Error with no mapping found', { cognitoError });
  } else {
    logger.info('[CognitoErrorMap] Error with mapping found', { cognitoError });
  }
  const type = (errorMapping?.type) || AuthError;
  const message = errorMapping?.message;

  // return a constructor for the mapped error type to be invoked
  return type.bind(null, message || undefined, { cause: cognitoError });
}
