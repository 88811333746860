<template>
  <div class="section">
    <img class="image mb-4 mt-6" src="@/assets/404.svg?url" alt="404">
    <br>
    <div class="content">
      <h1 class="is-text-h1 has-text-centered">Invalid or expired link.</h1>
      <p>For support, call efm Logistics on 1300 769 705.</p>
    </div>
    <hr>
  </div>
</template>

<style scoped>
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>
