import FullStory from '@/shared/FullStory';
import instrumentation from '@/shared/instrumentation';

import { MovementFlowType } from '@/shared/models';

import userMonitor from '@App/instrumentation/userMonitor';
import DGFormData from '@DangerousGoods/models/DGFormData';

import { DGResourceType } from '@DangerousGoods/types';

import { DGInstrumentationTypes } from './types';

// don't count these as changes between a preset and a DG
const EXCLUDE_CHANGED_KEYS = [
  'receptacleCount',
  'totalAggregateQuantityAmount',
  'totalAggregateQuantityUnit',
];

const getChangedFields = (initialData: DGFormData, formData: DGFormData) => {
  if (!formData.presetId) return undefined;
  return Object.entries(formData).reduce((acc, [key, value]) => {
    if (!EXCLUDE_CHANGED_KEYS.includes(key) && value?.toString() !== initialData[key as keyof DGFormData]?.toString()) {
      return [...acc, key];
    }
    return acc;
  }, [] as string[]);
};

export interface DGAddedInstrumentationData {
  resourceType: DGResourceType;
  flowType: MovementFlowType;
  formData: DGFormData;
  initialData: DGFormData;
  savePreset: boolean,
}

// @type:action @application.id:f5e7ca39-c083-412b-b4be-4312000a2318 @action.name:"dg added"
instrumentation.on(DGInstrumentationTypes.DG_ADDED, (data: DGAddedInstrumentationData) => {
  const payload = {
    resourceType: data.resourceType, // @context.dg.resourceType (facet)
    flowType: data.flowType, // @context.dg.flowType (facet)
    presetUsed: !!data.formData.presetId, // @context.dg.presetUsed (facet)
    presetSaved: data.savePreset, // @context.dg.presetSaved (facet)
    dgType: data.formData.dgType, // @context.dg.dgType (facet)
    presetFieldsChanged: getChangedFields(data.initialData, data.formData),
  };

  userMonitor.event(DGInstrumentationTypes.DG_ADDED, { dg: payload });
  FullStory.event(DGInstrumentationTypes.DG_ADDED, payload);
});

type DGAbandonedInstrumentationData = Omit<DGAddedInstrumentationData, 'initialData'>;

// @type:action @application.id:f5e7ca39-c083-412b-b4be-4312000a2318 @action.name:"dg abandoned"
instrumentation.on(DGInstrumentationTypes.DG_ABANDONED, (data: DGAbandonedInstrumentationData) => {
  const payload = {
    resourceType: data.resourceType, // @context.dg.resourceType (facet)
    flowType: data.flowType, // @context.dg.flowType (facet)
    presetUsed: !!data.formData.presetId, // @context.dg.presetUsed (facet)
    dgType: data.formData.dgType, // @context.dg.dgType (facet)
  };

  userMonitor.event(DGInstrumentationTypes.DG_ABANDONED, { dg: payload });
  FullStory.event(DGInstrumentationTypes.DG_ABANDONED, payload);
});

type DGGlossaryInstrumentationData = {
  resourceType: DGResourceType;
  flowType: MovementFlowType;
  view: string;
};

// @type:action @application.id:f5e7ca39-c083-412b-b4be-4312000a2318 @action.name:"dg glossary"
instrumentation.on(DGInstrumentationTypes.DG_GLOSSARY, (data: DGGlossaryInstrumentationData) => {
  const payload = {
    resourceType: data.resourceType, // @context.dg.resourceType (facet)
    flowType: data.flowType, // @context.dg.flowType (facet)
    view: data.view, // @context.dg.view (facet)
  };
  userMonitor.event(DGInstrumentationTypes.DG_GLOSSARY, { dg: payload });
  FullStory.event(DGInstrumentationTypes.DG_GLOSSARY, payload);
});

interface DGAdvancedFilterInstrumentationData {
  resourceType: DGResourceType;
  view: string;
}

// @type:action @application.id:f5e7ca39-c083-412b-b4be-4312000a2318 @action.name:"dg advanced filter"
instrumentation.on(DGInstrumentationTypes.DG_ADVANCED_FILTER, (data: DGAdvancedFilterInstrumentationData) => {
  const payload = {
    resourceType: data.resourceType, // @context.dg.resourceType (facet)
    view: data.view, // @context.dg.view (facet)
  };
  userMonitor.event(DGInstrumentationTypes.DG_ADVANCED_FILTER, { dg: payload });
  FullStory.event(DGInstrumentationTypes.DG_ADVANCED_FILTER, payload);
});

interface DGViewIconInstrumentationData {
  resourceType: DGResourceType;
  flowType: MovementFlowType;
}

// @type:action @application.id:f5e7ca39-c083-412b-b4be-4312000a2318 @action.name:"dg view icon"
instrumentation.on(DGInstrumentationTypes.DG_VIEW_ICON, (data: DGViewIconInstrumentationData) => {
  const payload = {
    resourceType: data.resourceType, // @context.dg.resourceType (facet)
    flowType: data.flowType, // @context.dg.view (facet)
  };
  userMonitor.event(DGInstrumentationTypes.DG_VIEW_ICON, { dg: payload });
  FullStory.event(DGInstrumentationTypes.DG_VIEW_ICON, payload);
});

instrumentation.before(DGInstrumentationTypes.DG_PRESET_BULK_DELETE, (payload: unknown) => payload);
instrumentation.on(DGInstrumentationTypes.DG_PRESET_BULK_DELETE, ({ presetIds, duration }: {
  presetIds: string[],
  duration: number
}) => {
  userMonitor.event('dg presets bulk deleted', {
    duration,
    presets_deleted: presetIds.length, // Measure: @context.presets_deleted
  });

  FullStory.event('dg presets bulk deleted', {
    duration,
    presetsDeleted: presetIds.length,
  }, {
    duration: 'int',
    presetsDeleted: 'int',
  });
});

interface DGPresetImportData {
  total: number;
  successful: number;
  existing: number;
  duration: number;
  validationErrors: string[],
}

instrumentation.before(DGInstrumentationTypes.DG_PRESET_IMPORT, (payload: unknown) => payload);
instrumentation.on(DGInstrumentationTypes.DG_PRESET_IMPORT, ({
                                                               total,
                                                               successful,
                                                               existing,
                                                               duration,
                                                               validationErrors,
                                                             }: DGPresetImportData) => {
  userMonitor.event('dangerous goods presets imported', {
    total, // Measure: @context.total
    imported: successful, // Measure: @context.imported
    existing, // Measure: @context.existing
    validation_errors: validationErrors, // Measure: @context.validation_errors
    duration,
  });

  FullStory.event('dangerous goods presets imported', {
    total,
    imported: successful,
    existing,
    validationErrors,
    duration,
  }, {
    total: 'int',
    imported: 'int',
    existing: 'int',
    validationErrors: 'int',
    duration: 'int',
  });
});

interface DGPresetExportData {
  total: number;
  filterApplied: boolean;
  duration: number;
}

instrumentation.before(DGInstrumentationTypes.DG_PRESET_EXPORT, (payload: unknown) => payload);
instrumentation.on(DGInstrumentationTypes.DG_PRESET_EXPORT, ({ duration, total, filterApplied }: DGPresetExportData) => {
  userMonitor.event('dangerous goods presets exported', {
    duration,
    presets_exported: total, // Measure: @context.presets_exported
    filter_applied: filterApplied, // Facet: @context.filter_applied
  });

  FullStory.event('dangerous goods presets exported', {
    duration,
    presetsExported: total,
    filterApplied,
  }, {
    duration: 'int',
    presetsExported: 'int',
  });
});
