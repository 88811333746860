<script setup lang="ts">
  import ModalCard from '@/shared/modals/ModalCard.vue';
  import { useModal } from '@/shared/modalStack';

  const modal = useModal();

  defineProps<{
    prompt: 'type' | 'version';
  }>();
  const emit = defineEmits<{
    'accept': [];
  }>();

  function acceptAndClose() {
    emit('accept');
    modal.close();
  }
</script>

<template>
  <ModalCard
    title="Browser not supported"
    class="has-solid-background"
    data-testref="browser-check-modal"
    :quick-close="false"
  >
    <template v-if="prompt === 'type'">
      <p>You are using a browser that we do not support. For the optimal experience use Google Chrome.</p>
      <br>
      <a href="https://www.google.com.au/chrome/">Download Google Chrome</a>
    </template>
    <template v-else-if="prompt === 'version'">
      <p>You are using an out-of-date version of Google Chrome which may block functionality. For the optimal experience update your browser.</p>
      <br>
      <a href="https://www.google.com.au/chrome/">Update Google Chrome</a>
    </template>
    <div class="buttons is-right">
      <button type="button" class="button is-primary" @click="acceptAndClose">Continue</button>
    </div>
  </ModalCard>
</template>

<style scoped lang="scss">

</style>
