import FullStory from '@/shared/FullStory';
import Instrumentation from '@/shared/instrumentation';

import userMonitor from '@App/instrumentation/userMonitor';

export enum AutoprintConfigInstrumentation {
  InstallationRefreshClicked = 'installation refresh clicked',
  HelpGuideDownloaded = 'autoprint help guide downloaded',
  InstallerDownloaded = 'autoprint installer downloaded',
  InstallationCompleted = 'autoprint installation completed',
}

Instrumentation.on(AutoprintConfigInstrumentation.InstallationRefreshClicked, () => {
  userMonitor.event('autoprint refresh clicked');
  FullStory.event('autoprint refresh clicked', {});
});

Instrumentation.on(AutoprintConfigInstrumentation.HelpGuideDownloaded, () => {
  userMonitor.event('autoprint help guide downloaded');
  FullStory.event('autoprint help guide downloaded', {});
});

Instrumentation.on(AutoprintConfigInstrumentation.InstallerDownloaded, () => {
  userMonitor.event('autoprint installer downloaded');
  FullStory.event('autoprint installer downloaded', {});
});

Instrumentation.on(AutoprintConfigInstrumentation.InstallationCompleted, ({ clientDetected }: {
  clientDetected: boolean;
}) => {
  const context = {
    autoprint: {
      // @context.autoprint.clientDetected (facet)
      clientDetected,
    },
  };

  userMonitor.event('autoprint installation completed', context);
  FullStory.event('autoprint installation completed', {
    clientDetected,
  });
});
