import { NewConsignment, QuoteSet } from '@/shared/models';
import APIClient from '@/shared/services/api-client';
import { operations } from '@/shared/services/schema/quotes.schema';

import * as mappers from './mappers';

export default class QuoteClient {
  private apiClient: APIClient;
  private concurrencyControllers: Record<string, AbortController>;

  constructor(apiClient: APIClient) {
    this.apiClient = apiClient;
    this.concurrencyControllers = {};
  }

  async requestQuotes(consignment: NewConsignment, concurrencyKey?: string): Promise<QuoteSet> {
    if (consignment.lineItems.length < 1) {
      throw new Error('[Sender] Cannot request quote without any line items');
    }

    // build the config parameter as we go
    const config: Parameters<APIClient['post']>[2] = {};

    if (concurrencyKey) {
      this.cancelRequestQuotes(concurrencyKey);
      this.concurrencyControllers[concurrencyKey] = new AbortController();
      config.signal = this.concurrencyControllers[concurrencyKey].signal;
    }

    try {
      const response = await this.apiClient.post<operations['createQuoteSet']['responses']['200']['content']['application/json']>(
        '/v0/quote-sets',
        mappers.quote.consignmentToCreateQuoteSetRequest(consignment),
        config,
      );
      return mappers.quote.clientQuoteSetToQuoteSet(response.data.data, consignment);
    } finally {
      if (concurrencyKey) {
        delete this.concurrencyControllers[concurrencyKey];
      }
    }
  }

  cancelRequestQuotes(concurrencyKey: string) {
    if (this.concurrencyControllers[concurrencyKey]) {
      // already aborted
      if (this.concurrencyControllers[concurrencyKey].signal.aborted) {
        return;
      }
      this.concurrencyControllers[concurrencyKey].abort();
    }
  }
}
