import instrumentation from '@/shared/instrumentation';

import { FormEvents } from '@App/instrumentation/formEvents';
import userMonitor from '@App/instrumentation/userMonitor';

instrumentation.on(FormEvents.formSectionTransition, ({ component, inputType = 'other', transition }) => {
  // Not currently interested in logging display transitions
  if (transition !== 'edit') return;

  userMonitor.event('form section transitioned', {
    interaction: {
      // @interaction.section (Facet)
      section: component.title.toLowerCase(),
      // @interaction.method (Facet)
      method: inputType,
      // @interaction.transition (Facet)
      transition,
    },
  });
});
