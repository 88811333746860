import { type AppConfig, type ComponentPublicInstance } from 'vue';

import { errorIsDisplayed } from './helpers';

type ErrorHandler = NonNullable<AppConfig['errorHandler']>;

export default function handleErrorCaptured(error: Parameters<ErrorHandler>[0], vm: Parameters<ErrorHandler>[1], info: Parameters<ErrorHandler>[2]) {
  if (!(error instanceof Error)) {
    logger.warn('Non-Error object received by handleErrorCaptured', { error });
    return;
  }

  // ignore route navigation abort errors which can be thrown from valid app logic
  // aborting navigation, ie data loss warning.
  if (error.message.match(/navigation aborted from .* navigation guard/i)) {
    return;
  }

  if (import.meta.env?.NODE_ENV === 'development' && info?.includes('render')) {
    logger.warn(`render errors cannot be handled properly by the errorHandling system in development mode.
@see https://github.com/vuejs/core/blob/ae4b0783d78670b6e942ae2a4e3ec6efbbffa158/packages/runtime-core/src/errorHandling.ts#L157-L161 for why.`, {});
  }

  try {
    if (vm) {
      // Trace the component hierarchy outwards until an errorHandle hook is found
      let component: ComponentPublicInstance | null = vm;
      do {
        // $.errorHandle is attached to component instances by useErrorBoundary composable
        if (component.$.errorHandle) {
          logger.debug('errorHandle hook found', { error, displayed: errorIsDisplayed(error) }, { vm, component });
          const capture = component.$.errorHandle.call(component, error, vm, info) === false;
          if (capture) return;
        }
        // bubble outwards to the parent
        component = component.$parent;
      } while (component);
    }
  } catch (e) {
    logger.error('[ErrorHandling] error handling aborted', { error: e });
  }
}
