export const parseEventTypes = (eventsString, allowedEvents) => {
  if (typeof eventsString !== 'string') {
    return null;
  }

  return eventsString
    .split(',')
    .map(event => event.trim())
    .filter(event => allowedEvents.includes(event));
};

const eventTargetIsInsideWhitelist = (event, whitelist = []) => whitelist.some(
  selector => [...document.querySelectorAll(selector)].some(
    el => el.contains(event.target),
  ),
);

export const eventTargetIsOutsideEl = (el, event, whitelist) => (
  (event.target instanceof Window || (el !== event.target && !el.contains(event.target)))
  && !eventTargetIsInsideWhitelist(event, whitelist)
);

export const callHandlerIfOutside = ({ el, handler, whitelist }, event) => {
  if (eventTargetIsOutsideEl(el, event, whitelist)) {
    handler(event);
  }
};

// Using capture will ensure this event fires before other handlers bound to this event. This
// is useful in the case of components who add/remove markup from the DOM such as dropdowns,
// to ensure that we check whether the target element is "inside" our element before it gets
// removed from the DOM, at which point it will appear to be "outside" our element.
export const addListenersTo = (listenEl, eventTypes, handler, options = { capture: true }) => {
  eventTypes.forEach(event => {
    listenEl.addEventListener(event, handler, options);
  });
};

export const removeListenersFrom = (listenEl, eventTypes, handler, options = { capture: true }) => {
  eventTypes.forEach(event => {
    listenEl.removeEventListener(event, handler, options);
  });
};
