<template>
  <div
    v-on-outside:click,focus="{ handler: closeDropdown, whitelist: [onOutsideWhitelist] }"
    class="autocomplete"
    :class="{ 'is-expanded': expanded, 'is-right': isRight }"
    :data-testref="dataTestref"
  >
    <ControlSearch
      ref="inputEl"
      v-model="newValue"
      :sensitive="sensitive"
      v-bind="$attrs"
      :loading="loading"
      :placeholder="placeholder"
      data-testref="autocomplete-control"
      @update:model-value="onInput"
      @focus="focused"
      @blur="onBlur"
      @clear="clear"
      @keyup.esc.prevent="isActive = false"
      @keydown.tab="tabPressed"
      @keydown.enter.prevent="enterPressed"
      @keydown.up.prevent="advanceHoveredBy(-1)"
      @keydown.down.prevent="advanceHoveredBy(1)"
    >
      <template #icon-left><slot name="icon-left"></slot></template>
      <template #icon-right><slot name="icon-right"></slot></template>
    </ControlSearch>
    <transition name="fade">
      <div
        v-show="displayDropdown"
        ref="dropdown"
        class="dropdown-menu"
        :class="{ 'is-opened-top': isOpenedTop }"
        :style="{}"
        data-testref="autocomplete-suggestions"
      >
        <div v-sensitive class="dropdown-content" :class="{ 'disable-scroll': disableDropdownScroll }">
          <div v-if="hasHeaderSlot" class="dropdown-item autocomplete-header">
            <slot name="header"></slot>
          </div>
          <template v-for="(element, groupindex) in groupedOptions">
            <div
              v-if="element.group"
              :key="`${groupindex}group`"
              class="dropdown-item"
            >
              <slot
                name="group"
                :group="element.group"
                :index="groupindex"
              >
                <span class="has-text-weight-bold">{{ element.group }}</span>
              </slot>
            </div>
            <a
              v-for="(option, index) in element.items"
              :key="`${groupindex}:${index}`"
              data-testref="dropdown-item"
              class="dropdown-item"
              :class="getItemClasses(option)"
              @click="setSelected(option, undefined, $event)"
            >
              <slot
                :option="option"
                :index="option.id"
              >
                <span v-sensitive>{{ getValue(option, true) }}</span>
              </slot>
            </a>
          </template>
          <div v-if="isEmpty && hasEmptySlot">
            <slot name="empty"></slot>
          </div>
          <div v-if="hasFooterSlot" class="dropdown-item">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import get from 'lodash/get';
  import groupBy from 'lodash/groupBy';
  import has from 'lodash/has';
  import isEqual from 'lodash/isEqual';
  import map from 'lodash/map';
  import { nextTick } from 'vue';

  import ControlSearch from '@/shared/components/controls/ControlSearch.vue';
  import InputBehaviourMixin from '@/shared/mixins/InputBehaviourMixin';
  import OnOutside from '@/shared/on-outside';

  export default {
    name: 'ControlAutocomplete',
    components: { ControlSearch },
    directives: { OnOutside },
    mixins: [InputBehaviourMixin],
    inheritAttrs: false,
    props: {
      // eslint-disable-next-line
      modelValue: [Number, String],
      options: { type: Array, default: () => [] },
      placeholder: { type: String, default: null },
      /** Property of the object. Used when options is an array of objects. */
      field: { type: String, default: 'value' },
      /** The fist option will be always pre-selected */
      keepFirst: { type: Boolean, default: false },
      clearOnSelect: { type: Boolean, default: false },
      openOnFocus: { type: Boolean, default: false },
      keepOpen: { type: Boolean, default: false },
      // select option when focused and tab is pressed
      tabSelect: { type: Boolean, default: true },
      /** `top`, `bottom`, `auto` */
      dropdownPosition: { type: String, default: 'auto' },
      disableDropdownScroll: { type: Boolean, default: false },
      // eslint-disable-next-line
      groupField: String,
      // eslint-disable-next-line
      groupOptions: String,
      isRight: { type: Boolean, default: false },

      expanded: { type: Boolean, default: false },
      loading: { type: Boolean, default: false },
      /** overrides the isActive property. Used when you need to control opening
       * or closing the dropdown based on external criteria. If null, isActive is
       * used */
      overrideIsActive: { type: Boolean, default: null },

      dataTestref: { type: String, default: undefined },

      onOutsideWhitelist: { default: undefined, type: String },

      itemClassFactory: { type: Function, default: undefined },

    },
    emits: {
      'update:modelValue': null,
      /** Emitted when user selects an option, but before the input is updated and dropdown closed */
      beforeSelect: null,
      /** Emitted when user selects an option */
      select: null,
      /** Emitted on text input */
      typing: null,
      focus: null,
      blur: null,
      /** Emitted when clear button is clicked. */
      clear: null,
    },
    data() {
      return {
        selected: null,
        hovered: null,
        isActive: false,
        newValue: this.modelValue,
        isListInViewportVertically: true,
        hasFocus: false,
        style: {},
      };
    },
    computed: {
      groupedOptions() {
        if (this.groupField) {
          if (this.groupOptions) {
            return this.options.map(option => ({
              group: get(option, this.groupField),
              items: get(option, this.groupOptions),
            }));
          }

          return map(groupBy(this.options, option => get(option, this.groupField), (items, group) => ({ group, items })));
        }
        return [{ items: this.options }];
      },
      allOptionsFlat() {
        return this.groupedOptions
          .flatMap(d => d.items);
      },
      isEmpty() {
        return !this.allOptionsFlat?.length;
      },

      /** Check if exists "empty" slot */
      hasEmptySlot() {
        return !!this.$slots.empty;
      },
      /** Check if exists "header" slot */
      hasHeaderSlot() {
        return !!this.$slots.header;
      },
      /** Check if exists "footer" slot */
      hasFooterSlot() {
        return !!this.$slots.footer;
      },
      /** Apply dropdownPosition property */
      isOpenedTop() {
        return (
          this.dropdownPosition === 'top' ||
          (this.dropdownPosition === 'auto' && !this.isListInViewportVertically)
        );
      },

      displayDropdown() {
        const dropdownHasContent = !this.isEmpty || this.hasEmptySlot || this.hasHeaderSlot;
        const displayDropdown = (this.isActive && dropdownHasContent);

        return this.overrideIsActive !== null ? this.overrideIsActive : displayDropdown;
      },
    },
    watch: {
      /**
       * When dropdown is toggled, check the visibility to know when
       * to open upwards.
       */
      isActive(active, wasActive) {
        if (this.dropdownPosition === 'auto') {
          if (active) {
            this.calcDropdownInViewportVertical();
          } else {
            // Timeout to wait for the animation to finish before recalculating
            setTimeout(() => {
              this.calcDropdownInViewportVertical();
            }, 100);
          }
        }

        if (!active && wasActive) {
          this.$inputEmit('blur');
        }
      },

      newValue(value) {
        // do not emit if the value hasn't changed. this causes events with no meaning
        // and can cause infinite loops when v-model is used
        if (value !== this.modelValue) {
          this.$inputEmit('update:modelValue', value);
        }
      },

      modelValue(value) {
        this.newValue = value;
      },
      allOptionsFlat() {
        // unselect a "hovered" option if it is no longer available
        if (!this.allOptionsFlat.find(this.isHovered)) {
          this.setHovered(null);
        }

        // when options change keep first option always pre-selected
        if (this.keepFirst) {
          this.selectFirstSelectableOption();
        }
      },
    },
    created() {
      if (typeof window !== 'undefined') {
        if (this.dropdownPosition === 'auto') {
          window.addEventListener('resize', this.calcDropdownInViewportVertical);
        }
      }
    },

    beforeUnmount() {
      if (typeof window !== 'undefined') {
        if (this.dropdownPosition === 'auto') {
          window.removeEventListener('resize', this.calcDropdownInViewportVertical);
        }
      }
    },
    methods: {
      /** Set which option is currently hovered. */
      setHovered(option) {
        this.hovered = (option !== undefined) ? option : null;
      },
      isHovered(option) {
        return isEqual(this.hovered, option);
      },
      /**
       * Set which option is currently selected, update v-model,
       * update input value and close dropdown.
       */
      async setSelected(option, closeDropdown = true, event = undefined) {
        if (option === undefined) return;

        // not $inputEmit, no need to trigger validation on a before event
        this.$emit('beforeSelect', {
          userInput: this.newValue,
          option,
        });

        this.selected = option;

        if (this.selected !== null) {
          this.newValue = this.clearOnSelect ? '' : this.getValue(this.selected);
          this.setHovered(null);
        }
        if (closeDropdown) {
          await nextTick();
          this.closeDropdown();
        }

        this.$inputEmit('select', this.selected, event);
      },
      /** Select first option */
      async selectFirstSelectableOption() {
        if (this.isEmpty) return;

        await nextTick();

        const firstOption = this.allOptionsFlat[0];
        if (this.openOnFocus || (this.newValue !== '' && !this.isHovered(firstOption))) {
          this.setHovered(firstOption);
        }
      },
      /**
       * Enter key listener.
       * Select the hovered option.
       */
      enterPressed(event) {
        if (this.hovered !== null) {
          this.setSelected(this.hovered, !this.keepOpen, event);
        }
      },
      /**
       * Tab key listener.
       * Select hovered option if it exists, close dropdown, then allow
       * native handling to move to next tabbable element.
       */
      tabPressed(event) {
        if (!this.isActive) return;

        if (this.hovered === null) {
          this.closeDropdown();
        } else if (this.tabSelect) {
          this.setSelected(this.hovered, !this.keepOpen, event);
        }
      },
      closeDropdown() {
        if (!this.isActive) return;

        this.isActive = false;
      },
      /**
       * Return display text for the input.
       * If object, get value from path, or else just the value.
       */
      getValue(option) {
        if (option === null || !this.field) return undefined;

        if (typeof option === 'object') {
          if (has(option, this.field)) {
            return get(option, this.field);
          }

          logger.warn(`InputAutocomplete - field ${this.field} not in selected option`);
        }

        return option;
      },

      /**
       * Calculate if the dropdown is vertically visible when activated,
       * otherwise it is openened upwards.
       */
      async calcDropdownInViewportVertical() {
        await nextTick();
        /**
         * this.$refs.dropdown may be undefined (or null)
         * when Autocomplete is conditional rendered
         */
        if (!this.$refs.dropdown) {
          return;
        }
        const rect = this.$refs.dropdown.getBoundingClientRect();
        this.isListInViewportVertically = rect.top >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
      },
      /**
       * Change the currently "hovered" option by an increment, used to select the next / prev
       * option using keyboard events.
       */
      advanceHoveredBy(increment) {
        // if the dropdown isn't active, pop it open
        if (!this.isActive) {
          this.isActive = true;
          return;
        }

        const currentIndex = this.allOptionsFlat.findIndex(this.isHovered);
        const toHoverIndex = Math.max(0, Math.min(this.allOptionsFlat.length - 1, currentIndex + increment));

        this.setHovered(this.allOptionsFlat[toHoverIndex]);

        const toHoverElement = this.$refs.dropdown
          .querySelectorAll('.dropdown-content > a.dropdown-item:not(.is-disabled)')[toHoverIndex];
        toHoverElement?.scrollIntoView({ block: 'nearest', inline: 'nearest' });
      },
      /**
       * Focus listener.
       * If value is the same as selected, select all text.
       */
      focused(event) {
        if (this.getValue(this.selected) === this.newValue) {
          this.$refs.inputEl.select();
        }
        if (this.openOnFocus) {
          this.isActive = true;
          if (this.keepFirst) {
            this.selectFirstSelectableOption();
          }
        }
        this.hasFocus = true;
        this.$inputEmit('focus', event);
      },
      /** Blur listener. */
      onBlur() {
        this.hasFocus = false;

        if (!this.isActive && !this.overrideIsActive) {
          this.$inputEmit('blur');
        }
      },
      onInput() {
        const currentValue = this.getValue(this.selected);
        if (currentValue && currentValue === this.newValue) return;
        this.$inputEmit('typing', this.newValue);

        this.clearSelected();
      },

      clear() {
        this.$emit('clear');
        this.clearSelected();
      },

      clearSelected() {
        // Check if selected is invalid
        const currentValue = this.getValue(this.selected);
        if (currentValue && currentValue !== this.newValue) {
          this.setSelected(null, false);
        }

        // nextTick is required to get up to date value of openOnFocus prop
        nextTick(() => {
          // Close dropdown if input is clear or else open it
          if (this.hasFocus && (!this.openOnFocus || this.newValue)) {
            this.isActive = !!this.newValue;
          }
        });
      },
      getItemClasses(option) {
        return {
          'is-hovered': this.isHovered(option),
          ...(this.itemClassFactory && this.itemClassFactory(option)),
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  $dropdown-content-max-height: 250px !default;

  .autocomplete {
    position: relative;

    .dropdown-menu {
      position: absolute;
      display: block;
      min-width: 100%;
      max-width: 100%;
      &.is-opened-top {
        top: auto;
        bottom: 100%;
      }

      .dropdown-content {
        overflow: auto;
        max-height: $dropdown-content-max-height;

        &.disable-scroll {
          overflow: visible;
          max-height: none;
        }
      }
    }

    &.is-right {
      .dropdown-menu {
        left: auto;
        right: 0;
      }
    }

    &.is-expanded {
      width: 100%;
    }
  }

  .dropdown-item {
    width: 100%;
    max-width: 100%;
    //margin: 0.815rem;
    //padding-right: 1rem;
    padding: 0.4rem 0.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $text;

    &:empty {
      padding: 0;
    }

    &.is-hovered {
      background: $dropdown-item-hover-background-color;
      color: $dropdown-item-hover-color;
    }

    &.is-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
</style>
