<template>
  <component :is="errorPage.component(error)" v-if="errorPage" />
  <ErrorLayout v-else title="Oops! Something went wrong.">
    <template #figure>
      <img src="@/assets/error.svg?url" alt="404">
    </template>
    <template #default>
      <p>While we work to fix this try one of the following:</p>
      <ul>
        <li><SvgIcon :svg="ArrowRightIcon" class="mr-1 has-text-primary" /> <a @click="$router.go()">Refresh</a> the page</li>
        <slot></slot>
        <li><SvgIcon :svg="ArrowRightIcon" class="mr-1 has-text-primary" /> Try again in a few minutes</li>
        <li><SvgIcon :svg="ArrowRightIcon" class="mr-1 has-text-primary" /> Call efm support on <b>1300 769 605</b></li>
      </ul>
    </template>
  </ErrorLayout>
</template>

<script>
  import { h } from 'vue';

  import { PermissionDeniedError } from '@/shared/errorHandling/errors';
  import { errorIsCausedBy } from '@/shared/errorHandling/helpers';
  import SvgIcon from '@/shared/icons/SvgIcon.vue';
  import ArrowRightIcon from '@/shared/icons/svgs/arrowRight.svg';
  import { NetworkError, NotFoundError } from '@/shared/services/api-client';

  import ErrorLayout from '@App/layout/views/ErrorLayout.vue';
  import NetworkFailure from '@App/layout/views/NetworkFailure.vue';
  import NotFound from '@App/layout/views/NotFound.vue';
  import PermissionDenied from '@App/layout/views/PermissionDenied.vue';

  const defaultErrorPages = [
    {
      check: error => error.status === 404 || error.constructor === NotFoundError,
      component: (error) => h(NotFound, { error }),
    },
    {
      check: error => error.status === 403 || error.constructor === PermissionDeniedError,
      component: (error) => h(PermissionDenied, { error }),
    },
    {
      check: error => errorIsCausedBy(error, NetworkError),
      component: (error) => h(NetworkFailure, { error }),
    },
  ];

  export default {
    name: 'ErrorFallback',
    components: {
      SvgIcon,
      ErrorLayout,
    },
    props: {
      error: { type: Object, required: false, default: () => ({}) },
      errorPages: {
        type: Array,
        validator: errorPages => !errorPages.some(errorPage => !('check' in errorPage) || !('component' in errorPage)),
        default: () => [
          // {
          // TEMPLATE for custom error pages
          // check: error => error.status === 404 || error.constructor === NotFoundError,
          // component: (error) => h(NotFound),
          // },
        ],
      },
    },
    computed: {
      ArrowRightIcon() {
        return ArrowRightIcon;
      },
      errorPage() {
        return [...this.errorPages, ...defaultErrorPages].find(({ check }) => check(this.error));
      },
    },
  };
</script>

<style lang="scss" scoped>

</style>
