interface Destination {
  postcode: string;
  locality: string;
  subdivision: string;
  countryId: string;
}

export interface NewDestination {
  postcode?: string;
  locality?: string;
  subdivision?: string;
  countryId?: string;
}

const createDestination = ({
                                    postcode,
                                    locality,
                                    subdivision,
                                    countryId,
                                  }: Destination): Destination => ({
  postcode,
  locality,
  subdivision,
  countryId,
});

const createNewDestination = ({
  postcode,
  locality,
  subdivision,
  countryId,
}: Partial<NewDestination> = {}): NewDestination => ({
  postcode,
  locality: locality || '',
  subdivision,
  countryId,
});

export const destinationFactory = {
  create: createDestination,
  createNew: createNewDestination,
};

export const isDestination = (
  destination: Destination | NewDestination,
): destination is Destination => !!destination.countryId
  && !!destination.locality
  && !!destination.postcode
  && !!destination.subdivision;

export default Destination;
