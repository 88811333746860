import {
  computed, ComputedRef, readonly, Ref, UnwrapRef,
} from 'vue';

export type CreateFeatureContext<T> = {
  [key in keyof T]: T[key] | ComputedRef<T[key]> | Ref<T[key]> | UnwrapRef<T[key]>;
};

export type FeatureContextFilterFn<FeatureContext> = (context: Readonly<FeatureContext>) => CreateFeatureContext<FeatureContext>;

export interface FeatureDefinition<FeatureContext, ConfigReturnType> {
  enabled: (context: Readonly<FeatureContext>) => boolean,
  config?: (context: Readonly<FeatureContext>) => ConfigReturnType,
}

export interface Feature<FeatureContext> {
  enabled(featureDefinition: FeatureDefinition<FeatureContext, unknown>): ComputedRef<boolean>;

  config<CR>(featureDefinition: FeatureDefinition<FeatureContext, CR>): ComputedRef<CR>;

  scope(filterContextFn: FeatureContextFilterFn<FeatureContext>): Feature<FeatureContext>;
}

function createFeatures<FeatureContext extends object>(contextFn: () => CreateFeatureContext<FeatureContext>) {
  const createFeature = <CR>(featureDefinition: FeatureDefinition<FeatureContext, CR>) => featureDefinition;

  const useFeature = (): Feature<FeatureContext> => {
    // Execute the context function where we have access to component hierarchy, etc
    const reactiveContext = readonly(contextFn()) as Readonly<FeatureContext>;

    return {
      enabled: (definition) => computed(
        () => definition.enabled(reactiveContext),
      ),
      config: (definition) => {
        if (typeof definition.config !== 'function') {
          throw new Error('Feature definition does not support config');
        }
        return computed(() => {
          // this should never happen because we checked already!
          if (typeof definition.config !== 'function') {
            throw new Error('Feature definition does not support config');
          }
          return definition.config(reactiveContext);
        });
      },
      scope: (scopedContextFn) => {
        const scopedFeatures = createFeatures(() => scopedContextFn(reactiveContext));
        return scopedFeatures.useFeature();
      },
    };
  };

  return {
    createFeature,
    useFeature,
  };
}

export default createFeatures;
