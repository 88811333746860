<template>
  <ControlWithIcon class="control-search" :class="controlWrapperClasses" :loading="loading">
    <template #icon-left>
      <slot name="icon-left">
        <SvgIcon :svg="filter ? FilterIcon : SearchIcon" size="small" />
      </slot>
    </template>
    <ControlText
      ref="inputEl"
      v-model="model"
      v-bind="$attrs"
      :has-error="hasError"
      :sensitive="sensitive"
      :data-testref="dataTestref"
      autocomplete="off"
    />
    <template #icon-right>
      <a v-if="clearable" class="clear-icon" aria-label="Clear" @click="clear">
        <SvgIcon :svg="CloseIcon" size="small" />
      </a>
      <slot v-else name="icon-right"></slot>
    </template>
  </ControlWithIcon>
</template>

<script setup lang="ts">
  import { computed } from 'vue';

  import ControlText from '@/shared/components/controls/ControlText.vue';
  import ControlWithIcon from '@/shared/components/controls/ControlWithIcon.vue';
  import { basicControl, BasicControlProps } from '@/shared/components/controls/helpers/controlBehaviour';
  import SvgIcon from '@/shared/icons/SvgIcon.vue';
  import CloseIcon from '@/shared/icons/svgs/closeCircle.svg';
  import FilterIcon from '@/shared/icons/svgs/filter.svg';
  import SearchIcon from '@/shared/icons/svgs/search.svg';

  defineOptions({
    inheritAttrs: false,
  });

  const props = defineProps<BasicControlProps & {
    loading?: boolean,
    modelValue?: string;
    controlWrapperClasses?: string | string[] | object;
    filter?: boolean,
  }>();

  const emit = defineEmits< {
    'update:modelValue': [string];
    'clear': [];
  }>();

  const { inputEl, focus, select } = basicControl(props);

  const model = computed({
    get: () => props.modelValue,
    set(value) { emit('update:modelValue', value || ''); },
  });

  const clearable = computed(() => (!props.loading && !!props.modelValue));

  const clear = () => {
    model.value = '';
    emit('clear');
    focus();
  };

  defineExpose({
    focus,
    select,
  });
</script>

<style scoped lang="scss">
  .clear-icon {
    display: block;
    text-decoration: none;
    pointer-events: all;
    :deep(svg) {
      display: block;
      pointer-events: all;
    }
  }
</style>
