<template>
  <div v-if="isCritical" class="critical-boundary">
    <slot name="fallback" :critical-error="criticalError">
      <component :is="ErrorBoundaryFallback" v-if="ErrorBoundaryFallback" :error="criticalError" />
      <div v-else class="critical-boundary">Oops! Something went wrong</div>
    </slot>
  </div>
  <div v-else>
    <slot v-if="!manualDisplay" name="errorDisplay">
      <ErrorDisplayList :errors="errors" />
    </slot>
    <slot :errors="errors"></slot>
  </div>
</template>

<script setup lang="ts">
  import uniqueId from 'lodash/uniqueId';
  import { computed, inject, onMounted, onUnmounted, watch } from 'vue';

  import { contextInjectionKeyConfig } from './config';
  import ErrorDisplayList from './ErrorDisplayList.vue';
  import errorStore from './errorStore';
  import useErrorBoundary from './useErrorBoundary';

  const errorHandlingConfig = inject(contextInjectionKeyConfig);
  const ErrorBoundaryFallback = errorHandlingConfig?.fallbackComponent;

  const props = withDefaults(defineProps<{
    manualDisplay?: boolean;
    name?: Parameters<typeof useErrorBoundary>[0]['name'];
    onErrorCaptured?: Parameters<typeof useErrorBoundary>[0]['onErrorCaptured'];
  }>(), {
    manualDisplay: false,
    name: `error:${uniqueId()}`,
    onErrorCaptured: undefined,
  });

  // eslint-disable-next-line vue/no-setup-props-destructure
  const { errorBoundary } = useErrorBoundary({
    name: props.name,
    onErrorCaptured: props.onErrorCaptured,
    useExactName: true,
  });

  const isCritical = computed(() => errorStore.errorBoundaryIsCritical(errorBoundary.name));
  const criticalError = computed(() => errorStore.getErrorBoundary(errorBoundary.name).criticalError);
  const errors = computed(() => errorStore.getErrorBoundary(errorBoundary.name).errors || []);

  errorStore.registerErrorBoundary(errorBoundary.name);

  onMounted(() => {
    if (!props.manualDisplay) {
      errorStore.registerErrorBoundaryDisplay(errorBoundary.name);
    }
  });

  onUnmounted(() => {
    errorStore.unregisterErrorBoundary(errorBoundary.name);
  });

  watch(() => props.name, () => {
    // changes to name are not reactive because the `useErrorBoundary` call initialises
    // the error boundary at setup time
    logger.warn('ErrorBoundary `name` prop was changed after initialisation');
  });
</script>
