import { createTypeHelpers } from '@App/store/store-helpers';

import { name } from '../module';


/**
 * Explicitly named getters, mutations and actions. Marked internal because
 * these will be used for definitions in the store, but most external code
 * will access these through the namespace, ie:
 *   '${MODULE_NAME}/CURRENT_ORGANISATION'
 */
const InternalTypes = {
  getters: {
    user: 'user',
    userHasAcceptedTerms: 'userHasAcceptedTerms',
    sites: 'sites',
    site: 'site',
    organisations: 'organisations',
    organisation: 'organisation',
    platformDefaults: 'platformDefaults',
    defaultSite: 'defaultSite',
  },

  mutations: {
    SET_USER: 'SET_USER',
    SET_USER_ACCEPT_TERMS: 'SET_USER_ACCEPT_TERMS',
  },

  actions: {
    fetchUser: 'fetchUser',
    getSite: 'getSite',
    acceptTerms: 'acceptTerms',
    clearOrgData: 'clearOrgData',
  },
};

/**
 * Derive namespaced versions of each constant that can be used in views and
 * components directly, ie:
 *   this.$store.dispatch(Org.fetchUser)
 *     which is the same as:
 *   this.$store.dispatch('org/fetchUser')
 */
const ModuleTypes = createTypeHelpers(name, InternalTypes);

export { ModuleTypes as default, InternalTypes };
