import DangerousGoods from '@/shared/models/DangerousGoods/DangerousGoods';
import Destination from '@/shared/models/Destination';
import { createModelWithKey, ModelWithKey } from '@/shared/models/helpers/ModelWithKey';

interface Freight extends ModelWithKey<Dimensions> {
  destination: Destination;
  description?: string;
  packagingType: string;
  shippingItemPresetId?: UUID;
  weight: number;
  quantity: number;
  requestId?: UUID;
  dangerousGoods?: DangerousGoods[];
}

export const createFreight = ({
  _key,
  destination,
  description,
  packagingType,
  width,
  height,
  length,
  weight,
  quantity,
  shippingItemPresetId,
  requestId,
  dangerousGoods,
}: (Omit<Freight, '_key'> & { _key?: string })): Freight => createModelWithKey({
  _key,
  destination,
  description,
  packagingType,
  width,
  height,
  length,
  weight,
  quantity,
  shippingItemPresetId,
  requestId,
  dangerousGoods,
});


export default Freight;
