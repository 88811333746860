import { Temporal } from '@js-temporal/polyfill';

import { Interval } from '@/shared/DateTime';
import Contact, { createContact } from '@/shared/models/Contact';
import { createLocation } from '@/shared/models/Location';

import { PickupConsignment } from '@/shared/models/Pickup/PickupConsignment';

import Addressee from '../Addressee';
import DangerousGoodsDeclaration from '../DangerousGoods/DangerousGoodsDeclaration';
import Freight from '../Freight';

import PickupRequest from './PickupRequest';
import PickupService from './PickupService';
import PickupServiceOption from './PickupServiceOption';

export enum PickupType {
  Outbound = 'outbound',
  Inbound = 'inbound',
  Transfer = 'transfer',
}

export type PickupContact = Contact & { email: string; };
export type PickupAddressee = Addressee & { contact: PickupContact };

interface FreightProfile {
  consignments: PickupConsignment[];
  // additional freight
  freight?: Freight[];
}

interface Pickup {
  // Core
  id: UUID;
  siteId: UUID;
  pickupType: PickupType;
  createdAt: Temporal.Instant;
  updatedAt: Temporal.Instant;
  requestedAt?: Temporal.Instant;
  pickupDate: Temporal.PlainDate;
  pickupWindow?: Interval;
  pickupCloseTime?: Temporal.Instant;
  pickupInstructions?: string;
  reference?: string;
  requests: PickupRequest[];
  /** @deprecated */
  freight: Freight[];
  freightProfile: FreightProfile;
  serviceOptions?: PickupServiceOption[];
  service?: PickupService;
  sender: PickupAddressee;
  dangerousGoodsDeclaration?: DangerousGoodsDeclaration;
}

const createPickupContact = (): PickupContact => ({
  ...(createContact()),
  email: '',
});

export const pickupAddresseeFactory = {
  createNew: ({
    contact = createPickupContact(),
    location = createLocation(),
    addressBookEntryId,
  }: Partial<PickupAddressee> = {}): PickupAddressee => ({
    contact,
    location,
    addressBookEntryId,
  }),
  create: ({ contact, location, addressBookEntryId }: PickupAddressee): PickupAddressee => ({
    contact,
    location,
    addressBookEntryId,
  }),
};

export default Pickup;
