const loadExternal = ({
  src,
  async = true,
  defer = false,
  priority = 'auto',
}) => new Promise((resolve, reject) => {
  const script = document.createElement('script');

  script.src = src;
  script.fetchpriority = priority;
  script.type = 'application/javascript';

  if (async) script.async = true;
  if (defer) script.defer = true;

  script.onload = resolve;

  // GEPPES-2789 - PLEASE NOTE
  // this should actually be called 'onAfterError', the error has already been caught by window at this point.
  script.onerror = reject;

  document.head.appendChild(script);
});

export default loadExternal;
