<script setup lang="ts">
  import { computed, inject } from 'vue';

  import BannerMessage, { BANNER_TYPE } from '@/shared/components/BannerMessage.vue';
  import { contextInjectionKeyDisplayHandlers } from '@/shared/errorHandling/config';

  const props = defineProps<{
    errors: Error[],
  }>();

  // errorDisplayHandlers can be configured globally in errorHandling plugin, or locally
  // in useErrorBoundary
  const errorDisplayHandlers = inject(contextInjectionKeyDisplayHandlers) || [];

  // find a display handler, which tells us how to display a certain type of error
  const displayableErrors = computed(() => props.errors.map(
    error => {
      const handlerForError = errorDisplayHandlers.find(({ check }) => check(error));
      return handlerForError ? { component: handlerForError.component(error) } : error;
    },
  ));
</script>

<template>
  <BannerMessage v-for="error in displayableErrors" :key="error" :type="BANNER_TYPE.DANGER">
    <Component :is="error.component" v-if="'component' in error" />
    <div v-else-if="error.message">{{ error.message }}</div>
  </BannerMessage>
</template>
