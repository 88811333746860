import uniqueId from 'lodash/uniqueId';

export type ModelWithKey<T> = T & {
  _key: string
};

type ModelWithKeyFactory<T> = (initialValues?: T & { _key?: string }) => ModelWithKey<T>;

export function createModelWithKey<T extends object>(model: T | ModelWithKey<T>): ModelWithKey<T> {
  return {
    ...model,
    _key: '_key' in model && model._key
      ? model._key
      : uniqueId(),
  };
}

export function modelWithKeyFactory<T extends object>(modelFactory: (initialValues?: T) => T): ModelWithKeyFactory<T> {
  return (values) => createModelWithKey<T>(modelFactory(values));
}

export function modelWithoutKey<T>(modelWithKey: ModelWithKey<T>): Omit<T, '_key'> {
  const { _key, ...modelWithKeyOmitted } = modelWithKey;
  return modelWithKeyOmitted;
}

export function withKey<T extends object>(object: T & { _key?: string }): ModelWithKey<T> {
  return { _key: object?._key || uniqueId(), ...object };
}
