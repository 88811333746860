/**
 * ManifestSummary model
 * represents a single API result when searching/listing manifests
 */

import { createOrganisation } from '@/shared/models/Organisation';

const create = ({
  id,
  created,
  consignmentType,
  manifestNumber,
  dispatchDate,
  itemQuantity,
  volume,
  weight,
  organisation,
  site,
  carrier,
  agreedServices = [],
  senderName,
  dispatchAddress = {},
  hasDangerousGoods,
} = {}) => ({
  id,
  created,
  consignmentType,
  manifestNumber,
  dispatchDate,
  itemQuantity,
  volume,
  weight,
  site,
  organisation: createOrganisation(organisation),
  carrier: createOrganisation(carrier),
  agreedServices,
  senderName,
  dispatchAddress,
  hasDangerousGoods,
});

const createFromApi = ({
  id,
  attributes,
}) => create({
  id,
  ...attributes,
});

const ManifestSummary = {
  create,
  createFromApi,
};

export default ManifestSummary;
