import { datadogRum } from '@datadog/browser-rum-slim';
import type { RumErrorEvent, RumEvent } from '@datadog/browser-rum-slim';

import AppcuesClient, { FEEDBACK_SURVEY_FLOW_ID } from '@/shared/analytics/AppcuesClient';
import LocalMonitorClient from '@/shared/analytics/LocalMonitorClient';
import MonitorClient from '@/shared/analytics/MonitorClient';
import ErrorMonitoring from '@/shared/ErrorMonitoring';
import FullStory from '@/shared/FullStory';

interface UserMonitorConfig {
  app: {
    name: string,
    environment: string,
    version: string,
    deploymentBranch: string,
  },
  appcues: {
    enabled: boolean,
    account_id: string
  },
  datadog: {
    rumAppID: string,
    rumClientToken: string,
    ignoreEventMarker?: string,
  },
  rum: {
    datadog: boolean
  },
  ignoreErrorsCausedBy: ErrorType[]
}

interface InstrumentationContext {
  duration?: number;

  [key: string]: unknown;
}

interface UserWithMetaData {
  id: string;
  sitesAvailable: number;
  orgsAvailable: number;
}

/**
 * A utility class to wrap around an analytics service library, which allows
 * recording user actions and events that occur during a user session.
 */
class UserMonitor {
  private _context: InstrumentationContext;

  private _appcues: AppcuesClient | undefined;

  private _monitor: MonitorClient | undefined;

  private _ignoredErrorTypes: ErrorType[] | undefined;

  constructor({
    app,
    appcues,
    datadog,
    rum,
    ignoreErrorsCausedBy,
  }: UserMonitorConfig) {
    this._context = {};
    this._ignoredErrorTypes = ignoreErrorsCausedBy;

    let rumClient;

    // Disable RUM collection in some environments, ie Cypress or synthetics
    if (rum.datadog) {
      rumClient = datadogRum;
      rumClient.init({
        applicationId: datadog.rumAppID,
        clientToken: datadog.rumClientToken,

        sessionSampleRate: 100, // Enable user actions and page view tracking for 100% of users
        trackUserInteractions: false, // Disable session replay recording
        trackResources: true,
        trackLongTasks: true,

        service: app.name.toLowerCase()
          .replace(/\s/g, '-'),
        env: app.environment,
        version: app.version,

        // Enable tracing on same-origin requests (to the API proxy)
        allowedTracingUrls: [window.location.origin],

        beforeSend: (event: RumEvent) => { // eslint-disable-line consistent-return
          // prevent certain errors getting reported to Datadog
          if (event.type === 'error' && this.shouldIgnoreError(event, datadog)) return false;

          // TODO? this was never implemented, current performs logic operations without value
          // if (scrubSensitiveRUMData(event, context) === false) return false;

          return true;
        },
      });
    } else {
      rumClient = new LocalMonitorClient();
    }
    rumClient.setGlobalContextProperty('deployment', app.deploymentBranch);
    this._monitor = rumClient;

    if (appcues.enabled) {
      const appcuesClient = new AppcuesClient({ enabled: appcues.enabled, accountId: appcues.account_id });
      appcuesClient.load();
      this._appcues = appcuesClient;
    }

    ErrorMonitoring.addContextOnError(() => {
      const session = FullStory.getCurrentSessionURL(true);
      if (session) {
        return {
          FullStory: { session },
        };
      }
      return {};
    });
  }

  /**
   * Check if this error should be reported to DataDog or not.
   * @param {RumEvent} event the error event to check
   * @private {boolean} if true, ignore the error
   */
  private shouldIgnoreError(event: RumErrorEvent, datadog: UserMonitorConfig['datadog']) {
    const { error } = event;
    if (!error) return false; // invalid error event

    // prevent console.errors being sent to Datadog
    if (event.error?.source === 'console' && datadog.ignoreEventMarker && event.error?.message.includes(datadog.ignoreEventMarker)) return true;

    if (this._ignoredErrorTypes?.map(t => t.name).includes(error.type as string)) {
      return true;
    }

    // 3rd party errors have a broken stack trace in Chrome, so let's ignore those.
    if (/at undefined @ $/.test(error.stack || '')) return true;

    // do not ignore other errors.
    return false;
  }

  /**
   * Record a custom user event with context specific to that action.
   * @param {string} name - the name of the user action
   * @param {object} context - a set of key/value data relating to the action
   */
  event(name: string, context: InstrumentationContext = {}) {
    if (!name) {
      throw new Error('Cannot record a user event without a name');
    }

    // Copy so we can transform some standard fields
    const contextToSend = { ...context };
    if (contextToSend.duration) {
      contextToSend.duration *= 1e6; // Convert ms to nanoseconds
    }

    if (this._monitor) {
      this._monitor.addAction(name, { ...this._context, ...contextToSend });
    }

    // TODO - spread and send `_context` to Appcues too?
    this._appcues?.track(name, context);
  }

  /**
   * Add contextual data that will be included with every action recorded with this monitor.
   * @param {object} context - a set of key/value data relating to the entire user session
   */
  addContext(context: InstrumentationContext) {
    // Fold the new context key/values but preserve non-conflicting existing context
    this._context = {
      ...this._context,
      ...context,
    };
  }

  removeContext(key: string) {
    if (typeof this._context[key] !== 'undefined') {
      delete this._context[key];
    }
  }

  /**
   * When a user authenticates, add non-PII metadata to our logging and instrumentation collection.
   * If no userId is provided, clear user metadata from all tooling.
   * @param {string|null} userId - the user's unique, non-personally identifiable id
   */
  setUser(userId: string | null) {
    if (userId) {
      this._monitor?.setGlobalContextProperty('usr.uuid', userId);

      logger.addGlobalContext('usr.uuid', userId);

      FullStory.identify(userId, { displayName: userId });

      ErrorMonitoring.setUser(userId);
    } else {
      this._monitor?.removeGlobalContextProperty('usr.uuid');

      logger.removeGlobalContext('usr.uuid');

      this._appcues?.reset();

      FullStory.anonymize();

      ErrorMonitoring.setUser();
    }
  }

  setUserWithMetaData(user: UserWithMetaData) {
    if (user.id) {
      this._appcues?.identify(user.id, {
        sitesAvailable: user.sitesAvailable,
        orgsAvailable: user.orgsAvailable,
      });
    }
  }

  /**
   * A user selecting a site is a key event, as every action they take from that point
   * will be in the context of that site, so set the user's siteId and orgId as metadata
   * in our third-party tools to help with fault identification and resolution.
   * @param {object} context
   * @param {string} context.orgId - unique id of the org the selected site belongs to
   * @param {string} context.siteId - unique id of the selected site
   * @param {object} options
   * @param {object} options.features - feature system able to determine if features are enabled
   */
  setSite({ orgId, siteId }: { orgId?: UUID, siteId?: UUID } = {}, { features }: { features?: Record<string, boolean> } = {}) {
    // send details about current features with logging and errors
    logger.addGlobalContext('app.features', features);
    this._monitor?.setGlobalContextProperty('app.features', features);
    ErrorMonitoring.addMetadata('features', features);
    this.addContext({ features });

    if (orgId || siteId) {
      logger.addGlobalContext('usr.org', orgId);
      this._monitor?.setGlobalContextProperty('usr.org', orgId);
      FullStory.addContext('orgUuid', orgId);

      if (siteId) {
        logger.addGlobalContext('usr.site', siteId);
        this._monitor?.setGlobalContextProperty('usr.site', siteId);
        this.event('site selected', { orgId, siteId });
        FullStory.addContext('siteUuid', siteId);
      } else {
        logger.removeGlobalContext('usr.site');
        this._monitor?.removeGlobalContextProperty('usr.site');
        this.event('org selected', { orgId });
        FullStory.removeContext('siteUuid');
      }

      FullStory.event('site selected', {
        orgUuid: orgId,
        siteUuid: siteId,
      });
      ErrorMonitoring.addMetadata('user', {
        orgId,
        siteId,
      });
      this._appcues?.group(siteId ? `site:${siteId}` : `org:${orgId}`, {
        features,
      });
    } else {
      logger.removeGlobalContext('usr.org');
      logger.removeGlobalContext('usr.site');
      this._monitor?.removeGlobalContextProperty('usr.org');
      this._monitor?.removeGlobalContextProperty('usr.site');
      FullStory.removeContext('siteUuid');
      FullStory.removeContext('orgUuid');
      ErrorMonitoring.clearMetadata('user');
    }
  }

  showFeedbackSurvey() {
    this._appcues?.show(FEEDBACK_SURVEY_FLOW_ID);
  }
}

export default UserMonitor;
