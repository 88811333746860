<template>
  <ProgressIndicator v-if="!hasContext" message="Loading site details" />
  <AppLayout v-else>
    <template #header-context>
      <ContextDropDown
        data-testref="app-header-company"
        title="Company"
        :options="sortedOrganisations"
        :create-link="createOrgLink"
        :selected="context.org?.id"
        sensitive
      />
      <SiteContextDropDown
        v-if="context.org"
        :options="sortedSites"
        :create-link="createSiteLink"
        :selected="context.site?.id"
        :org-id="context.org?.id"
      />
    </template>
    <template #header-navigation>
      <SiteNavigation />
    </template>

    <ErrorBoundary v-bind="errorBoundary">
      <RouterView v-if="!isRouteContextChanging" v-slot="{ Component }" :key="contextKey">
        <transition name="route-change" mode="out-in">
          <component :is="Component" />
        </transition>
      </RouterView>
    </ErrorBoundary>
  </AppLayout>
</template>

<script setup lang="ts">
  import { Temporal } from '@js-temporal/polyfill';
  import { computed, onUnmounted, ref, watch } from 'vue';
  import { onBeforeRouteUpdate, useRoute } from 'vue-router';

  import config from '@/config';
  import ProgressIndicator from '@/shared/components/ProgressIndicator.vue';
  import { ErrorBoundary, useErrorBoundary } from '@/shared/errorHandling';
  import { PermissionDeniedError } from '@/shared/errorHandling/errors';
  import { errorIsCausedBy, isUnhandleableError } from '@/shared/errorHandling/helpers';
  import instrumentation from '@/shared/instrumentation';
  import { OrganisationWithSites, Site } from '@/shared/models';
  import { CancelledError } from '@/shared/services/api-client';
  import { dynamicKeySort } from '@/shared/utils/string-utils';

  import testingHelper from '@/shared/utils/testingHelper';

  import { useFeature } from '@App/config/features';
  import createContext from '@App/context/createContext';
  import { ContextType } from '@App/context/types';
  import { useContextSource } from '@App/context/useContextSource';
  import appInstrumentationTypes from '@App/instrumentation/types';
  import AppLayout from '@App/layout/views/AppLayout.vue';
  import ContextDropDown from '@Site/components/ContextDropDown.vue';
  import SiteContextDropDown from '@Site/components/SiteContextDropDown.vue';
  import SiteNavigation from '@Site/components/SiteNavigation.vue';

  const route = useRoute();
  const feature = useFeature();

  const { errorBoundary } = useErrorBoundary({
    name: 'error:site-layout',
    onErrorCaptured({ error }, { errorIsDisplayed, markAsCritical, markAsDisplayed }) {
      if (errorIsCausedBy(error, CancelledError)) {
        markAsDisplayed(error);
      }

      if (isUnhandleableError(error) && !errorIsDisplayed(error)) {
        markAsCritical(error);
      }
    },
  });

  const isRouteContextChanging = ref(false);
  onBeforeRouteUpdate((to, from, next) => {
    errorBoundary.clearErrors();

    // when the user makes a change to the context via the route, the updated Context flows down
    // to the mounted view, which some views do not handle well (ie, create consignment). we add
    // some safety by unmounting the RouterView for the duration of the transition.
    if (to.params.contextId !== from.params.contextId) {
      isRouteContextChanging.value = true;
    }
    next();
  });

  const {
    context,
    setContext,
    hasContext,
  } = createContext();

  // protect against invalid values of context type
  const contextType = computed<ContextType | undefined>(() => ((route.params?.contextType === 'site' || route.params?.contextType === 'org')
    ? route.params.contextType
    : undefined));
  const contextId = computed(() => (Array.isArray(route.params?.contextId)
    ? route.params.contextId.at(0)
    : route.params?.contextId
  ));

  // force the RouterView to re-render when the context changes
  // some views are not completely reactive and need the view to be re-mounted
  const contextKey = computed(() => `orgId:${context.value?.org?.id || ''}|siteId:${context.value?.site?.id || ''}`);

  const { allOrganisations, contextFromTypeAndId, userCanAccessContext } = useContextSource({
    contextType,
    contextId,
  });

  setContext(contextFromTypeAndId);

  watch([contextType, contextId], async () => {
    // Ensure selected site is still present in the state, or select a new site
    if (contextType.value && !userCanAccessContext(contextType, contextId)) {
      throw new PermissionDeniedError(`Cannot access selected ${contextType.value}`);
    }
  }, { immediate: true });

  watch(context, (newContext, previousContext) => {
    if ((newContext?.org?.id === previousContext?.org?.id && newContext?.site?.id === previousContext?.site?.id)
      || !hasContext.value) {
      // no change to the selected org/site
      return;
    }

    // when the user changes to a different site / org, record it in instrumentation
    const contextFeatures = feature.scope((ftContext) => ({ ...ftContext, ...context.value }));
    instrumentation.event(appInstrumentationTypes.CONTEXT_CHANGED, {
      context: context.value,
      feature: contextFeatures,
    });

    // once we've ensured the user has access to the new Context, re-mount the RouterView
    isRouteContextChanging.value = false;
  }, { immediate: true });

  onUnmounted(() => {
    instrumentation.event(appInstrumentationTypes.CONTEXT_CHANGED, {
      context: undefined,
      feature: feature.scope(ftContext => (ftContext)),
    });
  });

  const sortedOrganisations = computed(() => {
    if (!allOrganisations.value) return [];
    return Object.values(allOrganisations.value).sort(dynamicKeySort('name'));
  });

  const sortedSites = computed(() => {
    if (!context.value?.org?.sites) return [];
    return Object.values(context.value.org.sites).sort(dynamicKeySort('name'));
  });

  // generate a router location for the same route at a different site, taking into account
  // that routes with additional params (ie resource ids) would not be allowed
  const createSiteLink = (site: Site) => {
    if (!context.value.org) throw new Error('cannot construct site link when no org is selected');

    const { name, params: { contextId: currentSiteId, contextType: currentContextType, ...otherParams } } = route;

    if (site.id) {
      // if the current route has other params, which may be resource ids, it is possibly invalid
      if (Object.keys(otherParams).length) {
        // link to the default route in this site instead
        return { name: config.defaultRoute, params: { contextType: 'site', contextId: site.id }, query: { contextSwitch: true } };
      }
      // if site id is the only param, form a link to the same location at the new site
      return { name, params: { contextType: 'site', contextId: site.id }, query: { contextSwitch: true } };
    }

    // global view
    // if the current route has other params, which may be resource ids, it is possibly invalid
    if (Object.keys(otherParams).length) {
      // link to the default route in this site instead
      return { name: config.defaultRoute, params: { contextType: 'org', contextId: context.value?.org.id } };
    }
    // if site id is the only param, form a link to the same location at the new site
    return { name, params: { contextType: 'org', contextId: context.value?.org.id } };
  };

  const createOrgLink = (org: OrganisationWithSites) => {
    const firstSite = Object.values(org.sites)
      .sort(dynamicKeySort('name'))
      .shift();

    // is there any way to encode this into the OrganisationWithSites type?
    if (!firstSite) throw new Error('organisation has no sites');

    return createSiteLink(firstSite);
  };

  watch([context], () => {
    // Set some helper data to allow synthetic & e2e tests to set dispatch date
    // based on the offset of the current site.
    // Hardcode the TZ of the Borg Cube which is used for synthetic & e2e tests
    // @see https://flip-eng.atlassian.net/browse/GEPPIE-2110
    const duration = Temporal.Duration.from({ nanoseconds: Temporal.TimeZone.from('Australia/Melbourne').getOffsetNanosecondsFor(Temporal.Now.instant()) });
    testingHelper.siteOffset = duration.total('minutes');
    const today = Temporal.Now.plainDateISO('Australia/Melbourne').toZonedDateTime(Temporal.Now.timeZoneId());
    testingHelper.siteToday = new Date(today.epochMilliseconds);
  }, { immediate: true });
</script>

<style scoped lang="scss">
  .is-right {
    flex-grow: 1;
    max-width: 486px;
    display: flex;
    justify-content: flex-end;
  }

  :deep(.navbar-settings) {
    padding-left: 20px;
    padding-right: 5px;
    display: block;

    i {
      color: $brand-100;
      font-size: 1.2rem;
    }
  }
</style>
