import { Temporal } from '@js-temporal/polyfill';

import { plainDateNow } from '@/shared/DateTime/helpers';
import FullStory from '@/shared/FullStory';
import instrumentation from '@/shared/instrumentation';

import userMonitor from '@App/instrumentation/userMonitor';

import manifestInstrumentationTypes from '@Manifest/instrumentation/types';

instrumentation.on(manifestInstrumentationTypes.MANIFEST_CREATED, ({ consignmentSummaries, manifest, formData, duration }) => {
  const consignmentIds = manifest.consignments.map(c => c.id);
  const consignments = consignmentIds.map(id => consignmentSummaries.find(c => c.id === id));
  const today = plainDateNow();
  const dispatchDate = Temporal.PlainDate.from(manifest.dispatchDate);
  const diff = today.until(dispatchDate);

  const hasDG = consignmentSummaries.some(c => c.hasDangerousGoods);

  // Collect user metrics about how consignments are created, what gets used and changed
  const manifestContext = {
    // @context.manifest.dispatch_delta_days (Measure)
    dispatch_delta_days: diff.days,
    // @context.manifest.consignment_count (Measure)
    consignment_count: consignments.length || 0,
    // @context.manifest.consignment_type (Facet)
    consignment_type: manifest.consignmentType,
    // @context.manifest.item_count (Measure)
    item_count: consignments.reduce((items, con) => items + con.itemQuantity, 0) || 0,
    // @context.manifest.has_dangerous_goods (Facet)
    has_dg: hasDG,
  };

  // if any of the consignments have pallet info, let's enhance our context.
  if (formData.consignments.some(c => c.pallets?.transferType && c.pallets?.transferType !== 'none')) {
    manifestContext.pallets = {
      // @context.manifest.pallets.carrierTransferConsignmentCount
      carrierTransferConsignmentCount: formData.consignments.filter(c => c.pallets?.transferType === 'carrier').length,
    };
    // @context.manifest.pallets.addDocketNumbers.(chep|loscam|other)
    const addDocketNumbers = formData.consignments.reduce((acc, c) => {
      Object.entries(c.pallets?.docketNumbers || {}).forEach(([lender, numbers]) => {
        acc[lender] = (acc[lender] || 0) + numbers.length;
      });
      return acc;
    }, {});
    if (Object.values(addDocketNumbers).length) manifestContext.pallets.addDocketNumbers = addDocketNumbers;
  }

  if (consignments.some(c => c.hasDangerousGoods)) {
    // @context.manifest.hasDangerousGoods (facet)
    manifestContext.hasDangerousGoods = true;
  }

  userMonitor.event('manifest created', {
    duration,
    manifest: manifestContext,
  });

  FullStory.event('manifest created', {
    consignmentType: manifest.consignmentType,
    consignmentIds: [...consignmentIds],
    hasPallets: !!manifestContext.pallets,
    hasDG,
  });
});

instrumentation.on(manifestInstrumentationTypes.CONSIGNMENTS_SELECTED, ({ selectionType }) => {
  userMonitor.event('manifest consignments selected', {
    // @context.manifest.selection_type (Facet)
    selection_type: selectionType,
  });

  FullStory.event('manifest consignments selected', {
    selectionType,
  });
});

instrumentation.on(manifestInstrumentationTypes.NO_PICKUP_FOR_MANIFEST, ({ selection }) => {
  userMonitor.event('manifest attempted without pickup', {
    // @context.selection (Facet)
    selection,
  });

  FullStory.event('manifest attempted without pickup', {
    selection,
  });
});
