/*
* focus helper for base input components
*
* requirements:
* - main input should be referenced with 'inputEl'
 */
export default {
  props: {
    startFocused: { default: false },
  },

  mounted() {
    if (!this.$refs.inputEl) {
      throw new Error('[InputFocusMixin] element with `inputEl` must exist');
    }

    if (this.startFocused !== false) this.focus();
  },

  methods: {
    /**
     * method to focus the input element
     * @public
     */
    focus() {
      if (typeof this.$refs.inputEl?.focus === 'function') this.$refs.inputEl.focus();
    },

    select() {
      if (typeof this.$refs.inputEl?.select === 'function') this.$refs.inputEl.select();
    },
  },
};
