import { DirectiveHook, MaybeRefOrGetter, ObjectDirective, toValue } from 'vue';

export interface SensitiveProps {
  sensitive?: boolean;
}

export const CREATE_SENSITIVE_PROPS = { sensitive: { type: Boolean, default: false } };

export interface SensitiveDirective extends ObjectDirective {
  beforeMount: DirectiveHook;
}

export default function createSensitiveDirective(sensitive: MaybeRefOrGetter<boolean | string>): SensitiveDirective {
  return {
    beforeMount: (el: HTMLElement, bindings: { modifiers: { always?: boolean, exclude?: boolean } }) => {
      const sensitiveFlag = toValue(sensitive);

      // sensitive can be an empty string so check for false and undefined explicitly
      const isMasked = !(sensitiveFlag === false || sensitiveFlag === undefined || sensitiveFlag === 'false') || bindings.modifiers.always;

      if (isMasked && bindings.modifiers.exclude) {
        el.dataset.sensitive = 'exclude'; // eslint-disable-line no-param-reassign
        return;
      }

      if (isMasked) {
        el.dataset.sensitive = 'mask'; // eslint-disable-line no-param-reassign
      }
    },
  };
}
