<script>
  import { h, toRefs } from 'vue';

  import markFilterMatches from './markFilterMatches';

  export default {
    props: {
      className: { type: String, default: 'hl' },
      markerStart: { type: String, default: '{{' },
      markerEnd: { type: String, default: '}}' },
      text: { required: true, type: String, default: '' },
      highlight: { type: [String, Array], default: undefined },
    },
    setup(props) {
      const {
        className,
        markerStart,
        markerEnd,
        text,
        highlight,
      } = toRefs(props);

      return () => {
        let markedText = text.value;

        /*
          highlight can optionally be a string of array of strings to match in the text
          if highlight is not passed as a prop, it is assumed that the text
          has already been marked e.g. backend search
        */
        let queries = highlight.value;
        if (highlight.value && typeof highlight.value === 'string') {
          queries = [highlight.value];
        } else if (highlight.value && !Array.isArray(highlight.value)) {
          throw new Error('highlight must be either string, or array of strings.');
        }

        if (highlight.value) {
          try {
            queries.forEach((query) => {
              markedText = markFilterMatches({ text: markedText, filter: query });
            });
          } catch {
            markedText = text.value;
          }
        }

        return h(
          'span',
          { class: 'text-highlight' },
          markedText ?
            markedText
              .split(markerStart.value)
              .map((t) => (t.includes(markerEnd.value) ?
                t.split(markerEnd.value)
                  .map((u, i) => (i === 0 ? h('mark', { class: className.value }, u) : u))
                : t))
              .flat()
            : '',
        );
      };
    },
  };
</script>
