import Contact, { createContact } from '@/shared/models/Contact';
import DeliveryInstructions from '@/shared/models/DeliveryInstructions';
import Location, { createLocation } from '@/shared/models/Location';

interface Addressee {
  location: Location;
  contact: Contact;
  deliveryInstructions?: DeliveryInstructions;
  addressBookEntryId?: string;
}

const newAddressee = (): Addressee => ({
  location: createLocation(),
  contact: createContact(),
});

export const createAddressee = ({
                                  location,
                                  contact,
                                  deliveryInstructions,
                                  addressBookEntryId,
                              }: Addressee | undefined = newAddressee()): Addressee => ({
  location,
  contact,
  deliveryInstructions,
  addressBookEntryId,
});

export default Addressee;
