<template>
  <ModalCard title="Pop-ups are blocked on this browser">
    <section class="popup-modal">
      <div>
        To enable your labels and documents to pop-up in new tabs, find and click
        this icon in your browser's address bar, then select <b>Always allow.</b>
        <img src="@/assets/popup-blocked.png" alt="pop-ups blocked">
      </div>
    </section>
    <template #footer>
      <ActionsBar class="popup-modal-actions">
        <button type="button" class="button is-primary" @click="modal.close()">
          Done
        </button>
      </ActionsBar>
    </template>
  </ModalCard>
</template>

<script setup>
  import ActionsBar from '@/shared/components/ActionsBar.vue';
  import ModalCard from '@/shared/modals/ModalCard.vue';
  import { useModal } from '@/shared/modals/modalManager';

  const modal = useModal();
</script>

<style lang="scss" scoped>
  .popup-modal {
    img {
      display: block;
      margin: 24px auto 0;
    }
  }

  .popup-modal-actions {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
  }
</style>
