import { useAsyncState, UseAsyncStateOptions } from '@vueuse/core';
import { onUnmounted, readonly, Ref } from 'vue';

export type AsyncCallbackConfig = {
  abortController: AbortController;
};
export type UseAsyncResourceCallback<ResourceCallbackParameters extends unknown[], ResourceType>
  = (config: AsyncCallbackConfig, ...args: ResourceCallbackParameters) => Promise<ResourceType>;

export type UseAsyncResourceOptions = UseAsyncStateOptions<true> & {
  abortOnUnmount?: boolean;
  allowConcurrent?: boolean;
};

export interface AsyncResource<ResourceType, ResourceCallbackParameters extends unknown[]> {
  state: Ref<ResourceType | undefined>,
  isLoading: Readonly<Ref<boolean>>,
  isReady: Readonly<Ref<boolean>>,
  fetch: (...args: ResourceCallbackParameters) => Promise<ResourceType | undefined>,
  reset: () => void,
  abort: () => void,
}

export default function useAsyncResource<ResourceType, ResourceCallbackParameters extends unknown[]>(
  callback: UseAsyncResourceCallback<ResourceCallbackParameters, ResourceType>,
  initialData: ResourceType | undefined = undefined,
  options: UseAsyncResourceOptions = {},
  ): AsyncResource<ResourceType, ResourceCallbackParameters> {
  const {
    abortOnUnmount = true,
    allowConcurrent = false,
    ...useAsyncStateOptions
  } = options;

  const {
    state,
    isLoading,
    isReady,
    execute,
  } = useAsyncState<ResourceType | undefined, Parameters<typeof callback>>(
    callback,
    initialData,
    {
      immediate: false,
      throwError: true,
      ...useAsyncStateOptions,
    },
  );

  const reset = () => {
    state.value = initialData;
  };

  const abortControllers: AbortController[] = [];
  const abort = () => {
    // abort all requests while emptying the array of abortControllers
    while (abortControllers.length > 0) {
      abortControllers.shift()?.abort();
    }
  };

  const fetch = async (...args: ResourceCallbackParameters) => {
  // const fetch = async (...args: Parameters<typeof callback>) => {
    if (!allowConcurrent) {
      abort();
    }

    const abortController = new AbortController();
    abortControllers.push(abortController);

    const callbackOptions: AsyncCallbackConfig = { abortController };
    return execute(options.delay, callbackOptions, ...args);
  };

  if (abortOnUnmount) {
    onUnmounted(() => {
      abort();
    });
  }

  return {
    state,
    isLoading: readonly(isLoading),
    isReady: readonly(isReady),
    fetch,
    reset,
    abort,
  };
}
