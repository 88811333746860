<template>
  <header class="app-header">
    <div class="container is-fluid">
      <router-link class="logo" to="/">
        <img src="@/assets/logo.svg?url" alt="Home">
      </router-link>
      <div class="navbars">
        <nav class="context" role="navigation" aria-label="context selectors">
          <slot name="header-context"></slot>
          <UserContextHeader v-if="user" />
          <router-link
            v-else
            class="button is-tertiary login-button"
            :to="{ name: 'auth.login' }"
          >
            Login
          </router-link>
        </nav>
        <nav class="main-nav" role="navigation" aria-label="main navigation">
          <slot name="header-navigation"></slot>
        </nav>
      </div>
    </div>
  </header>
</template>

<script setup>
  import { computed } from 'vue';
  import { useStore } from 'vuex';

  import UserContextHeader from '@App/layout/components/UserContextHeader.vue';
  import Org from '@Org/store/types';

  const store = useStore();
  const user = computed(() => store.getters[Org.getters.user]);
</script>

<style lang="scss" scoped>
  .app-header {
    @include material-thin;
    background: #fff;
    padding: 16px 0;
  }

  .container {
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
  }

  .logo {
    flex-shrink: 0;
    flex-grow: 0;
    display: block;
    margin: 8px 40px 8px 0;

    img {
      width: 80px;
      height: 80px;
      display: block;
    }
  }

  .navbars {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .context {
    display: flex;
    gap: 12px;
  }

  .main-nav {
    display: flex;
    align-items: center;
    gap: 8px;

    > :deep(*) {
      margin-top: 16px;
    }

    > :deep(.is-right) {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
  }

  .login-button {
    margin-left: auto;
  }

</style>
