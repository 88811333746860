import merge from 'lodash/merge';

// APIs often return only fields with matches in the highlight object
export type ModelWithPartialHighlight<T> = T & {
  meta?: {
    highlight?: Partial<T> | undefined;
  };
};

// we want our models to have a full model in highlight to make templates simpler
export type ModelWithHighlight<T> = T & {
  meta: {
    highlight: T;
  }
};

export function modelWithHighlightFactory<T>(modelFactory: (initialValues: T) => T):
  (initialValues: ModelWithPartialHighlight<T>) => ModelWithHighlight<T> {
  return (values) => {
    const { meta = { highlight: undefined }, ...modelValues } = values;

    // construct a full version of the model, with highlighted versions of fields taking precedence
    // this makes it easier to use the highlights, as we don't need to do:
    // model.highlights.someProp || model.someProp everywhere.
    const fullHighlight = merge<Partial<T>, T, Partial<T>>({}, modelValues as T, meta.highlight || {});

    return {
      ...modelFactory(modelValues as T),
      meta: {
        highlight: modelFactory(fullHighlight),
      },
    };
  };
}
