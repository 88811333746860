import flow from 'lodash/flow';
import fMapKeys from 'lodash/fp/mapKeys';
import fPickBy from 'lodash/fp/pickBy';

interface Highlight {
  start?: string;
  end?: string;
}

export interface FormatSearchParamsArgs {
  limit?: number,
  offset?: number,
  sort?: string;
  filters?: Record<string, unknown>;
  search?: string | null;
  highlight?: Highlight | null;

  exportFormat?: string | null;
}

interface SearchParamsBase {
  'page[offset]'?: number;
  'page[limit]'?: number;
  sort?: string;
  q?: string;
}

// for filters & highlight
type SearchParamsLoose = Record<string, string>;

export type SearchParams = SearchParamsBase & SearchParamsLoose;

export const formatSearchParams = ({
  limit,
  offset,
  sort,
  filters,
  search = null,
  highlight = null,
}: FormatSearchParamsArgs) :SearchParams => {
  const formatFilters = flow([fPickBy(value => typeof value === 'boolean' || !!value), fMapKeys(key => `filter[${key}]`)]);
  const formatHighlight = flow([fPickBy(value => !!value), fMapKeys(key => `highlight[${key}]`)]);

  let params = {};
  if (offset || limit !== undefined) {
    params = {
      ...params,
      'page[offset]': offset || 0,
      'page[limit]': limit ?? 20,
    };
  }
  if (sort) params = { ...params, sort };
  if (filters) params = { ...params, ...formatFilters(filters) };
  if (search) params = { ...params, q: search.trim() };
  if (highlight) params = { ...params, ...formatHighlight(highlight) };

  return params;
};

interface BaseOperationResponse {
  parameters: {
    query?: {
      [name: string]: unknown;
    };
    header?: never;
    path?: never;
    cookie?: never;
  };
  responses: {
    [statusCode: number]: {
      headers: {
        [name: string]: unknown;
      };
      content?: {
        [mediaType: string]: unknown;
      };
    };
  };
}

// eslint-disable-next-line vue/max-len
type SuccessResponses = 200 | 201 | 202 | 203 | 204 | 205 | 206 | 207 | 208 | 209 | 210 | 211 | 212 | 213 | 214 | 215 | 216 | 217 | 218 | 219 | 220 | 221 | 222 | 223 | 224 | 225 | 226 | 227 | 228 | 229 | 230 | 231 | 232 | 233 | 234 | 235 | 236 | 237 | 238 | 239 | 240 | 241 | 242 | 243 | 244 | 245 | 246 | 247 | 248 | 249 | 250 | 251 | 252 | 253 | 254 | 255 | 256 | 257 | 258 | 259 | 260 | 261 | 262 | 263 | 264 | 265 | 266 | 267 | 268 | 269 | 270 | 271 | 272 | 273 | 274 | 275 | 276 | 277 | 278 | 279 | 280 | 281 | 282 | 283 | 284 | 285 | 286 | 287 | 288 | 289 | 290 | 291 | 292 | 293 | 294 | 295 | 296 | 297 | 298 | 299;

/**
 * Type helper to extract 200 JSON responses from operations
 */
export type OperationResponse<T extends BaseOperationResponse> = {
  [K in keyof T['responses']]: K extends SuccessResponses ? (
    T['responses'][K] extends { content: { 'application/json': infer U } } ? U : undefined
    ) : never;
}[keyof T['responses']];


/**
 * Type helper to extract operation's query filters
 */
export type ExtractFilterKeys<T extends BaseOperationResponse> = {
  [K in keyof NonNullable<T['parameters']['query']> as K extends `filter[${infer Param}]` ? Param : never]?: NonNullable<T['parameters']['query']>[K]
};
