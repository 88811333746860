import { h } from 'vue';
import { Router, RouterLink, useRouter } from 'vue-router';

import { type ErrorHandlingConfig } from '@/shared/errorHandling/config';
import { AppError, PermissionDeniedError as PermissionDeniedAppError } from '@/shared/errorHandling/errors';
import { errorIsCausedBy, errorIsCausedByAnyOf } from '@/shared/errorHandling/helpers';
import { PollyTimeoutError } from '@/shared/polly';
import {
  ApiClientError,
  NotAuthenticatedError,
  NotFoundError,
  PermissionDeniedError,
  PreconditionFailedClientError,
} from '@/shared/services/api-client';
import NetworkError from '@/shared/services/api-client/errors/NetworkError';
import { AuthFeedbackError } from '@/shared/services/auth/errors';
import SiteIdLimitError from '@/shared/services/sender/errors/SiteIdLimitError';

import ErrorFallback from '@App/ErrorFallback.vue';
import ResourceError from '@App/errors/ResourceError';

const reloadCallback = (router?: Router) => () => (router ? router.go(0) : document.location.reload());

export const appErrorHandlingConfig: ErrorHandlingConfig = {
  fallbackComponent: ErrorFallback,
  errorDisplayHandlers: [ // first match will be displayed
    {
      check: error => errorIsCausedBy(error, NotAuthenticatedError),
      component: () => [
        'You need to log in to access this resource. ',
        h(RouterLink, { to: { name: 'auth.login' } }, () => 'Log in'),
        '.',
      ],
    },
    {
      check: error => errorIsCausedByAnyOf(error, [PermissionDeniedAppError, PermissionDeniedError]),
      component: () => h('p', 'You do not have permission to access to the requested resource.'),
    },
    {
      check: error => errorIsCausedBy(error, NetworkError),
      component: () => h('p', 'Unable to connect. Check your internet connection and try again.'),
    },
    {
      check: error => error instanceof ResourceError,
      component: error => ({
        setup() {
          const router = useRouter();
          return () => h('p', [
            error.message,
            ' Re-enter the missing details or ',
            h('a', { onClick: reloadCallback(router) }, 'try refreshing the page'),
            '.',
          ]);
        },
      }),
    },
    {
      check: error => error instanceof NotFoundError,
      component: () => ({
        setup() {
          const router = useRouter();
          return () => h('p', [
            'The requested resource was not found. ',
            h('a', { onClick: () => (router ? router.go(-1) : window.history.go(-1)) }, 'Go back'),
          ]);
        },
      }),
    },
    {
      check: error => error instanceof PreconditionFailedClientError,
      component: () => ({
        setup() {
          const router = useRouter();
          return () => h('p', [
            'There was a problem with the request. Please ',
            h('a', { onClick: reloadCallback(router) }, 'reload'),
            ' or try again.',
          ]);
        },
      }),
    },
    {
      check: error => error instanceof PollyTimeoutError,
      component: () => ({
        setup() {
          const router = useRouter();
          return () => h('p', [
            'This task is taking longer than expected. ',
            h('a', { onClick: reloadCallback(router) }, 'Try refreshing the page'),
          ]);
        },
      }),
    },
    {
      check: error => error instanceof SiteIdLimitError,
      component: error => ({
        setup() {
          return () => [
            h('p', `Sites selected exceeds maximum${error instanceof SiteIdLimitError ? ` of ${error.maximum}` : ''}.`),
            h('p', 'Modify your selection to see different results.'),
          ];
        },
      }),
    },
    {
      check: error => error instanceof ApiClientError,
      component: () => ({
        setup() {
          const router = useRouter();
          return () => h('p', [
            'There was an error. ',
            h('a', { onClick: reloadCallback(router) }, 'Try refreshing the page'),
          ]);
        },
      }),
    },
    {
      check: error => error instanceof AuthFeedbackError && !!error.message,
      component: error => ({
        setup() {
          // AuthFeedbackError is used to displayed messages to the user
          return () => h('p', error.message);
        },
      }),
    },
    {
      check: error => error instanceof AppError,
      component: error => ({
        setup() {
          // AppError instances should have user-friendly messages
          return () => h('p', error.message || 'Something went wrong.');
        },
      }),
    },
  ],
};
