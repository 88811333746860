export default class InvalidConsignmentSiteError extends Error {
  // eslint-disable-next-line default-param-last
  constructor(message = 'Invalid consignment for this site.', consignment, options) {
    super(message, options);

    this.consignment = consignment;

    // Maintains proper stack trace for where our error was thrown
    // Only works in v8 (node & Chrome)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}
