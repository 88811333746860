import { createApp, h } from 'vue';

/**
 * Accepts the same args as h() but returns HTML instead of vnodes.
 *
 * Warning: the performance of this function is probably quite bad. It should only be used
 * to create markup at a snapshot in time, and not with reactivity in mind.
 */
export default function h2html(...args: Parameters<typeof h>): string {
  const fragment = new DocumentFragment();
  const app = createApp(() => h(...args));
  app.mount((fragment as unknown) as Element);
  const markup = [...fragment.children].map(e => e.outerHTML).join('\n');
  app.unmount(); // clean up app references in devtools
  return markup;
}
