/**
 * AuthStore - action methods
 * @see https://vuex.vuejs.org/guide/actions.html
 */


import AuthService from '@/shared/services/auth';
import {
  AuthError,
  MFAVerificationRequiredError,
  PasswordResetRequiredError,
  PasswordSetupRequiredError,
  SigninInProgressError,
  UserAlreadyAuthenticatedError,
} from '@/shared/services/auth/errors';

import { InternalTypes as Auth } from './types';


const AuthActions = {
  [Auth.actions.login]: async ({ commit }, credentials) => {
    let user;
    try {
      commit(Auth.mutations.CLEAR_RESET_PASSWORD_USERNAME);

      user = await AuthService.signIn(credentials.username, credentials.password);

      if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        logger.debug('MFA VERIFICATION REQUIRED', { user });
        commit(Auth.mutations.SET_MFA_VERIFICATION_REQUIRED, true);
      }

      // GEPPES-3332 prevent setting the user before the force reload on login
      // setting the user causes reactivity to resolve user data from org graphql
      // these network requests as subsequently aborted by the force reload
      // commit(Auth.mutations.SET_USER, user);

      return user;
    } catch (error) {
      if (error instanceof UserAlreadyAuthenticatedError) {
        logger.debug('USER ALREADY LOGGED IN(?)');
        return {};
      }

      if (error instanceof MFAVerificationRequiredError) {
        commit(Auth.mutations.SET_MFA_VERIFICATION_REQUIRED, true);
        return {};
      }
      if (error instanceof PasswordSetupRequiredError) {
        logger.debug('NEW_PASSWORD_REQUIRED');
        commit(Auth.mutations.SET_UNCONFIRMED_USER, {});
        return {};
      }
      if (error instanceof SigninInProgressError) {
        return {};
      }
      if (error instanceof PasswordResetRequiredError) {
        commit(Auth.mutations.SET_RESET_PASSWORD_USERNAME, credentials.username);
        return {};
      }
      commit(Auth.mutations.CLEAR_USER);
      throw error;
    }
  },

  [Auth.actions.logout]: async ({ dispatch }) => {
    try {
      await AuthService.signOut({ global: true });
    } finally {
      // clean up of any remaining authorization state
      await dispatch(Auth.actions.clearAll);
    }
  },

  [Auth.actions.verifyMFATOTP]: async ({ commit }, token) => {
    try {
      const user = await AuthService.verifyMFATOTP({ token });

      commit(Auth.mutations.SET_USER, user);
      commit(Auth.mutations.SET_MFA_VERIFICATION_REQUIRED, false);
    } catch (error) {
      logger.debug('Error during MFA verification', error);

      throw error;
    }
  },

  [Auth.actions.completeNewPassword]: async ({ commit, dispatch, getters }, { newPassword }) => {
    if (!getters[Auth.getters.isUserMissingConfirmation]) {
      throw new Error('User does not require confirmation');
    }

    // Vuex complains when Cognito modifies the user object
    const unconfirmedUser = getters[Auth.getters.getUnconfirmedUser];
    commit(Auth.mutations.CLEAR_UNCONFIRMED_USER);

    try {
      await AuthService.completeNewPassword(newPassword);
    } catch (error) {
      // If it fails, we replace uncomfirmed user back in the state
      commit(Auth.mutations.SET_UNCONFIRMED_USER, unconfirmedUser);

      logger.error('Could not complete password setup', { error });
      throw error;
    }

    // Session should now be available to populate the user state
    await dispatch(Auth.actions.confirmSession);
  },

  [Auth.actions.forgotPassword]: async ({ commit }, { username }) => {
    try {
      const {
        attributeName,
        destination,
      } = await AuthService.forgotPassword({ username });

      commit(Auth.mutations.SET_VERIFICATION_CODE_DETAILS, {
        destination,
        attributeName,
      });
    } catch (e) {
      // do not allow username fishing
      if (['UserNotFoundException', 'NotAuthorizedException'].includes(e.code)) {
        commit(Auth.mutations.SET_VERIFICATION_CODE_DETAILS, {
          destination: 'invalid',
          attributeName: 'invalid',
        });
      } else {
        throw new AuthError(e.message, { cause: e });
      }
    }
  },

  [Auth.actions.forgotPasswordComplete]: async ({ commit }, { username, code, password }) => {
    await AuthService.forgotPasswordComplete({ username, code, password });
    commit(Auth.mutations.FORGOT_PASSWORD_COMPLETE);
  },

  [Auth.actions.resetForgotPassword]: async ({ commit }) => {
    commit(Auth.mutations.RESET_FORGOT_PASSWORD);
    commit(Auth.mutations.CLEAR_RESET_PASSWORD_USERNAME);
  },

  [Auth.actions.confirmSession]: async ({ commit }) => {
    try {
      // currentSession will return session token details or nothing, as of Amplify@6 cognito may throw
      if (await AuthService.currentSession()) { return true; }
    } catch (error) {
      commit(Auth.mutations.CLEAR_USER);
    }
    return false;
  },

  [Auth.actions.checkExistingSession]: async ({ commit }) => {
    try {
      const user = await AuthService.getCurrentUser();
      commit(Auth.mutations.SET_USER, user);
    } catch (error) {
      commit(Auth.mutations.CLEAR_USER);
    }
  },

  [Auth.actions.saveRedirect]: ({ commit }, redirectPath) => {
    commit(Auth.mutations.SET_REDIRECT, redirectPath);
  },

  [Auth.actions.clearRedirect]: ({ commit }) => {
    commit(Auth.mutations.CLEAR_REDIRECT);
  },

  [Auth.actions.clearAll]: ({ commit }) => {
    commit(Auth.mutations.CLEAR_USER);
    commit(Auth.mutations.CLEAR_REDIRECT);
    commit(Auth.mutations.CLEAR_UNCONFIRMED_USER);
  },
};

export default AuthActions;
