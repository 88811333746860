import { Temporal } from '@js-temporal/polyfill';

import { temporalInstantFromDate, temporalPlainDateFromDate, temporalPlainTimeFromDate } from '@/shared/DateTime/mappers';

export type DateLike = Temporal.PlainDate | Temporal.PlainDateTime | Temporal.Instant | Temporal.ZonedDateTime;
export type PlainDateLike = Temporal.PlainDateLike;
export type AnyDateType = Date | string | Temporal.Instant | Temporal.PlainDate | Temporal.PlainDateTime | Temporal.ZonedDateTime | null | undefined;

export function plainDateNow() {
  // GEPPES-2882 timeZone not set in Chrome on macOS 14
  // causes Temporal.Now.plainDateISO() to throw
  if (!Intl.DateTimeFormat().resolvedOptions().timeZone) {
    return temporalPlainDateFromDate(new Date());
  }

  return Temporal.Now.plainDateISO();
}

export function plainDateTimeNow(timeZone?: Temporal.TimeZoneLike) {
  // GEPPES-2882
  if (!Intl.DateTimeFormat().resolvedOptions().timeZone) {
    return temporalPlainTimeFromDate(new Date(), timeZone);
  }
  return Temporal.Now.plainDateTimeISO(timeZone);
}

// e.g. 2024-02-03,2024-02-05
export function isValidISODateRange(range?: string) {
  if (!range) return false;

  const match = /^(\d{4}-\d{2}-\d{2}),(\d{4}-\d{2}-\d{2})$/.exec(range);
  if (!match) return false;

  // ensure start date is < end date
  const startDate = Temporal.PlainDate.from(match[1]);
  const endDate = Temporal.PlainDate.from(match[2]);
  if (Temporal.PlainDate.compare(startDate, endDate) !== -1) return false;

  return true;
}

export function isPastDate(date: string) {
  return Temporal.PlainDate.from(date).since(plainDateNow()).days < 0;
}

export function timeSinceNow(instant: Temporal.Instant | string) {
  return Temporal.Instant.from(instant).since(Temporal.Now.instant());
}

export function temporalFromAnything(anything: AnyDateType) {
  if (!anything) throw new Error(`Could not convert input to a Temporal type: ${anything}`);

  // any Temporal can be used unmodified
  if ([Temporal.Instant, Temporal.PlainDate, Temporal.PlainDateTime, Temporal.ZonedDateTime].some(type => anything instanceof type)) return anything;

  if (anything instanceof Date) return temporalInstantFromDate(anything);

  if (typeof anything === 'string') {
    // Use Temporal constructors to determine the most accurate representation based on
    // the provided string, instead of implementing the check manually with heinous regex
    // 2050-12-24T13:45:67Z
    // 2050-12-24T13:45:67+10:00
    // 2050-12-24T13:45:67.12345-09:00
    try {
      return Temporal.Instant.from(anything);
    } catch { /* empty */ }
    try { // 2050-12-24T13:45:67
      return Temporal.PlainDateTime.from(anything);
    } catch { /* empty */ }
    try { // 2050-12-24
      return Temporal.PlainDate.from(anything);
    } catch { /* empty */ }
  }

  throw new Error(`Could not convert input to a Temporal type: ${anything}`);
}
