import ConsignmentToBeManifested from '@/shared/models/ConsignmentToBeManifested';
import MappingError from '@/shared/services/errors/MappingError';
import { type components } from '@/shared/services/schema/manifest.schema';

type ManifestPayload = components['schemas']['ManifestPayload']['data']['attributes'];

export const mapConsignmentsToBeManifested =
  (consignments: ConsignmentToBeManifested[]): ManifestPayload => {
    const { siteId } = consignments[0];
    return {
      siteId,
      consignments: consignments.map((consignment) => {
        if (consignment.siteId !== siteId) {
          throw new Error(`Consignment ${consignment.id} has site id of ${consignment.siteId} but expected ${siteId}`);
        }

        if (consignment.pallets
          && consignment.pallets.transferType !== 'carrier'
          && consignment.pallets.transferType !== 'receiver'
          && consignment.pallets.transferType !== 'unknown') {
          throw new MappingError('Manifest transferType can only be carrier, receiver or unknown');
        }

        return {
          consignmentId: consignment.id,
          pallets: consignment.pallets ? {
            transferType: consignment.pallets.transferType,
            docketNumbers: consignment.pallets.docketNumbers,
          } : undefined,
        };
      }),
    };
  };
