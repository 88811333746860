export type CONSIGNMENT_ENQUIRY_TYPE = 'Request for POD'
  | 'Overdue delivery'
  | 'Request for delivery ETA'
  | 'Partial delivery'
  | 'Redirect delivery'
  | 'Return to Sender'
  | 'Urgent request to carrier for freight movement'
  | 'Redelivery'
  | 'Damaged Freight'
  | 'No scans available'
  | 'Other...';

export const CONSIGNMENT_ENQUIRY_TYPES: Record<string, CONSIGNMENT_ENQUIRY_TYPE> = {
  'POD Request': 'Request for POD',
  Overdue: 'Overdue delivery',
  'Delivery ETA': 'Request for delivery ETA',
  'Partial Delivery': 'Partial delivery',
  Redirection: 'Redirect delivery',
  'Return to Sender': 'Return to Sender',
  'Carrier Pre-Alert': 'Urgent request to carrier for freight movement',
  Redelivery: 'Redelivery',
  'Damaged Freight': 'Damaged Freight',
  'Nil Scan': 'No scans available',
  other: 'Other...',
};

export default interface ConsignmentEnquiry {
  id: UUID;
  type: CONSIGNMENT_ENQUIRY_TYPE,
  reason: string,
  user?: { id: UUID, email: string, fullName: string };
  subject: string;
  description: string;
  consignment: { id: UUID; link: string; };
  createdAt: RFC3339InstantString;
}

const create = ({
  id,
  type,
  reason,
  user,
  subject,
  description,
  consignment,
  createdAt,
}: ConsignmentEnquiry): ConsignmentEnquiry => ({
  id,
  type,
  reason,
  user: user ? { id: user.id, fullName: user.fullName, email: user.email } : undefined,
  subject,
  description,
  consignment,
  createdAt,
});

const createFromApi = ({
  id,
  attributes,
}: {id: UUID, attributes: Omit<ConsignmentEnquiry, 'id'>}) => create({
  id,
  ...attributes,
});

export const consignmentEnquiryFactory = {
  create,
  createFromApi,
};
