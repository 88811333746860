import APIClient from '@/shared/services/api-client';

import OrgClient from './OrgClient';
import ShippingItemPresetsClient from './ShippingItemPresetsClient';

if (!import.meta.env.GEPPETTO_SERVICE_ORG_URL) {
  throw new Error('.env not correctly configured');
}

const baseUrl = import.meta.env.GEPPETTO_SERVICE_ORG_URL;

const apiClient = new APIClient(baseUrl);
const orgService = new OrgClient(apiClient);
const shippingItemPresetService = new ShippingItemPresetsClient(apiClient);

export {
  orgService as default,
  shippingItemPresetService,
};
