import FullStory from '@/shared/FullStory';
import instrumentation from '@/shared/instrumentation';

import userMonitor from '@App/instrumentation/userMonitor';

import { getConsignmentStatus } from '../../Consignment/helpers';

import globalSearchInstrumentationTypes from './types';

instrumentation.on(globalSearchInstrumentationTypes.CONSIGNMENT_OPENED, ({
                                                                           consignmentSummary,
                                                                           isRecentlyViewed = false,
                                                                           searchTerm = '',
                                                                         }) => {
  const consignmentStatus = getConsignmentStatus({ consignment: consignmentSummary }).label;

  let searchType = 'other';
  if (isRecentlyViewed) {
    searchType = 'recent';
  } else if (consignmentSummary.consignmentNo === searchTerm) {
    searchType = 'consignmentNoExact';
  } else if (consignmentSummary.consignmentNo.includes(searchTerm)) {
    searchType = 'consignmentNo';
  }

  userMonitor.event('consignment opened from search', {
    consignment: {
      // @context.consignment.id
      id: consignmentSummary.id,
      // @context.consignment.status
      status: consignmentStatus,
    },
    globalSearch: {
      // @context.globalSearch.searchType
      searchType,
    },
  });

  FullStory.event('consignment opened from search', {
    consignmentId: consignmentSummary.id,
    consignmentStatus,
    searchType,
  });
});
