const pageTitle = {
  install(app) {
    app.mixin({
      mounted() {
        if (this.$options.pageTitle) {
          if (typeof this.$options.pageTitle === 'function') {
            this.$watch(this.$options.pageTitle, (val) => {
              document.title = val;
            });
          } else {
            document.title = this.$options.pageTitle;
          }
        }
      },
    });
  },
};

export default pageTitle;
