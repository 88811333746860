import capitalize from 'lodash/capitalize';

export default {
  props: {
    validateOn: { type: Array, default: () => ['blur', 'input'] },
  },

  computed: {
    safeInputListeners() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;

      const validationListeners = this.validateOn.reduce((acc, eventName) => {
        const safeEventName = `on${capitalize(eventName)}`;

        const validation = () => {
          if (vm.validateOn.includes(eventName)) {
            vm.touch();
          }
        };

        return { [safeEventName]: validation, ...acc };
      }, {});

      return {
        ...validationListeners,
      };
    },
  },

  methods: {
    $inputEmit(event, ...args) {
      this.$emit(event, ...args);

      if (this.validateOn.includes(event)) this.touch();
    },

    inputListeners(customListeners = {}) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;

      const validationListeners = this.validateOn.reduce((acc, eventName) => {
        const validation = (event) => {
          if (Object.keys(customListeners).includes(eventName)) {
            customListeners[eventName](event);
          }
          if (vm.validateOn.includes(eventName)) {
            vm.touch();
          }
        };

        return { [eventName]: validation, ...acc };
      }, {});

      return {
        ...customListeners,
        ...validationListeners,
      };
    },
  },
};
