import uniq from 'lodash/uniq';
import { ValidationRuleObject } from 'veritas';

export interface ServerValidation {
  code?: string;
  detail?: string;
  title?: string
  source: { pointer: string }
}

export interface ServerError {
  code?: string,
  errorMessage: string;
  pointer: string;
}

export type ServerErrors = Record<string, ServerError>;

export type ServerErrorRule = (subPointer: string | RegExp) => ValidationRuleObject;

const createServerFailedRule = (
  locateServerValidationError: (sp: string | RegExp) => ServerError | ServerError[] | null,
  oldValueCache: Record<string, unknown> = {},
): ServerErrorRule => (subPointer: string | RegExp) => ({
  $params: { type: 'serverFailed', value: subPointer },
  $validator: (value: unknown) => {
    const serverError = locateServerValidationError(subPointer);
    const isValid = Array.isArray(serverError) ? !serverError.length : !serverError;

    if (isValid) {
      oldValueCache[subPointer.toString()] = undefined; // eslint-disable-line no-param-reassign
      return true;
    }

    const serializedValue = JSON.stringify(value);

    if (oldValueCache[subPointer.toString()] === undefined) {
      oldValueCache[subPointer.toString()] = serializedValue; // eslint-disable-line no-param-reassign
    }

    return serializedValue !== oldValueCache[subPointer.toString()];
  },
  $message: ({ $params }: { $params: { type: 'serverFailed', value: string }}) => {
    const serverError = locateServerValidationError($params.value);
    if (!serverError) return 'server error';
    if (Array.isArray(serverError)) {
      if (!serverError.length) return 'server error';
      // filter duplicates and return a comma separated list
      return uniq(serverError.map(e => e.errorMessage)).join(', ');
    }
    return serverError.errorMessage;
  },
});

export default createServerFailedRule;
