import { OneOf } from '@/shared/types/utility';

import Address from '../Address';
import Locality, { localityFactory, LocalityWithTimeZone } from '../Locality';

export interface EstimateContact {
  name?: string;
  phone?: string;
  email?: string;
}

export interface EstimateAddressee {
  name?: string;
  address: Address | LocalityWithTimeZone;
  contact?: EstimateContact;
  residential?: boolean;
  addressBookContactId?: UUID;
  addressBookEntryId?: UUID;
}

export type EstimateSender = EstimateAddressee;

export type EstimateReceiver = EstimateAddressee & {
  authorityToLeave?: boolean;
  deliveryTimeslotRequired?: boolean;
};

function createEstimateSender({
                                address,
                                addressBookContactId,
                                addressBookEntryId,
                                contact,
                                name,
                                residential,
                }: EstimateSender): EstimateSender {
  return {
    address,
    addressBookContactId,
    addressBookEntryId,
    contact,
    name,
    residential,
  };
}

function createEstimateReceiver({
                                  address,
                                  addressBookContactId,
                                  addressBookEntryId,
                                  authorityToLeave,
                                  contact,
                                  deliveryTimeslotRequired,
                                  name,
                                  residential,
                              }: EstimateReceiver): EstimateReceiver {
  return {
    address,
    addressBookContactId,
    addressBookEntryId,
    authorityToLeave,
    contact,
    deliveryTimeslotRequired,
    name,
    residential,
  };
}

export interface NewEstimateLocationAddress {
  id: UUID | undefined;
  line2?: string;
}

// An estimate location can have a full address or a locality, but never both
export type NewEstimateLocation = Partial<OneOf<{
  address: NewEstimateLocationAddress;
  locality: Locality;
}>>;

function createNewEstimateLocation({ address, locality }: NewEstimateLocation = {
  locality: localityFactory.createNew(),
}): NewEstimateLocation {
  if (address && locality) {
    throw new Error('EstimateLocation cannot have an address and a locality');
  } else if (!address && !locality) {
    throw new Error('EstimateLocation must have an address or a locality');
  }
  if (address) {
    return { address };
  }
  return { locality };
}

export interface NewEstimateAddressee {
  name?: string;
  location: NewEstimateLocation;
  contact: EstimateContact;
  residential?: boolean;
  addressBookContactId?: UUID;
  addressBookEntryId?: UUID;
}

export type NewEstimateSender = NewEstimateAddressee;

export type NewEstimateReceiver = NewEstimateAddressee & {
  authorityToLeave?: boolean;
  deliveryTimeslotRequired?: boolean;
};

function createNewEstimateSender({
                                   addressBookContactId,
                                   addressBookEntryId,
                                   contact = {},
                                   location = createNewEstimateLocation(),
                                   name,
                                   residential,
                                 }: Partial<NewEstimateSender> = {}): NewEstimateSender {
  return {
    addressBookContactId,
    addressBookEntryId,
    contact,
    location: createNewEstimateLocation(location),
    name,
    residential,
  };
}

function createNewEstimateReceiver({
                                     addressBookContactId,
                                     addressBookEntryId,
                                     authorityToLeave,
                                     contact = {},
                                     deliveryTimeslotRequired,
                                     location = createNewEstimateLocation(),
                                     name,
                                     residential,
                                   }: Partial<NewEstimateReceiver> = {}): NewEstimateReceiver {
  return {
    addressBookContactId,
    addressBookEntryId,
    authorityToLeave,
    contact,
    deliveryTimeslotRequired,
    location: createNewEstimateLocation(location),
    name,
    residential,
  };
}

export const estimateAddresseeFactory = {
  createEstimateSender,
  createEstimateReceiver,
  createNewEstimateSender,
  createNewEstimateReceiver,
};
