import { Temporal } from '@js-temporal/polyfill';

import { PickupServiceOption } from '@/shared/models';
import { Evaluation } from '@/shared/models/Pickup/PickupServiceOption';

export const bookingCutoffHasPassed = (service: PickupServiceOption) => {
  if (service.evaluation?.afterBookingCutoff) return true;
  const now = Temporal.Now.instant();
  if (!service.pickupBookingCutoff) return false;
  return service.pickupBookingCutoff.since(now).sign === -1;
};

export const checkPickupEvaluation = (evaluation: Evaluation) => {
  if (evaluation.unsupportedPickupLocation) return false;
  if (evaluation.unsupportedPackagingType) return false;
  if (evaluation.afterPickupCutoff) return false;
  if (evaluation.integrationNotConfigured) return false;

  // advanced checks
  if (evaluation.unsupportedDestinations.length) return false;
  if (evaluation.unsupportedPackagingTypes.length) return false;
  if (evaluation.unavailablePricing.length) return false;

  // DG checks
  if (evaluation.dangerousGoods?.notAllowedByCarrier) return false;
  if (evaluation.dangerousGoods?.carrierAuthorityDenied) return false;

  // no evaluation errors, we're cool!
  return true;
};

export const isPickupServiceRecommended = (service: PickupServiceOption) => {
  // if ANY of the evaluation fail, the answer is no;
  const { evaluation } = service;


  if (!checkPickupEvaluation(evaluation)) return false;

  if (bookingCutoffHasPassed(service)) return false;

  if (!service.selectable) {
    // if all the above passed and the service is not selectable, we missed an evaluation
    logger.error('Unhandled pickup service evaluation', evaluation);
    return false;
  }

  // no evaluation errors, we're cool!
  return true;
};


