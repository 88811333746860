<template>
  <ErrorBoundary v-bind="errorBoundary">
    <template #fallback><i class="fa fa-exclamation-triangle"></i> Search is temporarily unavailable.</template>

    <ControlAutocomplete
      v-model="searchStr"
      :options="searchResults"
      :loading="isLoading"
      class="global-search-input"
      data-testref="global-search"
      keep-first
      open-on-focus
      is-right
      placeholder="Search consignments by number, reference or name"
      field=""
      sensitive
      :tab-select="false"
      dropdown-position="bottom"
      @select="openConsignment"
      @focus="loadRecentlyViewed"
    >
      <template #header>
        <template v-if="showRecentlyViewed">
          <BannerMessage v-if="recentlyViewed.error" type="danger" message="There was a problem updating recently viewed consignments" />
          <div class="summary">
            Recently viewed
          </div>
        </template>
        <div v-else-if="searchResults?.length" class="summary">
          {{ searchResults.length }} results
        </div>
      </template>

      <template #default="{ option }">
        <SearchResultConsignment :consignment="option" :search-str="searchStr" />
      </template>

      <template #empty>
        <div v-if="searchStr.length < minLength || isLoading" class="dropdown-item is-disabled">Enter {{ minLength }} or more characters</div>
        <div v-else class="dropdown-item is-disabled">
          <strong>No results found.</strong>
        </div>
      </template>
    </ControlAutocomplete>
  </ErrorBoundary>
</template>

<script setup lang="ts">
  import {
    computed, reactive, ref, watch, watchEffect,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';

  import BannerMessage from '@/shared/components/BannerMessage.vue';
  import ControlAutocomplete from '@/shared/components/controls/ControlAutocomplete.vue';
  import { ErrorBoundary, useErrorBoundary } from '@/shared/errorHandling';
  import instrument from '@/shared/instrumentation';
  import { ConsignmentSummary } from '@/shared/models';
  import debounceAsync from '@/shared/utils/debounceAsync';
  import { useAsyncLoader } from '@/shared/vuex-async-loader';

  import { useMaybeOrg, useOrgId } from '@App/context';
  import criticalBoundary from '@App/errorHandling/criticalBoundary';
  import { namespace, types } from '@App/modules/GlobalSearch/store';
  import { isEditable } from '@Consignment/helpers';
  import { consignmentEditRoute, consignmentViewRoute } from '@Consignment/helpers/links';

  import useRecentlyViewedConsignments, { SanitisedConsignmentSummary } from '@GlobalSearch/behaviours/useRecentlyViewedConsignments';

  import globalSearchInstrumentationTypes from '../instrumentation/types';

  import SearchResultConsignment from './SearchResultConsignment.vue';

  const { errorBoundary } = useErrorBoundary({
    name: 'error:search',
    onErrorCaptured: ({ error }, { markAsCritical }) => {
      if (criticalBoundary.shouldSetCritical(error)) {
        markAsCritical(error);
      }
    },
  });

  const minLength = 3;
  const store = useStore();
  const router = useRouter();
  const orgId = useOrgId();
  const org = useMaybeOrg();

  const { createLoader } = useAsyncLoader(namespace);
  const searchStr = computed<string>({
    get() {
      return store.getters[`${namespace}/${types.getters.search}`];
    },
    set(value) {
      if (value === undefined) return; // when selecting an option it will try to use option.value

      store.dispatch(`${namespace}/${types.actions.setSearch}`, value);
    },
  });
  const delayedStr = ref(searchStr.value);

  watchEffect(() => {
    store.dispatch(`${namespace}/${types.actions.reset}`);
    if (!org.value) {
      store.dispatch(`${namespace}/${types.actions.setImplicitFilters}`, {
        'organisation.id': undefined,
        'site.id': undefined,
      });
      return;
    }
    const allSiteIds = Object.keys(org.value.sites).join(',');
    store.dispatch(`${namespace}/${types.actions.setImplicitFilters}`, {
      'organisation.id': orgId.value,
      'site.id': allSiteIds,
    });
  });

  watch([searchStr], debounceAsync(async () => {
    delayedStr.value = searchStr.value;

    if (searchStr.value.length >= minLength) {
      await store.dispatch(`${namespace}/${types.actions.listConsignments}`);
    } else {
      await store.dispatch(`${namespace}/${types.actions.clear}`);
    }
  }, 250));

  const openConsignment = async (consignmentSummary: ConsignmentSummary, isRecentlyViewed = false) => {
    instrument.event(globalSearchInstrumentationTypes.CONSIGNMENT_OPENED, {
      consignmentSummary,
      isRecentlyViewed,
      searchTerm: searchStr.value,
    });
    searchStr.value = '';

    if (consignmentSummary.issues && isEditable(consignmentSummary)) {
      await router.push(consignmentEditRoute(consignmentSummary));
    } else {
      await router.push(consignmentViewRoute(consignmentSummary));
    }
  };

  const isLoading = computed(() => createLoader(types.actions.listConsignments) || delayedStr.value !== searchStr.value);

  const { getRecentlyViewedConsignments } = useRecentlyViewedConsignments();
  const recentlyViewed = reactive<{consignments: SanitisedConsignmentSummary[], error?: boolean}>({
    consignments: [],
  });

  const showRecentlyViewed = computed(() => {
    if (searchStr.value.length) return false;
    if (!recentlyViewed.consignments.length) return false;
    return true;
  });

  const searchResults = computed<SanitisedConsignmentSummary[]>(() => {
    if (showRecentlyViewed.value) {
      return recentlyViewed.consignments;
    }
    return store.getters[`${namespace}/${types.getters.results}`];
  });

  const loadRecentlyViewed = () => {
    const { consignments, error } = getRecentlyViewedConsignments();
    recentlyViewed.consignments = consignments;
    recentlyViewed.error = error.value;
  };
</script>


<style lang="scss" scoped>
$search_width: 760px;
$search_height: 540px;

:deep(.autocomplete) {
  & > .dropdown-menu {
    width: $search_width;
    max-width: $search_width;
    margin-top: 8px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    border: 1px solid var(--chome-100);
    padding: 0;

    .dropdown-content {
      max-height: $search_height;
    }
  }
}

:deep(.dropdown-content) {
  padding: 12px;
  box-shadow: none;
  border-radius: 0;
  overflow: hidden;
}

:deep(a.dropdown-item) {
  all: unset; // pretend not to be a link.
  white-space: initial;
  cursor: pointer;
}

.dispatch-date-wrapper {
  min-width: 100px;
  width: 100px;
  text-align: right;
}

.critical-boundary {
  text-align: right;
  color: $copy-50;
}

.summary {
  padding: 0 0 10px 16px;
}

.recently-viewed .search-result {
  cursor: pointer;
}
</style>
