import createModel from '@/shared/models/helpers/createModel';

interface PackagingType {
  name: string;
  description: string;
}

export const createPackagingType = (partial?: Partial<PackagingType>) => createModel<PackagingType>({
  ...partial,
  description: partial?.description || partial?.name?.replace(/([a-z])([A-Z])/g, '$1 $2'), // JiffyBag => Jiffy Bag
}, {
  name: '',
  description: '',
});

export const packagingTypeGqlFields = ['name'];

export default PackagingType;
