/*
* helper for base input components
*
* requirements:
* - main input should be referenced with 'inputEl'
 */
import { pick } from 'radash';
import { InputHTMLAttributes, onMounted, ref, SelectHTMLAttributes } from 'vue';

import createSensitiveDirective from '@/shared/sensitive/createSensitiveDirective';

export interface BasicControlProps {
  dataTestref?: string;
  hasError?: boolean;
  startFocused?: boolean;
  sensitive?: boolean;
}

export interface TextControlProps extends BasicControlProps, /* @vue-ignore */ InputHTMLAttributes {

}

export interface SelectControlProps extends BasicControlProps, /* @vue-ignore */ SelectHTMLAttributes {

}

export function basicControl(props: BasicControlProps) {
  const inputEl = ref();

  function focus() {
    inputEl.value?.focus?.();
  }

  function select() {
    inputEl.value?.select?.();
  }

  onMounted(() => {
    if (!inputEl.value) {
      throw new Error('Control components should bind `inputEl` ref to an element');
    }

    if (props.startFocused) focus();
  });

  const vSensitive = createSensitiveDirective(props.sensitive || false);

  return {
    inputEl,
    focus,
    select,
    vSensitive,
  };
}

export function getBasicControlProps(props: BasicControlProps) {
  const propsToPick = ['dataTestref', 'hasError', 'startFocused', 'sensitive'] as (keyof typeof props)[];
  return pick(props, propsToPick);
}

