import { computed, defineComponent, PropType, provide } from 'vue';

import ModalInstance from '@/shared/modalStack/ModalInstance';

const ModalWithContext = defineComponent({
  props: { modal: { required: true, type: Object as PropType<ModalInstance> } },
  setup(props) {
    provide('modal-instance', computed(() => props.modal));
    return props.modal.render;
  },
});

export default ModalWithContext;
