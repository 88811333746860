import APIClient, { ApiClientError, GeppettoApiResponse } from '@/shared/services/api-client';

import { CarrierPickup, PICKUP_ACTION_FAIL_REASONS } from '../models';

import InvalidOrExpiredCarrierPickupTokenError from './errors/InvalidOrExpiredCarrierPickupTokenError';

import * as pickupMappers from './mappers';
import { PickupClientResponse, PickupConfirmationClientResponse } from './types';

interface PickupConfirmAPIRequest {
  confirm: boolean;
  confirmationReference?: string;
  rejectReason?: string;
}

if (!import.meta.env.GEPPETTO_SERVICE_SENDER_URL) {
  throw new Error('GEPPETTO .env not correctly configured');
}

const baseUrl = import.meta.env.GEPPETTO_SERVICE_SENDER_URL;

const apiClient = new APIClient(baseUrl);

async function actionPickup(token: string, payload: PickupConfirmAPIRequest): Promise<{
  success: boolean;
  failReason?: typeof PICKUP_ACTION_FAIL_REASONS[keyof typeof PICKUP_ACTION_FAIL_REASONS];
}> {
  try {
    await apiClient.put<GeppettoApiResponse<PickupConfirmationClientResponse>>(`/v0/pickups/confirmation/${token}`, { data: payload });

    return { success: true };
  } catch (error) {
    if (error instanceof ApiClientError) {
      if (error.status === 409) { // conflicting action
        return { success: false, failReason: PICKUP_ACTION_FAIL_REASONS.conflict };
      }
      if (error.status === 403) { // already actioned
        return { success: false, failReason: PICKUP_ACTION_FAIL_REASONS.actioned };
      }
      if (error.status === 404) { // expired or invalid token
        throw new InvalidOrExpiredCarrierPickupTokenError(undefined, { cause: error });
      }
    }

    throw error;
  }
}

export default {
  async fetch({ token }: { token: string }): Promise<CarrierPickup> {
    const response = await apiClient.get<GeppettoApiResponse<PickupClientResponse>>(`/v0/pickups/confirmation/${token}`);

    return pickupMappers.carrierPickupClientToCarrierPickup(response.data.data);
  },

  async confirm({ token, confirmationReference }: { token: string, confirmationReference?: string }) {
    return actionPickup(token, { confirm: true, confirmationReference });
  },

  async reject({ token, rejectReason }: { token: string, rejectReason?: string }) {
    return actionPickup(token, { confirm: false, rejectReason });
  },
};
