import { Temporal } from '@js-temporal/polyfill';
import { helpers } from '@vuelidate/validators';
import { withMessage, withParams } from 'veritas';
import { MaybeRefOrGetter, toValue } from 'vue';

import { TimeRange } from '@/shared/DateTime';


import formatTimeRange from '@/shared/DateTime/formatters';

export const validTimeRange = withMessage(
  'end time must be after start time',
  (value: TimeRange) => !helpers.req(value) || value.isValid(),
);

export const minTimeRangeSpan = (minimumMinutes: MaybeRefOrGetter<number>) => withParams(
  { minimumMinutes },
  withMessage(
    ({ $params }) => `must be at least ${toValue($params.minimumMinutes)} minutes`,
    (value: TimeRange) => {
      if (!toValue(minimumMinutes) || !value) return true;
      const minDuration = Temporal.Duration.from({ minutes: toValue(minimumMinutes) });
      return toValue(minimumMinutes) && value ? Temporal.Duration.compare(minDuration, value.duration) <= 0 : true;
    },
  ),
);

export const timeRangeWithinRange = (timeRange?: MaybeRefOrGetter<TimeRange>) => withParams(
  { timeRange },
  withMessage(
    ({ $params }) => `must be within ${formatTimeRange(toValue($params.timeRange))}`,
    (value?: TimeRange) => {
      if (!timeRange || !value) return true;

      const { start: earliestStart, end: latestEnd } = toValue(timeRange);
      return value.start.since(earliestStart).total({ unit: 'minutes' }) >= 0
        && value.end.until(latestEnd).total({ unit: 'minutes' }) >= 0;
    },
  ),
);

export const timeRangeWithinRanges = (timeRanges?: MaybeRefOrGetter<TimeRange[] | undefined>) => withParams(
  { timeRanges },
  withMessage(
    () => 'must be within valid ranges', // todo improve message
    (value?: TimeRange) => {
      const timeRangesValue = toValue(timeRanges);
      if (!timeRangesValue || !value) return true;

      return !timeRangesValue.some((timeRange) => {
        const { start: earliestStart, end: latestEnd } = timeRange;
        return value.start.since(earliestStart).total({ unit: 'minutes' }) < 0
          || value.end.until(latestEnd).total({ unit: 'minutes' }) < 0;
      });
    },
  ),
);
