import SpanEvent from './SpanEvent';
import VueInstrumentation from './VueInstrumentation';

const instrumentation = new VueInstrumentation();

instrumentation.install = function install(app, options) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;
  // Add a $instrument helper to every component that includes component state with every event and
  // span that originates within that component. Used like:
  //   this.$instrument.event('my event', payload);
  //   let span = this.$instrument.span('my span');  span.end(payload);
  app.mixin({
    computed: {
      $instrument() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const component = this;
        return {
          // Automatically add the Vue instance to every event payload
          event(name, payload) {
            return self.event(name, { component, ...payload });
          },
          // Pass $instrument as the instrumentation system so that { component } is included in
          // span before and end events.
          span(name, payload = {}) {
            return new SpanEvent(name, { component, ...payload }, component.$instrument);
          },
        };
      },
    },
  });

  // VueRouter integration
  if (options.router) {
    this._router = options.router;

    // instrument each route change as a pageview
    this._router.afterEach((to) => {
      const { path, params, query } = to;
      this.event(`pageview ${to.name}`, { path, params, query });
    });
  }

  // Vuex integration
  if (options.store) {
    this._store = options.store;

    // instrument each action as a span
    this._store.subscribeAction({
      before: async (action) => {
        action._span = this.span(action.type, { action, store: this._store }); // eslint-disable-line no-param-reassign
      },
      after: async (action) => {
        if (!action._span) return;
        // Include the full store state in the action so meanin
        action._span.end({ action, store: this._store });
      },
    });
  }
};

export default instrumentation;
