<template>
  <progress class="app-progress"></progress>
</template>

<script setup lang="ts">

</script>

<style lang="scss" scoped>
  @keyframes fillWidth {
    0% { transform: scaleX(0) }
    100% { transform: scaleX(1) }
  }

  progress.app-progress {
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
    padding: 0;
    background-color: var(--brand-100);

    animation: fillWidth 5s infinite ease-out;
    transform-origin: left;

    // disable default browser appearance
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance: none;
    &::-webkit-progress-inner-element {
      display: none;
    }
    // TODO: firefox?
  }
</style>
