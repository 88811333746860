<template>
  <ErrorLayout :title="errorTitle">
    <template #figure>
      <img src="@/assets/404.svg?url" alt="404">
    </template>
    <template #default>
      <p>Here are some helpful links to get you back on track:</p>
      <ul>
        <li>
          <SvgIcon :svg="ArrowRightIcon" class="mr-1 has-text-primary" />
          <a @click="$router.go(-1)">Go back</a>
        </li>
        <li v-if="userIsAuthenticated">
          <SvgIcon :svg="ArrowRightIcon" class="mr-1 has-text-primary" />
          <a @click="$router.push(createConLocation)">Create consignment</a>
        </li>
        <li v-if="userIsAuthenticated">
          <SvgIcon :svg="ArrowRightIcon" class="mr-1 has-text-primary" />
          <a @click="$router.push(manageConLocation)">Ready-to-manifest consignments</a>
        </li>
        <li v-if="userIsAuthenticated">
          <SvgIcon :svg="ArrowRightIcon" class="mr-1 has-text-primary" />
          <a @click="$router.push(trackConLocation)">Track consignments</a>
        </li>
      </ul>
    </template>
  </ErrorLayout>
</template>

<script>
  import { mapGetters } from 'vuex';

  import SvgIcon from '@/shared/icons/SvgIcon.vue';

  import ArrowRightIcon from '@/shared/icons/svgs/arrowRight.svg';

  import ErrorLayout from '@App/layout/views/ErrorLayout.vue';

  import App from '@App/store/types';
  import Org from '@Org/store/types';

  export default {
    name: 'NotFound',
    components: { SvgIcon, ErrorLayout },
    props: {
      title: {
        type: String,
        default: () => '',
      },
      error: {
        type: Object,
        default: null,
      },
    },
    computed: {
      ...mapGetters({
        defaultSite: Org.getters.defaultSite,
        userIsAuthenticated: App.getters.userIsAuthenticated,
      }),
      ArrowRightIcon() {
        return ArrowRightIcon;
      },
      errorTitle() {
        if (this.title) return this.title;
        if (this.error?.resourceType) return `Oops! ${this.error.resourceType} not found.`;
        return 'Oops! Page not found.';
      },
      contextId() {
        return this.$route.params?.contextId;
      },
      contextType() {
        return this.$route.params?.contextType;
      },
      createConLocation() {
        return {
          name: 'consignment.create',
          params: {
            contextType: this.contextType || 'site',
            contextId: this.contextId || this.defaultSite.id,
          },
        };
      },
      manageConLocation() {
        return {
          name: 'consignment.readyToManifest',
          params: {
            contextType: this.contextType || 'site',
            contextId: this.contextId || this.defaultSite.id,
          },
        };
      },
      trackConLocation() {
        return {
          name: 'track.consignments',
          params: {
            contextType: this.contextType || 'site',
            contextId: this.contextId || this.defaultSite.id,
          },
        };
      },
    },
  };
</script>

<style lang="scss" scoped>

</style>
