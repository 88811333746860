
interface Contact {
  id?: string;
  name: string;
  phone: string;
  email?: string;
}

const newContact = (): Contact => ({
  name: '',
  phone: '',
});

export const createContact = ({
  id,
  name,
  phone,
  email,
}: Contact | undefined = newContact()): Contact => ({
  id,
  name,
  phone,
  email,
});

interface ContactFromAddress {
  contactName?: string;
  contactPhone?: string;
  contactEmail?: string;
}

export const createContactFromAddressObject = ({
  contactName = '',
  contactPhone = '',
  contactEmail = '',
}: ContactFromAddress) => createContact({
  name: contactName,
  phone: contactPhone,
  email: contactEmail,
});

export default Contact;
