import { CarrierServiceProviderType } from '@/shared/types/AgreedService';

import Consignment from '../Consignment';
import Organisation, { createOrganisation, organisationGqlFields } from '../Organisation';

interface AgreedService {
  id: UUID;
  name: string;
  carrierChargeAccount: string;
  carrierServiceId: UUID;
  carrier: Organisation;
  carrierServiceProviderType: CarrierServiceProviderType;
}

export function hasDetails(service: Consignment['agreedService']): service is AgreedService {
  return !!((service as AgreedService).name);
}

const newAgreedService = (): AgreedService => ({
  id: '',
  name: '',
  carrierChargeAccount: '',
  carrierServiceId: '',
  carrier: createOrganisation(),
  carrierServiceProviderType: 'carrier',
});

export const createAgreedService = ({
                                      id,
                                      name,
                                      carrierChargeAccount,
                                      carrierServiceId,
                                      carrierServiceProviderType,
                                      carrier,
                                      // fourplOrg,
                                    }: AgreedService | undefined = newAgreedService()) => ({
  id,
  name,
  carrierChargeAccount,
  carrierServiceId,
  carrierServiceProviderType,

  // mapped fields
  carrier: createOrganisation(carrier),
  // fourplOrg: createOrganisation(fourplOrg),
});

export const agreedServiceGqlFields = [
  'uuid',
  'name',
  'carrierChargeAccount',
  'carrierServiceId',
  'carrierServiceProviderType',
  { carrier: organisationGqlFields },
];

export default AgreedService;
