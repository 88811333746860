<template>
  <component
    :is="to ? RouterLink : 'a'"
    v-bind="to ? { to, target, disabled } : { href, target, disabled }"
    class="base-link"
    :tabindex="disabled ? -1 : 0"
  >
    <slot></slot>
    <SvgIcon
      v-if="iconRight"
      :svg="typeof props.iconRight === 'boolean' ? ExternalIcon : props.iconRight"
    />
  </component>
</template>

<script setup lang="ts">

  import { type Component } from 'vue';
  import { RouterLink, RouterLinkProps } from 'vue-router';

  import SvgIcon from '@/shared/icons/SvgIcon.vue';
  import ExternalIcon from '@/shared/icons/svgs/external.svg';

  export type BaseLinkProps = {
    to?: RouterLinkProps['to'];
    href?: HTMLLinkElement['href'];
    iconRight?: Component | boolean;
    target?: HTMLLinkElement['target'];
    disabled?: boolean;
  };

  const props = defineProps<BaseLinkProps>();

</script>

<style scoped lang="scss">
.base-link {
  &[disabled="true"] {
    pointer-events: none;
    color: var(--copy-30);
  }

  svg {
    color: var(--brand-50);
  }

  &:hover {
    text-decoration: underline;
    svg {
      color: var(--brand-100);
    }
  }
}
</style>
