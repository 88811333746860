import { getConfig } from '@/shared/init';

import FauxStory from './FullStory';

const geppettoConfig = getConfig();

const FullStory = new FauxStory({
  enabled: geppettoConfig.fullStory?.enabled,
  orgId: geppettoConfig.fullStory?.orgId,
  globalUserVars: {
    version: geppettoConfig.version,
  },
});

export {
  FullStory as default,
};
