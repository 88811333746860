<template>
  <ListBoxDropDown
    v-bind="listBoxDropDownProps"
    ref="listBoxDropDownRef"
    @mouseenter="isOpen = true"
    @mouseleave="isOpen = false"
    @focusout="onFocusOut"
    @click="isOpen = !isOpen"
  >
    <template #trigger>
      <slot name="trigger"></slot>
    </template>
    <template #drop-down>
      <ListBox :size="size">
        <slot name="menu"></slot>
      </ListBox>
    </template>
  </ListBoxDropDown>
</template>

<script lang="ts" setup>
  import { computed, provide, ref, watch } from 'vue';

  import { ListBox, ListBoxDropDown } from './list-box';

  const props = withDefaults(defineProps<{
    size?: 'sm' | 'md';
    left?: boolean;
    top?: boolean;
  }>(), {
    size: 'md',
    left: false,
    top: false,
  });

  const emit = defineEmits(['open', 'close']);

  const listBoxDropDownRef = ref<typeof ListBoxDropDown | null>(null);

  const isOpen = ref(false);

  watch(isOpen, () => {
    if (isOpen.value) {
      emit('open');
    } else {
      emit('close');
    }
  });

  const listBoxDropDownProps = computed(() => ({
    open: isOpen.value,
    left: props.left,
    top: props.top,
  }));

  const onFocusOut = (event: MouseEvent) => {
    if (!listBoxDropDownRef.value?.$el.contains(event.relatedTarget)) {
      isOpen.value = false;
    }
  };

  provide('listItemTabbable', true);
</script>
