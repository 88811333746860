import { RecursivePartial } from '@/shared/types/utility';

/**
 * @deprecated
 *
 * Do not use this. We will refactor this later with client mappers. No longer needed
 */
const createModel = <T>(partial: RecursivePartial<T> | undefined, defaultValues: T): T => {
  const model = { ...defaultValues } as T;
  if (partial) {
    const modelKeys = Object.keys(model as Record<string, never>) as (keyof T)[];
    const partialKeys = Object.keys(partial as Record<string, never>) as (keyof T)[];
    modelKeys.forEach((key) => {
      if (partialKeys.includes(key)) {
        if (typeof model[key] === 'object' && !Array.isArray(model[key]) && model[key] !== null) {
          model[key] = createModel(partial[key] as never, model[key]);
        } else {
          model[key] = partial[key] as never;
        }
      }
    });
  }
  return model;
};

export default createModel;
