export default class AuthError extends Error {
  constructor(...params) {
    super(...params);

    // Maintains proper stack trace for where our error was thrown
    // Only works in v8 (node & Chrome)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }

    this.name = 'AuthError';
    if (!this.message) {
      this.message = 'An unknown error has occurred.';
    }
  }
}
