import('./sensitive-preview.scss');

/* This plugin can be used in conjunction with the feature system
/  by adding the following snippet to base App setup function */
// const feature = useFeature();
// if (feature.enabled(appFeatures.sensitive).value) {
//   const sensitivePreviewType = feature.config(appFeatures.sensitive).value;
//   document.body.classList.add(`sensitive-preview-${sensitivePreviewType}`);
// }

function install(app) {
  app.mixin({
    props: {
      sensitive: { type: Boolean, default: () => false },
    },
  });

  app.directive('sensitive', {
    beforeMount(el, binding) {
      const always = !!binding.modifiers.always;
      const exclude = !!binding.modifiers.exclude;

      // e.g. v-sensitive.exclude
      if ((binding.instance.sensitive || always) && exclude) {
        el.dataset.sensitive = 'exclude'; // eslint-disable-line no-param-reassign
        return;
      }

      // v-sensitive
      if (binding.instance.sensitive || always) {
        el.dataset.sensitive = 'mask'; // eslint-disable-line no-param-reassign
      }
    },
  });
}

const plugin = { install };

export {
  plugin as default,
};
