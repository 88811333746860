import { Temporal } from '@js-temporal/polyfill';
import omit from 'lodash/omit';

import {
  Consignment,
  ConsignmentAutoprintPreference,
  consignmentEnquiryFactory,
  ConsignmentLabelOptions,
  ConsignmentSummary,
  NewConsignment,
} from '@/shared/models';
import ConsignmentEnquiry from '@/shared/models/ConsignmentEnquiry';
import ConsignmentResultSet from '@/shared/models/ConsignmentResultSet';
import ListingFilter from '@/shared/models/ListingFilters';
import polly from '@/shared/polly';
import ApiClient, {
  ApiClientError,
  ApiClientRequestConfig,
  GeppettoJSONApiResponse,
} from '@/shared/services/api-client';
import { ExtractFilterKeys, formatSearchParams, FormatSearchParamsArgs, SearchParams } from '@/shared/services/helpers';
import { operations } from '@/shared/services/schema/consignment.schema';
import ConsignmentAlreadyManifestedError from '@/shared/services/sender/errors/ConsignmentAlreadyManifestedError';
import ConsignmentAlreadyUpdatedError from '@/shared/services/sender/errors/ConsignmentAlreadyUpdatedError';
import SiteIdLimitError from '@/shared/services/sender/errors/SiteIdLimitError';
import * as mappers from '@/shared/services/sender/mappers';

import Instant = Temporal.Instant;

type GetConsignmentFilterKeys = ExtractFilterKeys<operations['getConsignments']>;

export interface ConsignmentListParams extends Omit<ListingFilter, 'filters'> {
  filters?: Omit<GetConsignmentFilterKeys, 'eta'> & {
    'receiver.name'?: string; // not documented
    'eta'?: [Instant, Instant]; // custom type, manually mapped to string in service call
  }
}

export interface ConsignmentClientConfig {
  baseUrl: string;
  maxSiteIdParams: number;
}

interface FetchEnquiriesResponse {
  data: {
    id: UUID;
    attributes: Omit<ConsignmentEnquiry, 'id'>
  }[];
}

interface EntityDocument {
  id: UUID;
  documentFormat?: string;
  useBaseUrl?: boolean;
}

interface ConsignmentLabel extends EntityDocument {
  generateInstructions?: boolean,
  newItemsOnly?: boolean,
}

type FormattedParams<T> = { [P in keyof T]: T[P] | string; };

function formatConsignmentListFilters(
  filters: ConsignmentListParams['filters'],
): FormattedParams<NonNullable<ConsignmentListParams['filters']>> {
  if (!filters) return {};

  const formattedFilters: FormattedParams<ConsignmentListParams['filters']> = { ...filters };

  if (filters?.dispatchDate && /\d{4}-\d{2}-\d{2},\d{4}-\d{1,2}-\d{2}$/.test(filters.dispatchDate)) {
    formattedFilters.dispatchDate = `[${formattedFilters.dispatchDate}]`;
  }

  if (filters?.eta) {
    const [etaStart, etaEnd] = filters.eta;
    formattedFilters.eta = `[${etaStart},${etaEnd})`;
  }

  return formattedFilters;
}



export default class ConsignmentClient {
  private apiClient: ApiClient;

  private config: ConsignmentClientConfig;

  constructor(apiClient: ApiClient, config: ConsignmentClientConfig) {
    this.apiClient = apiClient;
    this.config = config;
  }

  listConsignmentsUrlBuilder({
    limit,
    offset,
    sort = '-dispatchDate,-updated',
    search,
    filters = {},
  }: ConsignmentListParams = {}, exportFormat: 'csv' | undefined = undefined) {
    const formattedFilters = formatConsignmentListFilters(filters);
    const params = formatSearchParams({ limit, offset, sort, filters: formattedFilters, search });

    let urlPath = '/v0/consignments';
    if (exportFormat) {
      if (['csv'].includes(exportFormat)) {
        urlPath += `.${exportFormat}`;
      } else {
        logger.warn('[SenderService] export format not supported');
      }
    }

    return this.apiClient.getUri(urlPath, { params });
  }

  /**
   * CONSIGNMENT
   */

  async create({ consignment: consignmentData, autoprint }: {
    consignment: NewConsignment;
    autoprint: ConsignmentAutoprintPreference;
  }): Promise<{ id: UUID }> {
    const consignment = omit(consignmentData, 'items', 'pods', 'tracking');

    type CreateConsignmentPayload = operations['createConsignment']['requestBody']['content']['application/json'];
    const requestPayload: CreateConsignmentPayload = mappers.consignment
      .mapNewConsignmentToClientCreateConsignmentResource({ consignment: consignmentData, autoprint });

    const response = await this.apiClient.post<operations['createConsignment']['responses']['201']['content']['application/json']>(
      '/v0/consignments',
      requestPayload,
    );

    const { id } = response.data.data;

    logger.debug('[SenderService] Consignment created', {}, { formData: consignment, consignmentId: id, response });

    // Return consignment and labels together
    return { id };
  }

  /**
   * @deprecated use searchConsignments method instead
   */
  async listConsignments({
                           limit,
                           offset,
                           sort = '-updated,-dispatchDate',
                           filters,
                           search,
                         }: FormatSearchParamsArgs, config?: ApiClientRequestConfig) {
    const formattedFilters = { ...filters };

    // if an empty agreed service id list is given, return nothing.
    if (filters?.['agreedService.id'] === '') return ConsignmentResultSet.create();

    if (filters?.dispatchDate && /\d{4}-\d{2}-\d{2},\d{4}-\d{1,2}-\d{2}$/.test(filters.dispatchDate as string)) {
      formattedFilters.dispatchDate = `[${formattedFilters.dispatchDate}]`;
    }

    const params = formatSearchParams({ limit, offset, sort, filters: formattedFilters, search });

    // we have a limit of n site IDs
    const maxSiteIds = this.config.maxSiteIdParams as number;
    if (maxSiteIds && params['filter[site.id]'] && params['filter[site.id]'].split(',').length > maxSiteIds) {
      throw new SiteIdLimitError(params['filter[site.id]'].split(',').length, maxSiteIds);
    }

    const { data } = await this.apiClient.query<operations['getConsignments']['responses']['200']['content']['application/json']>('/v0/consignments', { ...config, params });

    return mappers.consignmentSummary.mapClientResultSetToResultSet(data);
  }

  async searchConsignments({
    limit,
    offset,
    sort = '-updated,-dispatchDate',
    filters,
    search,
  }: ConsignmentListParams, config?: ApiClientRequestConfig) {
    // check for invalid zero or max site IDs limit
    if (!filters?.['site.id']?.length) {
      throw new Error('Site must be provided');
    }
    const maxSiteIds = this.config.maxSiteIdParams as number;
    if (maxSiteIds && filters['site.id'] && filters['site.id'].length > maxSiteIds) {
      throw new SiteIdLimitError(filters['site.id'].length, maxSiteIds);
    }

    const formattedFilters = formatConsignmentListFilters(filters);
    const params = formatSearchParams({ limit, offset, sort, filters: formattedFilters, search });

    type GetConsignmentsResponse = operations['getConsignments']['responses']['200']['content']['application/json'];
    const { data } = await this.apiClient.query<GetConsignmentsResponse>(
      '/v0/consignments',
      { ...config, params },
    );

    return mappers.consignmentSummary.mapClientResultSetToResultSet(data);
  }

  public async getConsignments({ consignmentIds }: { consignmentIds: UUID[] }) {
    const params: SearchParams = {
      'filter[consignment.id]': consignmentIds.join(','),
    };

    const { data } = await this.apiClient.query<operations['getConsignments']['responses']['200']['content']['application/json']>('/v0/consignments', { params });

    return mappers.consignmentSummary.mapClientResultSetToResultSet(data);
  }

  async fetchConsignmentById({ id }: { id: UUID }, config?: ApiClientRequestConfig) {
    const {
      data,
      headers,
    } = await this.apiClient.get<operations['viewConsignments']['responses']['200']['content']['application/json']>(`/v0/consignments/${id}`, config);

    return {
      consignment: mappers.consignment.mapClientGetConsignmentResponseToConsignment(data),
      links: data.data.links,
      version: headers.etag,
    };
  }

  async fetchConsignmentVersion({ id, previousVersion = null }: { id: UUID, previousVersion: string | null }) {
    // const { headers } = await polly(() => this.apiClient.head(`/v0/consignments/${id}`), { // soon:tm:
    const {
      data,
      headers,
    } = await polly(() => this.apiClient.get<operations['viewConsignments']['responses']['200']['content']['application/json']>(`/v0/consignments/${id}`), {
      retryCondition: (response) => response.headers.etag === previousVersion,
    });

    return {
      consignment: mappers.consignment.mapClientGetConsignmentResponseToConsignment(data),
      links: data.data.links,
      version: headers.etag,
    };
  }

  async generateConsignmentSummary({
    consignment,
    version = undefined,
  }: { consignment: NewConsignment, version: string | undefined }) {
    try {
      const requestPayload = mappers.consignment.mapNewConsignmentToClientCreateConsignmentResource({ consignment });
      const response = await this.apiClient.put<operations['summariseConsignmentUpdate']['responses']['200']['content']['application/json']>(
        `/v0/consignments/${consignment.id}/summary`,
        requestPayload,
        {
          headers: {
            'If-Match': version,
          },
        },
      );

      return {
        labelOptions: mappers.consignment.mapUpdateSummaryToConsignmentLabelOptions(response.data),
        success: response.status >= 200 && response.status < 300,
      };
    } catch (err: unknown) {
      if (err instanceof ApiClientError) {
        if (err.code === 'alreadyUpdated') {
          throw new ConsignmentAlreadyUpdatedError(undefined, { cause: err });
        }

        if (err.code === 'alreadyManifested') {
          throw new ConsignmentAlreadyManifestedError(undefined, { cause: err });
        }
      }

      throw (err);
    }
  }

  async updateConsignment({ consignment, autoprint, labelOptions, version = undefined }: {
    consignment: NewConsignment;
    autoprint: ConsignmentAutoprintPreference;
    labelOptions: ConsignmentLabelOptions;
    version: string | undefined;
  }) {
    try {
      type UpdateConsignmentPayload = operations['updateConsignment']['requestBody']['content']['application/json'];
      const requestPayload: UpdateConsignmentPayload = mappers.consignment
        .mapNewConsignmentToClientUpdateConsignmentResource({ consignment, autoprint, labelOptions });

      type UpdateConsignmentResponse = operations['updateConsignment']['responses']['202']['content']['application/json'];
      const response = await this.apiClient.put<UpdateConsignmentResponse>(
        `/v0/consignments/${consignment.id}`,
        requestPayload,
{ headers: { 'If-Match': version } },
      );

      return { success: response.status >= 200 && response.status < 300 };
    } catch (err: unknown) {
      if (err instanceof ApiClientError) {
        if (err.code === 'alreadyUpdated') {
          throw new ConsignmentAlreadyUpdatedError(undefined, { cause: err });
        }

        if (err.code === 'alreadyManifested') {
          throw new ConsignmentAlreadyManifestedError(undefined, { cause: err });
        }
      }

      throw (err);
    }
  }

  async deleteConsignment({ consignmentId, version = '*' }: { consignmentId: UUID, version?: string }) {
    try {
      const response = await this.apiClient.delete(`/v0/consignments/${consignmentId}`, { headers: { 'If-Match': version } });

      return { success: response.status >= 200 && response.status < 300 };
    } catch (err: unknown) {
      if (err instanceof ApiClientError) {
        const status = err.apiError?.meta?.status;

        if (status === 'manifested') {
          throw new ConsignmentAlreadyManifestedError(undefined, { cause: err });
        }
      }
      throw (err);
    }
  }

  /*
  * DOCUMENTS
  */

  async pollDocumentStatus(docPath: string) {
    return polly(() => this.apiClient.head(docPath));
  }

  async getDocumentUrlWhenReady(path: string, useBaseUrl = false) {
    const params = (new URLSearchParams()).toString();
    const documentPath = `${path}${params && `?${params}`}`;
    await this.pollDocumentStatus(documentPath);
    return `${useBaseUrl ? this.config.baseUrl : ''}${documentPath}`;
  }

  async getLabelUrl({
    id,
    documentFormat = 'pdf',
    useBaseUrl = false,
    generateInstructions = false,
    newItemsOnly = false,
  }: ConsignmentLabel) {
    const queryParams = new URLSearchParams();
    if (generateInstructions) queryParams.append('generateInstructions', 'true');
    if (newItemsOnly) queryParams.append('newItemsOnly', 'true');
    return this.getDocumentUrlWhenReady(`/v0/consignments/${id}/labels${documentFormat === 'pdf' ? '.pdf' : ''}${queryParams.toString() ? `?${queryParams.toString()}` : ''}`, useBaseUrl);
  }

  async getConnoteUrl({ id, documentFormat = 'pdf', useBaseUrl = false }: EntityDocument) {
    return this.getDocumentUrlWhenReady(`/v0/consignments/${id}/connote.${documentFormat}`, useBaseUrl);
  }

  async getDGSummaryUrl({ id, documentFormat = 'pdf', useBaseUrl = false }: EntityDocument) {
    return this.getDocumentUrlWhenReady(`/v0/consignments/${id}/dg-summary.${documentFormat}`, useBaseUrl);
  }

  async getHTMLDocument(docPath: string) {
    return polly(() => this.apiClient.html(docPath));
  }

  consignmentPodUrl(consignmentId: UUID, documentId: UUID) {
    return `${this.config.baseUrl}/v0/consignments/${consignmentId}/pods/${documentId}`;
  }

  openConsignmentPods(consignment: Consignment | ConsignmentSummary) {
    consignment.pods
      .filter(pod => pod.data.documentId)
      .forEach(pod => window.open(this.consignmentPodUrl(consignment.id, pod.data.documentId)));
  }

  /*
  * ENQUIRIES
  */

  async fetchEnquiries({ filters }: FormatSearchParamsArgs, config?: ApiClientRequestConfig) {
    const params = formatSearchParams({ filters });

    const response = await this.apiClient.query<FetchEnquiriesResponse>('/v0/enquiries', { ...config, params });
    return response.data?.data?.map(consignmentEnquiryFactory.createFromApi) || [];
  }

  async fetchConsignmentEnquiries({ consignmentId }: { consignmentId: UUID }, config?: ApiClientRequestConfig) {
    return this.fetchEnquiries({
      filters: {
        'consignment.id': consignmentId,
      },
    }, config);
  }

  async postEnquiry(enquiry: ConsignmentEnquiry & { consignmentId: string, link: string }) {
    try {
      const response = await this.apiClient.post<GeppettoJSONApiResponse<unknown>>('/v0/enquiries', {
        data: {
          type: 'enquiries',
          attributes: {
            consignment: {
              id: enquiry.consignmentId,
              link: enquiry.link,
            },
            type: enquiry.type,
            reason: enquiry.reason,
            description: enquiry.description,
          },
        },
      });

      logger.debug('[SenderService] Enquiry created', {}, { enquiry, response });

      return consignmentEnquiryFactory.createFromApi(response.data.data as never);
    } catch (error) {
      logger.error('[SenderService] Enquiry create failed', { error });
      throw error;
    }
  }
}
