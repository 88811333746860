/**
 * Vuex store getters, mutation and actions definition.
 */

const InternalTypes = {
  getters: {
    user: 'user',
    userIsAuthenticated: 'userIsAuthenticated',
    userHasAcceptedTerms: 'userHasAcceptedTerms',
    userHasChangedSite: 'userHasChangedSite',
  },

  mutations: {
    SET_USER_CHANGED_SITE: 'SET_USER_CHANGED_SITE',
  },

  actions: {
    confirmSession: 'confirmSession',
    checkExistingSession: 'checkExistingSession',
    fetchUserAccess: 'fetchUserAccess',
    logout: 'logout',
    siteChanged: 'siteChanged',
  },
};

/**
 * App store, being the root store, is not namespaced, but only getters and actions are exposed.
 */
const ModuleTypes = {
  getters: InternalTypes.getters,
  actions: InternalTypes.actions,
};

export {
  ModuleTypes as default,
  InternalTypes,
};
