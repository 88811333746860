<template>
  <div class="dialog-container" :class="compact && 'is-compact'">
    <StatusIcon :type="type" :class="!compact && 'is-large'" class="dialog-start" />
    <div v-sensitive class="dialog-body">
      <div v-if="title" class="dialog-title">{{ title }}</div>
      <slot>
        <TextParagraphs v-if="text" :text="text" />
      </slot>
    </div>
    <div class="dialog-end">
      <slot name="end">
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Theme } from '@/shared/consts';
  import StatusIcon from '@/shared/icons/StatusIcon.vue';
  import TextParagraphs from '@/shared/notify/components/TextParagraphs.vue';
  import createSensitiveDirective from '@/shared/sensitive/createSensitiveDirective';

  const props = withDefaults(defineProps<{
    type: Theme,
    title?: string,
    text?: string,
    compact?: boolean,
    sensitive?: boolean,
  }>(), {
    text: undefined,
    title: undefined,
    compact: false,
    sensitive: false,
  });

  const vSensitive = createSensitiveDirective(() => props.sensitive);
</script>

<style lang="scss">
// styles are NOT scoped so they work with sweetalert2
$dialog-padding: 16px 24px;
$dialog-padding-compact: 12px 16px;

.dialog-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  text-align: left;
  line-height: 1.5;
  padding: $dialog-padding 24px;

  &.is-compact {
    gap: 16px;
    min-height: 20px;
    padding: $dialog-padding-compact;
  }

  .dialog-start {
    align-self: flex-start;
    flex-grow: 0;
  }
  .dialog-body {
    flex-grow: 1;

    & *:not(:last-child) {
      margin-bottom: 4px;
    }
  }
  .dialog-end {
    flex-grow: 0;
    align-self: flex-start;

    &:empty {
      display: none;
    }
  }
}

.dialog-title {
  @include H4-default;
}
</style>
