<template>
  <CarrierPickupDetail
    :status-icon="IconStatusDanger"
    :pickup="pickupDetails"
    :loading="isLoadingDetails"
  >
    <template #title>
      Pickup request:<br>
      {{ pickupDetails.reference }}<br>
      has been rejected.
    </template>

    <FormFieldWrapper label="Pickup rejection reason" optional>
      <ControlText
        v-model="rejectReason"
        placeholde="Add a rejection reason"
        :disabled="isPickupActioned"
        data-testref="confirm-ref-input"
        sensitive
      />
    </FormFieldWrapper>
    <div v-if="!isPickupActioned" class="buttons is-right">
      <button type="button" class="button" data-testref="confirm-btn" @click="reject(token, rejectReason)">Add rejection reason</button>
    </div>
  </CarrierPickupDetail>
</template>

<script setup>
  import { Temporal } from '@js-temporal/polyfill';
  import { useTitle } from '@vueuse/core';
  import { computed, h, onMounted, ref } from 'vue';

  import ControlText from '@/shared/components/controls/ControlText.vue';
  import FormFieldWrapper from '@/shared/components/controls/wrappers/FormFieldWrapper.vue';
  import SvgIcon from '@/shared/icons/SvgIcon.vue';
  import IconDanger from '@/shared/icons/svgs/danger.svg';

  import CarrierPickupClient from '../apiClient/carrierPickupClient';
  import CarrierPickupDetail from '../components/CarrierPickupDetail.vue';

  const props = defineProps({
    token: { type: String, required: true },
  });

  const pageTitle = useTitle('View pickup response');

  const rejectReason = ref('');
  const pickupDetails = ref(null);
  const isLoadingDetails = ref(false);

  const isPickupActioned = computed(() => !!pickupDetails.value.rejection?.reason);

  const IconStatusDanger = h(SvgIcon, { style: 'color: var(--danger-100);', svg: IconDanger });

  async function reject(token, reason) {
    const response = await CarrierPickupClient.reject({ token: props.token, rejectReason: reason });

    if (response.error) {
      throw new Error('error in pickup action ');
    }

    if (reason) {
      pickupDetails.value.rejection = { reason, rejectedAt: Temporal.Now.instant() };
    }
  }

  onMounted(async () => {
    // load pickup details
    try {
      isLoadingDetails.value = true;

      pickupDetails.value = await CarrierPickupClient.fetch({ token: props.token });
    } finally {
      isLoadingDetails.value = false;
    }

    pageTitle.value = `View pickup response: ${pickupDetails.value.reference}`;

    if (pickupDetails.value.rejection?.reason) {
      rejectReason.value = pickupDetails.value.rejection.reason;
    }
  });
</script>

