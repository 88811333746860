import Address, { createAddress } from '@/shared/models/Address';

interface Location {
  name: string;
  addressId?: string;
  address?: Address;
  line2?: string;
  residential: boolean;
}

const newLocation = (): Location => ({
  name: '',
  addressId: '',
  address: createAddress(),
  residential: false,
});

export const createLocation = ({
  name,
  addressId,
  address,
  line2,
  residential,
}: (Omit<Location, 'residential'> & {residential?: boolean}) | undefined = newLocation()): Location => ({
  name,
  addressId,
  address: createAddress(address || undefined),
  line2,
  residential: residential ?? false,
});

interface AddressAndLocation extends Address {
  name: string;
  residential: boolean;
}

export const locationFromAddressObject = ({
  name,
  id,
  line1,
  line2,
  locality,
  postcode,
  subdivision,
  countryId,
  timeZone,
  from,
  gnafId,
  residential,
}: AddressAndLocation) => createLocation({
  name,
  addressId: id,
  line2,
  address: createAddress({
    id,
    line1,
    line2,
    locality,
    postcode,
    subdivision,
    countryId,
    timeZone,
    from,
    gnafId,
  }),
  residential,
});

export type LocationType = 'locality' | 'address';

export default Location;
