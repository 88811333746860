export default class InMemoryStorage {
  constructor() {
    this._data = {};
  }

  setItem(id, val) {
    // eslint-disable-next-line no-return-assign
    return this._data[id] = String(val);
  }

  getItem(id) {
    // eslint-disable-next-line no-prototype-builtins
    return this._data.hasOwnProperty(id) ? this._data[id] : null;
  }

  removeItem(id) {
    return delete this._data[id];
  }

  clear() {
    // eslint-disable-next-line no-return-assign
    return this._data = {};
  }
}
