<template>
  <div class="error-boundary-display">
    <slot :errors="errors">
      <ErrorDisplayList :errors="errors" />
    </slot>
  </div>
</template>

<script setup lang="ts">
  import { computed, onBeforeMount, onUnmounted } from 'vue';

  import ErrorDisplayList from './ErrorDisplayList.vue';
  import errorStore from './errorStore';

  const props = defineProps<{
    name: string,
  }>();

  const errors = computed(() => errorStore.getErrorBoundary(props.name)?.errors || []);

  onBeforeMount(() => {
    errorStore.registerErrorBoundaryDisplay(props.name);
  });

  onUnmounted(() => {
    errorStore.unregisterErrorBoundaryDisplay(props.name);
  });
</script>
