import { shake } from 'radash';

import { formatAgreedService } from '@/shared/formatters';

import Quote from './Quote';
import QuoteWithService from './QuoteWithService';

export function isSelectable(quote: Quote): boolean {
  return !!quote.selectable;
}

export function quoteSortCheapest(quote1: Quote, quote2: Quote): number {
  if (!quote1.selectable || quote1.total === null || quote1.total === undefined || Number.isNaN(quote1.total)) {
    return 1;
  }
  if (!quote2.selectable || quote2.total === null || quote2.total === undefined || Number.isNaN(quote1.total)) {
    return -1;
  }
  return (quote1.total <= quote2.total) ? -1 : 1;
}


export function quoteSortDisplay(quote1: QuoteWithService, quote2: QuoteWithService): number {
  if (!quote1.selectable && !quote2.selectable) {
    return formatAgreedService(quote1.agreedServiceModel).localeCompare(formatAgreedService(quote2.agreedServiceModel));
  }

  if (quote1.selectable && !quote2.selectable) return -1;
  if (!quote1.selectable && quote2.selectable) return 1;

  const quote1HasEvaluation = Object.keys(quote1.evaluation).length > 0;
  const quote2HasEvaluation = Object.keys(quote2.evaluation).length > 0;

  if (quote1HasEvaluation && quote2HasEvaluation) {
    return formatAgreedService(quote1.agreedServiceModel).localeCompare(formatAgreedService(quote2.agreedServiceModel));
  }
  if (quote1HasEvaluation && !quote2HasEvaluation) return 1;
  if (!quote1HasEvaluation && quote2HasEvaluation) return -1;

  const costDifference = (quote1.total || 0) - (quote2.total || 0);
  if (costDifference !== 0) return costDifference;

  return formatAgreedService(quote1.agreedServiceModel).localeCompare(formatAgreedService(quote2.agreedServiceModel));
}

export function removeEmptyEvaluations<T>(evaluations: T extends object ? T : never) {
  return shake(evaluations, e => (Array.isArray(e) ? !e.length : !e));
}
