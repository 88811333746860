<template>
  <div v-if="iconComponent" class="status-icon" :class="`has-text-${type}`">
    <SvgIcon :svg="iconComponent" size="medium" />
  </div>
</template>

<script>
  import { computed } from 'vue';

  import { THEME } from '@/shared/consts';
  import SvgIcon from '@/shared/icons/SvgIcon.vue';

  import IconStatusDanger from '@/shared/icons/svgs/danger.svg';
  import IconStatusInfo from '@/shared/icons/svgs/info.svg';
  import IconStatusSuccess from '@/shared/icons/svgs/success.svg';
  import IconStatusWarning from '@/shared/icons/svgs/warning.svg';

  // if this is updated the kinds in the style block also need to be updated
  export const STATUS_TYPE = {
    DANGER: THEME.DANGER,
    INFO: THEME.INFO,
    SUCCESS: THEME.SUCCESS,
    WARNING: THEME.WARNING,
  };
  const ICON_COMPONENTS = {
    [STATUS_TYPE.DANGER]: IconStatusDanger,
    [STATUS_TYPE.INFO]: IconStatusInfo,
    [STATUS_TYPE.SUCCESS]: IconStatusSuccess,
    [STATUS_TYPE.WARNING]: IconStatusWarning,
  };

  export default {
    components: { SvgIcon },
    props: {
      type: {
        type: String,
        default: undefined,
        // themes without icons will simply not render anything
        validator: (type) => Object.values(THEME).includes(type),
      },
    },
    setup(props) {
      const iconComponent = computed(() => ICON_COMPONENTS[props.type]);
      return {
        iconComponent,
      };
    },
  };
</script>

<style lang="scss">
// styles are NOT scoped so they work with sweetalert2
.status-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  .svg-icon {
    display: block;
  }

  &.is-large {
    text-align: center;
    height: 44px;
    width: 44px;
    min-width: 44px;
    border-radius: 100px;
  }

  @each $type in 'danger', 'info', 'success', 'warning' {
    &.has-text-#{$type} {
      color: var(--#{$type}-100);
      &.is-large {
        background: var(--#{$type}-10);
      }
    }
  }
}
</style>
