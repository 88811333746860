interface TrackingClientConfig {
  baseUrl: string;
}

export default class TrackingClient {
  private baseUrl: string;

  constructor(config: TrackingClientConfig) {
    this.baseUrl = config.baseUrl;
  }

  public async carrierTrackingURL({ id }: { id: string }): Promise<string | null> {
    const trackingURL = `${this.baseUrl}/v0/consignments/${id}/tracking-url`;

    // fetch will throw errors for 4XX & 5XX reponses
    // 4XX responses indicate tracking is not available
    try {
      const trackingCheck = await fetch(
        trackingURL,
        {
          method: 'HEAD',
          redirect: 'manual',
        },
      );

      // if the backend response to the HEAD request is a 3XX
      // the fetch response object will have be:
      // { ..., status: 0, type: 'opaqueredirect' }
      if (trackingCheck.type === 'opaqueredirect') {
        return trackingURL;
      }
    } catch (e) {
      logger.debug(`[SenderService] tracking not available for: ${id}`);
    }

    return null;
  }
}
