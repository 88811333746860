<template>
  <Tooltip multilined size="is-small" class="warning-tooltip">
    <template #default>
      <slot name="label"></slot>
      <i class="fas fa-exclamation-triangle warning-tooltip-icon"></i>
    </template>
    <template #content>
      <slot name="default"></slot>
    </template>
  </Tooltip>
</template>

<script>
  import Tooltip from '@/shared/components/Tooltip.vue';

  export default {
    name: 'WarningTooltip',
    components: { Tooltip },
  };
</script>

<style lang="scss" scoped>
  .warning-tooltip-icon {
    color: $yellow;
  }
</style>
