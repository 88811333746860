import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { sum } from 'radash';

import { extractValidationErrors } from '@/shared/backend-validations';
import { FORM_SECTION_MODES } from '@/shared/consts';
import instrumentation from '@/shared/instrumentation';

import { PrintClientStatus } from '@/shared/models';
import { consignmentFactory } from '@/shared/models/Consignment';
import { CancelledError, ValidationError } from '@/shared/services/api-client';
import SenderService, { quoteService } from '@/shared/services/sender';

import Address from '@Address/store/types';
import QuotesStore, { QuotesTypes as QuotesInternalTypes } from '@App/store/modules/QuotesStore';
import { createTypeHelpers } from '@App/store/store-helpers';
import { ConsignmentInstrumentation } from '@Consignment/instrumentation/types';

export const ConsignmentFormQuotes = createTypeHelpers('quotes', QuotesInternalTypes);

export const types = {
  actions: {
    reset: 'reset',
    resetFormDirty: 'resetFormDirty',
    setFormSectionMode: 'setFormSectionMode',
    setConsignmentFormData: 'setConsignmentFormData',
    selectReceiverAddressBookEntry: 'selectReceiverAddressBookEntry',
    updateFormData: 'updateFormData',
    requestNewQuotesSet: 'requestNewQuotesSet',
    setAutoprintConfig: 'setAutoprintConfig',
    setAutoprintPreference: 'setAutoprintPreference',
    setEstimate: 'setEstimate',
    setOrder: 'setOrder',
    setQuote: 'setQuote',
    setQuoteSet: 'setQuoteSet',
    setPreviousQuote: 'setPreviousQuote',
    saveConsignment: 'saveConsignment',
    updateConsignment: 'updateConsignment',
    fetchUpdateSummary: 'fetchUpdateSummary',
  },

  getters: {
    isFormDirty: 'isFormDirty',
    getFormSectionMode: 'getFormSectionMode',
    getConsignmentFormData: 'getConsignmentFormData',
    getConsignmentFormType: 'getConsignmentFormType',
    getConsignmentFormReferences: 'getConsignmentFormReferences',
    getConsignmentFormAgreedService: 'getConsignmentFormAgreedService',
    isTransferAutomatic: 'isTransferAutomatic',
    getConsignmentFormDispatchDetails: 'getConsignmentFormDispatchDetails',
    getConsignmentFormSenderAddress: 'getConsignmentFormSenderAddress',
    getConsignmentFormReceiverAddress: 'getConsignmentFormReceiverAddress',
    getConsignmentFormLineItems: 'getConsignmentFormLineItems',
    getConsignmentFormCostCenter: 'getConsignmentFormCostCenter',
    getConsignmentFormPayerAccount: 'getConsignmentFormPayerAccount',
    getConsignmentFormQuote: 'getConsignmentFormQuote',
    getConsignmentFormPallets: 'getConsignmentFormPallets',
    getSelectedReceiverAddressBookEntry: 'getSelectedReceiverAddressBookEntry',
    getConsignmentFormDangerousGoodsDeclaration: 'getConsignmentFormDangerousGoodsDeclaration',

    getAutoprintConfig: 'getAutoprintConfig',
    getAutoprintPreference: 'getAutoprintPreference',
    getPreviousQuote: 'getPreviousQuote',
    getServerValidationErrors: 'getServerValidationErrors',
    getEstimate: 'getEstimate',
    getOrder: 'getOrder',
    getCustomFieldData: 'getCustomFieldData',
  },

  mutations: {
    RESET: 'RESET',
    SET_MODE: 'SET_MODE',
    RESET_FORM: 'RESET_FORM',
    SET_FORM_DIRTY: 'SET_FORM_DIRTY',
    RESET_FORM_DIRTY: 'RESET_FORM_DIRTY',
    SET_CONSIGNMENT_FORM_DATA: 'SET_CONSIGNMENT_FORM_DATA',
    SET_CONSIGNMENT_FORM_DATA_KEY: 'SET_CONSIGNMENT_FORM_DATA_KEY',
    SET_AUTOPRINT_CONFIG: 'SET_AUTOPRINT_CONFIG',
    SET_AUTOPRINT_PREFERENCE: 'SET_AUTOPRINT_PREFERENCE',
    SET_SELECTED_RECEIVER_ADDRESS_BOOK_ENTRY: 'SET_SELECTED_RECEIVER_ADDRESS_BOOK_ENTRY',
    SET_PREVIOUS_CONSIGNMENT_STATE: 'SET_PREVIOUS_CONSIGNMENT_STATE',
    SET_VALIDATION_ERRORS: 'SET_VALIDATION_ERRORS',
    SET_ESTIMATE: 'SET_ESTIMATE',
    SET_ORDER: 'SET_ORDER',
  },
};

/**
 * @typedef ConsignmentStoreState
 * @property {NewConsignment} consignmentFormData
 * @property {Object} previousConsignmentState
 * @property {Object|null} selectedReceiverAddressBookEntry
 * @property {string} formSectionMode
 * @property {ConsignmentAutoprintPreference} autoprintPreference
 * @property {Array} serverValidationErrors
 * @property {boolean} isFormDirty
 */

/**
 * @returns {ConsignmentStoreState}
 */
const createDefaultStore = () => ({
  selectedReceiverAddressBookEntry: null,
  consignmentFormData: consignmentFactory.createNew(),
  previousConsignmentState: {},
  formSectionMode: FORM_SECTION_MODES.editDisplay,
  isFormDirty: false,
  serverValidationErrors: [],
  autoprintConfig: { client: null, config: null },
  autoprintPreference: { connote: false, labels: false, labelCopies: 1, dgSummary: false },
  estimate: null,
  order: null,
  agreedService: null,
  transferPolicy: null,
});

export const actions = {
  [types.actions.setQuote]: async ({ dispatch }, quote) => {
    dispatch(ConsignmentFormQuotes.actions.setQuote, quote);
  },
  [types.actions.setQuoteSet]: async ({ dispatch }, quoteSet) => {
    dispatch(ConsignmentFormQuotes.actions.setQuoteSet, quoteSet);
  },

  [types.actions.setPreviousQuote]: ({ commit }, quoteId) => {
    commit(types.mutations.SET_PREVIOUS_CONSIGNMENT_STATE, {
      key: 'previousQuote',
      value: quoteId,
    });
  },

  [types.actions.reset]: ({ commit, dispatch }) => {
    commit(types.mutations.RESET);
    dispatch(ConsignmentFormQuotes.actions.reset);
  },

  [types.actions.resetFormDirty]: ({ commit }) => {
    commit(types.mutations.RESET_FORM_DIRTY);
  },

  [types.actions.setFormSectionMode]: ({ commit }, { mode }) => {
    if (!Object.values(FORM_SECTION_MODES).includes(mode)) {
      throw new Error(`ConsignmentForm invalid mode. Valid options are ${Object.values(FORM_SECTION_MODES).join('|')}`);
    }
    commit(types.mutations.SET_MODE, { mode });
  },

  /**
   * action for loading a whole consignment, not related to a user input/change
   * @param state
   * @param commit
   * @param dispatch
   * @param getters
   * @param {NewConsignment} newConsignment
   * @returns {Promise<void>}
   */
  [types.actions.setConsignmentFormData]: async ({ state, commit, dispatch }, newConsignment) => {
    Object
      .keys(newConsignment)
      .filter(attr => attr in state.consignmentFormData)
      .forEach(attr => commit(types.mutations.SET_CONSIGNMENT_FORM_DATA_KEY, {
        key: attr,
        value: newConsignment[attr],
      }));

    // ToDo: address needs to be resolved only when creating a new consignment from defaults to fetch the site address for sender/receiver,
    // in all other scenarios address object should have all the details already
    // we should move this logic somewhere to the create view
    if (newConsignment.receiver?.addressId && !newConsignment.receiver?.address?.id) {
      const address = await dispatch(Address.actions.resolveAddress, newConsignment.receiver.addressId, { root: true });
      commit(types.mutations.SET_CONSIGNMENT_FORM_DATA_KEY, {
        key: 'receiver',
        value: { ...state.consignmentFormData.receiver, address },
      });
    }

    if (newConsignment.sender?.addressId && !newConsignment.sender?.address?.id) {
      const address = await dispatch(Address.actions.resolveAddress, newConsignment.sender.addressId, { root: true });
      commit(types.mutations.SET_CONSIGNMENT_FORM_DATA_KEY, {
        key: 'sender',
        value: { ...state.consignmentFormData.sender, address },
      });
    }

    commit(types.mutations.RESET_FORM);
  },

    /**
   * action for loading an estimate data, not related to a user input/change
   * @param state
   * @param commit
   * @param dispatch
   * @param getters
   * @param {Estimate} estimate
   * @returns {Promise<void>}
   */
    [types.actions.setEstimate]: async ({ commit }, estimate) => {
      commit(types.mutations.SET_ESTIMATE, estimate);
    },

    [types.actions.setOrder]: async ({ commit }, order) => {
      commit(types.mutations.SET_ORDER, order);
    },

  // action to update a form section value from an input/change/update
  [types.actions.updateFormData]: ({ state, commit, dispatch }, update) => {
    const invalidKeys = Object
      .keys(update)
      .filter(attr => !(attr in state.consignmentFormData));
    if (invalidKeys.length) throw new Error(`[ConsignmentFormStore/actions/updateFormData] Invalid keys ${invalidKeys.join('|')}`);

    if (!Object.keys(update).some(updateKey => !isEqual(update[updateKey], state.consignmentFormData[updateKey]))) {
      return;
    }

    const lineItemsCount = state.consignmentFormData.lineItems.length;
    const lineItemsQuantity = sum(state.consignmentFormData.lineItems, item => item.quantity);

    Object.keys(update)
      .forEach(attr => commit(types.mutations.SET_CONSIGNMENT_FORM_DATA_KEY, { key: attr, value: update[attr] }));

    commit(types.mutations.SET_FORM_DIRTY);

    const shouldResetQuote = val => ['dispatchDate', 'lineItems', 'receiver', 'sender', 'payerAccount'].includes(val);
    if (Object.keys(update).some(shouldResetQuote)) {
      // GEPPES-1507 cancel in-flight requests since underlying data has changed
      quoteService.cancelRequestQuotes('consignment');
      commit(types.mutations.SET_CONSIGNMENT_FORM_DATA_KEY, { key: 'quoteId', value: null });
      dispatch(ConsignmentFormQuotes.actions.reset);
    }

    // if a line item has been added, reset the DG declaration, as the new line item may contain DGs
    if (('lineItems' in update && update.lineItems.length)
      && (update.lineItems.length > lineItemsCount || sum(update.lineItems, item => item.quantity) > lineItemsQuantity)) {
      commit(types.mutations.SET_CONSIGNMENT_FORM_DATA_KEY, { key: 'dangerousGoodsDeclaration', value: { excludesDangerousGoods: false } });
    }
  },

  [types.actions.fetchUpdateSummary]: async ({ commit }, { consignment, version }) => {
    commit(types.mutations.SET_VALIDATION_ERRORS, null);

    try {
      const { labelOptions, success } = await SenderService.generateConsignmentSummary({
        consignment,
        version,
      });

      return { labelOptions, success };
    } catch (error) {
      if (error instanceof ValidationError) {
        const { validationErrors, otherErrors } = extractValidationErrors(error);
        commit(types.mutations.SET_VALIDATION_ERRORS, validationErrors);
        if (otherErrors.length) {
          throw new Error(otherErrors[0].detail || otherErrors[0].title, { cause: otherErrors[0] });
        }
        return { success: false };
      }
      throw error;
    }
  },
    /**
   * @param commit
   * @param {NewConsignment} consignment
   * @param {ConsignmentAutoprintPreference} autoprint
   * @returns {Promise<{id: UUID}>}
   */
    [types.actions.saveConsignment]: async ({ commit }, { consignment, autoprint }) => {
      commit(types.mutations.SET_VALIDATION_ERRORS, null);

      try {
        return await SenderService.create({
          consignment,
          autoprint,
        });
      } catch (error) {
        if (error instanceof ValidationError) {
          const { validationErrors, otherErrors } = extractValidationErrors(error);
          commit(types.mutations.SET_VALIDATION_ERRORS, validationErrors);
          if (otherErrors.length) {
            throw new Error(otherErrors[0].detail || otherErrors[0].title, { cause: otherErrors[0] });
          }
        }
        throw error;
      }
    },
  /**
   * @param commit
   * @param {NewConsignment} consignment
   * @param {ConsignmentAutoprintPreference} autoprint
   * @param {ConsignmentLabelOptions} labelOptions
   * @returns {Promise<{id: UUID}>}
   */
  [types.actions.updateConsignment]: async ({ commit }, { consignment, version, autoprint, labelOptions }) => {
    commit(types.mutations.SET_VALIDATION_ERRORS, null);

    try {
      const { success } = await SenderService.updateConsignment({
        consignment,
        autoprint,
        labelOptions,
        version,
      });

      return success;
    } catch (error) {
      if (error instanceof ValidationError) {
        const { validationErrors, otherErrors } = extractValidationErrors(error);
        commit(types.mutations.SET_VALIDATION_ERRORS, validationErrors);
        if (otherErrors.length) {
          throw new Error(otherErrors[0].detail || otherErrors[0].title, { cause: otherErrors[0] });
        }
      }

      throw error;
    }
  },

  [types.actions.requestNewQuotesSet]: async ({ state, commit }, { currentSiteId, preselectServiceId }) => {
    commit(types.mutations.SET_VALIDATION_ERRORS, null);
    try {
      commit(`quotes/${QuotesInternalTypes.mutations.RESET_QUOTE_SET}`);

      const quoteSet = await quoteService.requestQuotes({
        ...state.consignmentFormData,
        siteId: currentSiteId,
      }, 'consignment');
      logger.debug('[ConsignmentFormStore] Quote set requested', quoteSet);

      commit(`quotes/${QuotesInternalTypes.mutations.SET_QUOTE_SET}`, quoteSet);
      const serviceToSelectId = preselectServiceId || state.previousConsignmentState.previousQuote?.agreedServiceId;

      const quote = quoteSet.quotes.find(q => q.agreedServiceId === serviceToSelectId);
      if (!quote) return;

      if (preselectServiceId) {
        // force the service to select,even if it's not recommended/selectable
          instrumentation.event(ConsignmentInstrumentation.CONSIGNMENT_PRESELECTED_SERVICE_QUOTE_UPDATED, {
            consignmentId: state.consignmentFormData.id,
            quote,
          });

          commit(types.mutations.SET_CONSIGNMENT_FORM_DATA_KEY, { key: 'quoteId', value: quote.id });
        } else if (quote.recommended) {
          // if the previously selected service is still recommended, select it again
          commit(types.mutations.SET_CONSIGNMENT_FORM_DATA_KEY, { key: 'quoteId', value: quote.id });
        }
    } catch (error) {
      if (error instanceof CancelledError) {
        // do nothing!
        return;
      }

      if (error instanceof ValidationError) {
        const { validationErrors, otherErrors } = extractValidationErrors(error);
        commit(types.mutations.SET_VALIDATION_ERRORS, validationErrors);
        if (otherErrors.length) {
          throw new Error(otherErrors[0].detail || otherErrors[0].title, { cause: otherErrors[0] });
        }
        return;
      }

      logger.error('[ConsignmentFormStore] Request quote set failed', { error });
      commit(`quotes/${QuotesInternalTypes.mutations.RESET_QUOTE_SET}`);
      throw error;
    }
  },

  [types.actions.selectReceiverAddressBookEntry]: ({ commit }, addressBookEntry) => {
    commit(types.mutations.SET_SELECTED_RECEIVER_ADDRESS_BOOK_ENTRY, addressBookEntry);
  },

  [types.actions.setAutoprintConfig]: ({ commit }, { client, config }) => {
    commit(types.mutations.SET_AUTOPRINT_CONFIG, { client, config });
  },

  [types.actions.setAutoprintPreference]: ({ commit, state }, autoprintPreference) => {
    const printClientDetails = state.autoprintConfig.client;
    const printConfig = state.autoprintConfig.config;

    if (!printConfig || !printClientDetails) {
      commit(types.mutations.SET_AUTOPRINT_PREFERENCE, { labels: false, labelCopies: 1, connote: false });
      return;
    }

    const clientRunning = printClientDetails.status === PrintClientStatus.running;
    if (!clientRunning || !printConfig.enabled) {
      commit(types.mutations.SET_AUTOPRINT_PREFERENCE, { labels: false, labelCopies: 1, connote: false });
      return;
    }

    const validConnotePrintConfig = !!printConfig?.defaultConnotePrinter.id &&
      !!printConfig?.defaultConnotePrinter.enabled;
    const validLabelPrintConfig = !!printConfig?.defaultLabelPrinter.id &&
      !!printConfig?.defaultLabelPrinter.enabled;
    commit(types.mutations.SET_AUTOPRINT_PREFERENCE, {
      labels: autoprintPreference.labels && validLabelPrintConfig,
      labelCopies: autoprintPreference.labelCopies,
      connote: autoprintPreference.connote && validConnotePrintConfig,
    });
  },
};

export const getters = {
  [types.getters.isFormDirty]: state => state.isFormDirty,
  [types.getters.getFormSectionMode]: state => state.formSectionMode,
  [types.getters.getConsignmentFormData]: state => state.consignmentFormData,
  [types.getters.getConsignmentFormType]: state => state.consignmentFormData.type,
  [types.getters.getConsignmentFormReferences]: state => state.consignmentFormData.references,
  [types.getters.getConsignmentFormDispatchDetails]: state => state.consignmentFormData.dispatchDate,
  [types.getters.getConsignmentFormSenderAddress]: state => state.consignmentFormData.sender,
  [types.getters.getConsignmentFormReceiverAddress]: state => state.consignmentFormData.receiver,
  [types.getters.getConsignmentFormLineItems]: state => state.consignmentFormData.lineItems,
  [types.getters.getConsignmentFormCostCenter]: state => state.consignmentFormData.costCenter,
  [types.getters.getConsignmentFormPayerAccount]: state => state.consignmentFormData.payerAccount,
  [types.getters.getConsignmentFormAgreedService]: state => state.consignmentFormData.agreedService,
  [types.getters.isTransferAutomatic]: state => state.consignmentFormData.transferPolicy === 'automatic',
  [types.getters.getConsignmentFormQuote]: (state, _getters) => ((state.consignmentFormData.quoteId)
    ? _getters[ConsignmentFormQuotes.getters.getQuote](state.consignmentFormData.quoteId)
    : null),
  [types.getters.getConsignmentFormPallets]: state => state.consignmentFormData.pallets,
  [types.getters.getConsignmentFormDangerousGoodsDeclaration]: state => state.consignmentFormData.dangerousGoodsDeclaration,
  [types.getters.getSelectedReceiverAddressBookEntry]: state => state.selectedReceiverAddressBookEntry,
  [types.getters.getPreviousQuote]: (state) => state.previousConsignmentState.previousQuote,
  [types.getters.getServerValidationErrors]: state => state.serverValidationErrors,
  [types.getters.getAutoprintConfig]: state => state.autoprintConfig,
  [types.getters.getAutoprintPreference]: state => state.autoprintPreference,
  [types.getters.getEstimate]: state => state.estimate,
  [types.getters.getOrder]: state => state.order,
  [types.getters.getCustomFieldData]: state => state.consignmentFormData.customFields,
};

export const mutations = {
  [types.mutations.RESET]: (state) => {
    const resetState = omit(createDefaultStore(), ['mode']);
    Object.keys(resetState).forEach(key => {
      state[key] = resetState[key];
    });
  },
  [types.mutations.SET_MODE]: (state, { mode }) => {
    state.formSectionMode = mode;
  },
  [types.mutations.SET_CONSIGNMENT_FORM_DATA]: (state, formData) => {
    state.consignmentFormData = formData;
  },
  [types.mutations.SET_CONSIGNMENT_FORM_DATA_KEY]: (state, { key, value }) => {
    state.consignmentFormData[key] = value;
  },
  [types.mutations.SET_PREVIOUS_CONSIGNMENT_STATE]: (state, { key, value }) => {
    state.previousConsignmentState[key] = value;
  },
  [types.mutations.SET_SELECTED_RECEIVER_ADDRESS_BOOK_ENTRY]: (state, addressBookEntry) => {
    state.selectedReceiverAddressBookEntry = addressBookEntry;
  },
  [types.mutations.RESET_FORM]: (state) => {
    state.isFormDirty = false;
  },
  [types.mutations.SET_FORM_DIRTY]: (state) => {
    state.isFormDirty = true;
  },
  [types.mutations.RESET_FORM_DIRTY]: (state) => {
    state.isFormDirty = false;
  },
  [types.mutations.SET_VALIDATION_ERRORS](state, errors) {
    state.serverValidationErrors = errors || [];
  },
  [types.mutations.SET_AUTOPRINT_CONFIG](state, { client, config }) {
    state.autoprintConfig = { client, config };
  },
  [types.mutations.SET_AUTOPRINT_PREFERENCE](state, autoprintPreference) {
    state.autoprintPreference = autoprintPreference;
  },
  [types.mutations.SET_ESTIMATE](state, estimate) {
    state.estimate = estimate;
  },
  [types.mutations.SET_ORDER](state, order) {
    state.order = order;
  },
};

export default {
  namespaced: true,
  state: createDefaultStore,
  actions,
  getters,
  mutations,
  modules: {
    quotes: QuotesStore,
  },
};
