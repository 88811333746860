const mapData = (type, data) => {
  if (type === 'proofOfDelivery') {
    return { ...data, description: 'POD available' };
  }

  return { ...data };
};

const create = ({
  data = {},
  occurredAt,
  targetId,
  targetType,
  type,
} = {}) => ({
  data: mapData(type, data),
  occurredAt,
  targetId,
  targetType,
  type,
});

const createFromApi = create;

const ConsignmentTrackingEvent = {
  create,
  createFromApi,
};

export default ConsignmentTrackingEvent;
