
export function isUnhandleableError(error: Error): boolean {
  if (error.display) return false;

  // if is not from our base error, then default to whoops
  const unhandleableTypes = [
    // @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#Error_types
    TypeError, SyntaxError, ReferenceError, RangeError, URIError, Error,
  ];

  return !!(unhandleableTypes.find(errorType => error.constructor === errorType));
}

/**
 * Returns true if the error matches or was inherited from the provided type, or if any
 * wrapped error in the chain does.
 */
export function errorIsCausedBy<TError extends Error>(error: Error | unknown, errorType: ErrorType<TError>) {
  // run time safety if called from JavaScript
  if (!error || !(error instanceof Error)) {
    return false;
  }
  if (error instanceof errorType) {
    return true;
  }
  if (error.cause) {
    return errorIsCausedBy(error.cause, errorType);
  }
  return false;
}

/**
 * Returns true if the error matches or was inherited from any of the provided types, or if any
 * wrapped error in the chain does.
 */
export function errorIsCausedByAnyOf<TError extends Error>(error: Error | unknown, errorTypes: ErrorType<TError>[]) {
  return (errorTypes.length > 0)
    && errorTypes.some(errorType => errorIsCausedBy(error, errorType));
}

export function getErrorCauseBy<TError extends Error>(error: Error | unknown, errorType: ErrorType<TError>): TError | undefined {
  // run time safety if called from JavaScript
  if (!error || !(error instanceof Error)) {
    return undefined;
  }
  if (error instanceof errorType) {
    return error;
  }
  if (error.cause) {
    return getErrorCauseBy(error.cause, errorType);
  }
  return undefined;
}

export function errorIsCritical(error: Error): boolean {
  return !!error._critical;
}

export function errorIsDisplayed(error: Error): boolean {
  return !!error._displayed;
}

export function errorIsHandled(error: Error): boolean {
  return !!error._handled;
}
