import { isFunction, isObject } from './utils';
import { computed, reactive, unref } from 'vue';

/**
 * Raw validator function, before being normalized
 * Can return a Promise or a {@see ValidatorResponse}
 * @typedef {function(*): ((Promise<ValidatorResponse> | ValidatorResponse))} Validator
 */

/**
 * @typedef NormalizedValidator
 * @property {Validator} $validator
 * @property {String | Ref<String> | function(*): string} [$message]
 * @property {Object | Ref<Object>} [$params]
 */

/**
 * @typedef ValidationResult
 * @property {Ref<Boolean>} $pending
 * @property {Ref<Boolean>} $dirty
 * @property {Ref<Boolean>} $invalid
 * @property {Ref<Boolean>} $error
 * @property {Function} $touch
 * @property {Function} $reset
 * @property {Ref<ErrorObject[]>} $errors
 */

/**
 * Response form a raw Validator function.
 * Should return a Boolean or an object with $invalid property.
 * @typedef {Boolean | { $invalid: Boolean }} ValidatorResponse
 */

export function isPromise(object) {
  return isObject(object) && isFunction(object.then);
}

/**
 * Sorts the validators for a state tree branch
 * @param {Object<NormalizedValidator|Function>} validations
 * @return {{ rules: Object<NormalizedValidator>, nestedValidators: Object, collectionValidators: Object, config: Object }}
 */
function sortValidations(validations = {}) {
  const validationKeys = Object.keys(validations);

  const rules = {};
  const nestedValidators = {};
  let collectionValidators = {};
  const config = {}

  validationKeys.forEach(key => {
    const v = validations[key];

    switch (true) {
      // If it is already normalized, use it
      case isFunction(v.$validator):
        rules[key] = v;
        break;
      // If it is just a function, normalize it first
      // into { $validator: <Fun> }
      case key === '$each':
        collectionValidators = v;
        break;

      case key.startsWith('$'):
        config[key] = v;
        break;

      case isFunction(v):
        rules[key] = { $validator: v };
        break;
      // If it doesn’t match any of the above,
      // treat as nestedValidators state property
      default:
        nestedValidators[key] = v;
    }
  });

  return { rules, nestedValidators, collectionValidators, config };
}


/**
 * Calls a validation rule by unwrapping it's value first from a ref.
 *
 * can return Ref or boolean
 * @param {Validator} rule
 * @param {Ref} value
 * @return {Promise<ValidatorResponse> | ValidatorResponse}
 */
function callRule(rule, value) {
  const v = unref(value);
  return rule(v);
}

/**
 * Normalizes the validator result
 * Allows passing a boolean of an object like `{ $invalid: Boolean }`
 * @param {ValidatorResponse} result - Validator result
 * @return {Boolean}
 */
function normalizeValidatorResponse(result) {
  return result.$invalid !== undefined
    ? !result.$invalid
    : !result;
}

/**
 * Returns the result of the validator every time the model changes.
 * Wraps the call in a computed property.
 * Used for with normal functions.
 * TODO: This allows a validator to return $invalid, probably along with other parameters. We do not utilize them ATM.
 * @param {Validator} rule
 * @param {Ref<*>} model
 * @return {Ref<Boolean>}
 */
function createComputedResult(rule, model) {
  const result = callRule(rule, model);
  // result can be a reference for async validations
  return normalizeValidatorResponse(unref(result));
}

/**
 * Returns the validation result.
 * Detects async and sync validators.
 * @param {NormalizedValidator} rule
 * @param {Ref<*>|{}} model
 * @return {{$params: *, $message: Ref<String>, $pending: Ref<Boolean>, $invalid: Ref<Boolean>}}
 */
function createValidatorResult(rule, model) {
  const $params = rule.$params || {};
  const $invalid = createComputedResult(rule.$validator, model);

  const message = rule.$message;
  const $message = isFunction(message)
    ? message(
        {
          $invalid,
          $params: $params,
          $model: model,
        },
      )
    : message || '';

  return {
    $message,
    $params,
    $invalid,
  };
}

/**
 * Creates the main Validation Results object for a state tree
 * Walks the tree's top level branches
 * @param {Object<NormalizedValidator>} rules - Rules for the current state tree
 * @param {Object} model - Current state value
 * @param {String} key - Key for the current state tree
 * @param {String} path - the current property path
 * @param {[]} dirtyFields
 * @return {ValidationResult | {}}
 */
function createValidationResults(rules, model, key, path, dirtyFields) {
  // collect the property keys
  const ruleKeys = Object.keys(rules);

  const $dirty = dirtyFields.has(path); // this has to be evaluated from a dirty store

  const result = {
    // restore $dirty from cache
    $dirty,
    $path: path,
    $touch: () => {
      if (ruleKeys.length){
        dirtyFields.add(path);
      }
    },
    $reset: () => {
      dirtyFields.delete(path);
    },
  };

  /**
   * If there are no validation rules, it is most likely
   * a top level state, aka root
   */
  if (!ruleKeys.length) return result;

  ruleKeys.forEach(ruleKey => {
    result[ruleKey] = createValidatorResult(
      rules[ruleKey],
      model,
    );
  });

  result.$invalid = ruleKeys.some(ruleKey => result[ruleKey].$invalid);

  result.$error = result.$invalid && result.$dirty;

  const $silentErrors = ruleKeys
    .filter(ruleKey => result[ruleKey].$invalid)
    .map(ruleKey => {
      const res = result[ruleKey];
      return reactive({
        $propertyPath: path,
        $property: key,
        $validator: ruleKey,
        $message: res.$message,
        $params: res.$params,
        $pending: res.$pending,
      });
    });

  result.$errors = result.$dirty ? $silentErrors : [];

  return result;
}

/**
 * Creates the main Validation Results object for a state tree
 * Walks the tree's top level branches
 * @param {Object<NormalizedValidator>} collectionValidator - $each definition
 * @param {Object} model - Current state value
 * @param {String} key - Key for the current state tree
 * @param {String} path - the current property path
 * @param {[]} dirtyFields
 * @return {ValidationResult | {}}
 */
function createCollectionResults(collectionValidator, model, key, path, dirtyFields) {
  if (!Object.keys(collectionValidator).length || !model) { return {}; }

  const { config } = sortValidations(collectionValidator);
  if (!config.$key || !config.$rules) return {}; // invalid collection validator, needs both $key and $rules

  const validationResults = model.reduce((current, item, index) => {
    const key = item[config.$key] || index;

    const validations = config.$rules(item, key, index, model);

    const itemResult = setValidations({
      validations,
      state: item,
      key: null,
      parentKey: path + '.' + key,
      dirtyFields,
    });

    return { ...current, [key]: itemResult };
  }, {});

  return { $each: validationResults, $eachKey: config.$key };
}

/**
 * Collects the validation results of all nested state properties
 * @param {Object<NormalizedValidator|Function>} validations - The validation
 * @param {Object} nestedState - Current state
 * @param {String} [key] - Parent level state key
 * @param {String} path - Path to current property
 * @param {[]} dirtyFields - Path to current property
 * @return {{}}
 */
function collectNestedValidationResults(validations, nestedState, key, path, dirtyFields) {
  const nestedValidationKeys = Object.keys(validations);

  // if we have no state, return empty object
  if (!nestedValidationKeys.length) return {};

  return nestedValidationKeys.reduce((results, nestedKey) => {
    // build validation results for nested state
    results[nestedKey] = setValidations({
      validations: validations[nestedKey],
      state: nestedState,
      key: nestedKey,
      parentKey: path,
      dirtyFields,
    });
    return results;
  }, {});
}


/**
 * Generates the Meta fields from the results
 * @param {ValidationResult|{}} results
 * @param {Object<ValidationResult>[]} nestedResults
 * @param {Object<ValidationResult>[]} collectionResults
 * @return {{$anyDirty: Ref<Boolean>, $error: Ref<Boolean>, $invalid: Ref<Boolean>, $errors: Ref<ErrorObject[]>, $dirty: Ref<Boolean>, $touch: Function, $reset: Function }}
 */
function createMetaFields(results, nestedResults, collectionResults, path) {
  const nodeValidationsKeys = Object.keys(results).filter(resultsKey => !resultsKey.startsWith('$'))

  const allResults = [nestedResults, collectionResults]
    .filter(res => res)
    .reduce((allRes, res) => {
      return allRes.concat(Object.values(unref(res)));
    }, []);

  // use the $dirty property from the root level results
  // if node does not contain any rule/validation at this level we don't want to hold state for '$dirty' state
  // instead it is true if all children are $dirty
  const $dirty = (!!allResults.length && allResults.every(r => r.$dirty))
    || (nodeValidationsKeys.length ? results.$dirty : false);

  const $errors = (() => {
    // current state level errors, fallback to empty array if root
    const modelErrors = unref(results.$errors) || [];

    // collect all nested and child $errors
    const nestedErrors = allResults
      .filter(result => unref(result).$errors.length)
      .reduce((errors, result) => {
        return errors.concat(...result.$errors);
      }, []);

    // merge the $errors
    return modelErrors.concat(nestedErrors);
  })();

  const $invalid =
    // if any of the nested values is invalid
    allResults.some(r => r.$invalid) ||
    // or if the current state is invalid
    unref(results.$invalid) ||
    // fallback to false if is root
    false;

  const $anyDirty = (() =>
      allResults.some(r => r.$dirty) ||
      allResults.some(r => r.$anyDirty) ||
      $dirty
  )();

  const $error = nodeValidationsKeys.length
    ? ($invalid && $dirty) || false
    : !!allResults.length && allResults.every(r => r.$error);

  const $anyError = (() =>
      allResults.some(r => r.$error) ||
      allResults.some(r => r.$anyError) ||
      $error
  )();

  const $touch = (recursive = true) => {
    // call the root $touch
    results.$touch();
    // call all nested level $touch
    if (recursive) {
      allResults.forEach((result) => {
        result.$touch();
      });
    }
  };

  const $reset = (recursive = true) => {
    // reset the root $dirty state
    results.$reset();
    // reset all the children $dirty states
    if (recursive) {
      allResults.forEach((result) => {
        result.$reset();
      });
    }
  };

  // Ensure that if all child and nester results are $dirty, this also becomes $dirty
  if (allResults.length && allResults.every(nr => nr.$dirty)) {
    // no need to touch recursively, since all children are already dirty
    $touch(false);
  }

  return {
    $dirty,
    $errors,
    $invalid,
    $anyDirty,
    $error,
    $anyError,
    $touch,
    $reset,
  };
}

const getNestedState = (key, state, optionalState = false) => {
  const stateValue = unref(state);
  if (stateValue && key in stateValue){
    return stateValue[key];
  }

  if (!optionalState) {
    console.warn(`Validation - key: ${key}, is not present in state branch`, state);
  }
  return null;
}

/**
 * Main Vuelidate bootstrap function.
 * Used both for Composition API in `setup` and for Global App usage.
 * Used to collect validation state, when walking recursively down the state tree
 * @param {Object} params
 * @param {Object<NormalizedValidator|Function>} params.validations
 * @param {Object} params.state
 * @param {String} params.key - Current state property key. Used when being called on nested items
 * @param {String} params.parentKey - Parent state property key. Used when being called recursively
 * @param {[]} params.dirtyFields - reactive dirty state
 * @param {Object<ValidationResult>} [params.childResults] - Used to collect child results.
 * @return {UnwrapRef<VuelidateState>}
 */
export function setValidations({
  validations,
  state,
  key,
  parentKey,
  dirtyFields,
}) {
  // todo refactor and improve
  const path = parentKey ? `${parentKey}${key ? `.${key}` : ''}` : key;

  // Sort out the validation object into:
  // – rules = validators for current state tree fragment
  // — nestedValidators = nested state fragments keys that might contain more validators
  const { rules, nestedValidators, collectionValidators, config } = sortValidations(unref(validations));

  // console.log(key, state, { rules, nestedValidators, collectionValidators });

  // create protected state for cases when the state branch does not exist.
  const nestedState = key ? getNestedState(key, state, validations.$optionalState) : state;

  // Use rules for the current state fragment and validate it
  const results = createValidationResults(rules, nestedState, key, path || '__root', dirtyFields);

  const collectionResults = createCollectionResults(collectionValidators, nestedState, key, path, dirtyFields);
  // Use nested keys to repeat the process
  // *WARN*: This is recursive
  const nestedResults = collectNestedValidationResults(nestedValidators, nestedState, key, path, dirtyFields);

  // Collect and merge this level validation results
  // with all nested validation results
  const {
    $dirty,
    $errors,
    $invalid,
    $anyDirty,
    $anyError,
    $error,
    $touch,
    $reset,
  } = createMetaFields(results, nestedResults, collectionResults.$each, path || '__root');

  /**
   * If we have no `key` or 'parentKey', this is the top level state
   * We dont need `$model` there.
   */
  const $model = key || parentKey ? computed(() => nestedState) : null;

  return reactive({
    ...results,
    // NOTE: The order here is very important, since we want to override
    // some of the *results* meta fields with the collective version of it
    // that includes the results of nested state validation results
    $model,
    $dirty,
    $error,
    $errors,
    $invalid,
    $anyDirty,
    $anyError,
    $touch,
    $reset,
    ...(Object.keys(collectionResults).length && { ...collectionResults } ),
    $path: path || '__root',
    // add each nested property's state
    ...nestedResults,
    // add $namespace if one was provided
    ...(config.$namespace && { $namespace: config.$namespace }),
  });
}
