<template>
  <ControlWithIcon
    class="select"
    :class="controlWrapperClasses"
    :data-testref="dataTestref"
  >
    <select
      ref="inputEl"
      v-sensitive
      autocomplete="off"
      v-bind="$attrs"
      class="input"
      :class="{
        'is-danger': hasError,
      }"
      :value="modelValue"
      @input="input"
    >
      <option v-if="defaultOption" disabled value="">{{ defaultOption }}</option>
      <option v-for="(_option, _key) in options" :key="_key" v-sensitive :value="_key">{{ _option }}</option>
    </select>
    <template #icon-right>
      <SvgIcon :svg="ChevronDownIcon" size="small" class="select-icon" />
    </template>
  </ControlWithIcon>
</template>

<script setup lang="ts">
  import ControlWithIcon from '@/shared/components/controls/ControlWithIcon.vue';
  import { basicControl, BasicControlProps } from '@/shared/components/controls/helpers/controlBehaviour';
  import SvgIcon from '@/shared/icons/SvgIcon.vue';
  import ChevronDownIcon from '@/shared/icons/svgs/chevronDown.svg';

  defineOptions({
    inheritAttrs: false,
  });

  const props = defineProps<BasicControlProps & {
    modelValue?: string;
    controlWrapperClasses?: string | string[] | object;
    options: Record<string, string>;
    defaultOption?: string;
  }>();

  const emit = defineEmits<{
    'update:modelValue': [string | null]
  }>();

  const { inputEl, focus, select, vSensitive } = basicControl(props);

  function input(event: Event) {
    const { value } = (event.target as HTMLSelectElement);
    emit('update:modelValue', value !== '' ? value : null);
  }

  defineExpose({
    focus,
    select,
  });
</script>

<style lang="scss" scoped>
  .select {
    --right-icon-color: var(--brand-100);

    &.is-fullwidth {
      display: flex;
    }

    select {
      appearance: none;
      padding-right: 2.5rem;
      cursor: pointer;
      box-shadow: none;

      &.is-disabled {
        cursor: default;
      }
    }
  }
</style>
