import ConsignmentResultSetStore from '@App/store/modules/ConsignmentResultSetStore';

import { name as moduleName } from '../module';


const types = {
  getters: {
  },

  mutations: {
  },

  actions: {
  },
};

const consignmentsModuleName = 'consignment';

/**
 * Consignment store definition
 */
const ConsignmentTrackStore = {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    [consignmentsModuleName]: ConsignmentResultSetStore,
  },
};

const namespace = moduleName;
const consignmentsNamespace = `${moduleName}/${consignmentsModuleName}`;

export {
  ConsignmentTrackStore as default,
  types,
  namespace,
  consignmentsNamespace,
};
