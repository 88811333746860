/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * @param {String} property Key of the object to sort.
 */
export function dynamicKeySort(property) {
  let sortOrder = 1;

  // descending
  if (property[0] === '-') {
    sortOrder = -1;
    // eslint-disable-next-line no-param-reassign
    property = property.slice(1);
  }

  return (a, b) => {
    if (sortOrder === -1) {
      return b[property].localeCompare(a[property]);
    }
      return a[property].localeCompare(b[property]);
  };
}

const escapePattern = /[.*+?^${}()|[\]\\]/g;
export function escapeStringForRegex(input) {
  return input.replace(escapePattern, '\\$&');
}

export const sentenceCase = (input) => {
  if (!input || typeof input !== 'string') return input;
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export default {
  dynamicSort: dynamicKeySort,
  escapeStringForRegex,
  sentenceCase,
};
